import { signalStoreFeature, SignalStoreFeature, withHooks } from '@ngrx/signals'
import { EmptyFeatureResult } from '@ngrx/signals/src/signal-store-models'
import { PersistState } from '@awork/core/state/signal-store/persistState'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { debounceTime, skipUntil } from 'rxjs'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { Signal } from '@angular/core'
import { EntityMap } from '@ngrx/signals/entities'
import { toObservable } from '@angular/core/rxjs-interop'
import { app } from '@awork/environments/environment'
import { GenericEntity } from '@awork/core/state/signal-store/types'

export const PERSIST_DELAY = app === 'web' ? 5000 : 2000

/**
 * Options for the storage:
 * - key: The key used in IndexedDB.
 * - persistDelay: The delay in milliseconds to persist the state to the storage. Used with RxJS's debounceTime.
 */
export interface StorageOptions {
  key: string
  persistDelay?: number
}

interface Store<State extends object, Entity extends GenericEntity> {
  signalStore?: SignalStore<State>
  entitySignalStore?: EntitySignalStore<Entity>
}

type EntitySignalStoreType<Entity> = {
  entityMap: Signal<EntityMap<Entity>>
  ids: Signal<string[]>
  entities: Signal<Entity[]>
}

interface PersistedEntityState<Entity> {
  entities: Entity[]
  active: string
  loading: boolean
  error: Error
}

interface PersistedState<State> {
  state: State
  loading: boolean
  error: Error
}

/**
 * Adds persistence storage capabilities to the store.
 * It saves the state to the storage and restores it on initialization.
 * @param {Store<State, Entity>} store
 * @param {StorageOptions} storageOptions
 * @returns {SignalStoreFeature<EmptyFeatureResult & { state: State }, EmptyFeatureResult>}
 */
export function withStorage<State extends object = {}, Entity extends GenericEntity = { id: string }>(
  store: Store<State, Entity>,
  storageOptions: StorageOptions
): SignalStoreFeature<EmptyFeatureResult & { state: State }, EmptyFeatureResult> {
  return signalStoreFeature(
    withHooks({
      onInit(signalStore: unknown) {
        const storage = PersistState.instance.storage

        // Check if storage is available (IndexedDB)
        if (!storage) {
          return
        }

        if (store.signalStore) {
          restoreState(storage, store.signalStore, storageOptions)
          persistState(storage, store.signalStore, storageOptions)
        }

        if (store.entitySignalStore) {
          restoreEntityState(storage, store.entitySignalStore, storageOptions)
          persistEntityState(
            storage,
            signalStore as EntitySignalStoreType<Entity>,
            store.entitySignalStore,
            storageOptions
          )
        }
      }
    })
  )
}

/**
 * Restores the state from the storage
 * @param {PersistState} storage
 * @param {SignalStore} signalStore
 * @param {StorageOptions} storageOptions
 */
function restoreState<State extends object>(
  storage: PersistState,
  signalStore: SignalStore<State>,
  storageOptions: StorageOptions
): void {
  storage.getItem<PersistedState<State>>(storageOptions.key).then(storedState => {
    const isLoading = signalStore.getIsLoading()

    if (storedState && isLoading()) {
      signalStore.update(storedState.state, storageOptions.key + ': restoreState')
      signalStore.setLoading(storedState.loading)
      signalStore.setError(storedState.error)
    }
  })
}

/**
 * Persists the state to the storage
 * @param {PersistState} storage
 * @param {SignalStore} signalStore
 * @param {StorageOptions} storageOptions
 */
function persistState<State extends object>(
  storage: PersistState,
  signalStore: SignalStore<State>,
  storageOptions: StorageOptions
): void {
  const dueTime = storageOptions.persistDelay || PERSIST_DELAY
  signalStore.state$.pipe(skipUntil(storage.onRestored(storageOptions.key)), debounceTime(dueTime)).subscribe(state => {
    const persistedState: PersistedState<State> = {
      state,
      loading: signalStore.getIsLoading()(),
      error: signalStore.getError()()
    }

    storage.setItem(storageOptions.key, persistedState)
  })
}

/**
 * Restores the state from the storage
 * @param {PersistState} storage
 * @param {EntitySignalStore<Entity>} entitySignalStore
 * @param {StorageOptions} storageOptions
 */
function restoreEntityState<Entity extends GenericEntity>(
  storage: PersistState,
  entitySignalStore: EntitySignalStore<Entity>,
  storageOptions: StorageOptions
): void {
  storage.getItem<PersistedEntityState<Entity>>(storageOptions.key).then(storedState => {
    const isLoading = entitySignalStore.getIsLoading()

    if (storedState && isLoading()) {
      entitySignalStore.set(storedState.entities, storageOptions.key + ': restoreEntityState')
      entitySignalStore.setActive(storedState.active)
      entitySignalStore.setLoading(storedState.loading)
      entitySignalStore.setError(storedState.error)
    }
  })
}

/**
 * Persists the state to the storage
 * @param {PersistState} storage
 * @param {EntitySignalStoreType<Entity>} entitySignalStore
 * @param {EntitySignalStore<Entity>} store
 * @param {StorageOptions} storageOptions
 */
function persistEntityState<Entity extends GenericEntity>(
  storage: PersistState,
  entitySignalStore: EntitySignalStoreType<Entity>,
  store: EntitySignalStore<Entity>,
  storageOptions: StorageOptions
): void {
  const dueTime = storageOptions.persistDelay || PERSIST_DELAY

  toObservable(entitySignalStore.entities)
    .pipe(skipUntil(storage.onRestored(storageOptions.key)), debounceTime(dueTime))
    .subscribe(state => {
      const persistedEntityState: PersistedEntityState<Entity> = {
        entities: state,
        active: store.getActiveId(),
        loading: store.getIsLoading()(),
        error: store.getError()()
      }

      storage.setItem(storageOptions.key, persistedEntityState)
    })
}
