import { apiEndpoint } from '@awork/environments/environment'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import type { AccountService } from '@awork/_shared/services/account-service/account.service'
import getHostname from '@awork/_shared/functions/get-hostname'

export enum WorkspaceFeature {
  Planner,
  Scim
}

enum PreviousTool {
  noSpecificTool = 'noSpecificTool',
  calendarOrExcel = 'excel',
  asana = 'asana',
  monday = 'monday',
  jira = 'jira'
}

export type WorkspaceType =
  | 'creative-agencies'
  | 'technology'
  | 'consulting'
  | 'real-estate'
  | 'non-profit'
  | 'government'
  | 'consumer-goods'
  | 'healthcare'
  | 'education'
  | 'financial-services'
  | 'media'
  | 'private'
  | 'other'
  | string

export interface Subdomain {
  name: string
  isDefault: boolean
}

export interface BetaFeatureSettings {
  show: boolean
}

export interface ConnectSettings {
  disabled: boolean
}

export interface WorkspaceUISetting {
  showTrialExpiredModal: boolean
}

export interface GuestMigrationSetting {
  pending: boolean
}

export interface WorkspaceSettings {
  teamPlanner?: BetaFeatureSettings
  scimFeature?: BetaFeatureSettings
  basicConnect?: WorkspaceUISetting
  aworkConnect?: ConnectSettings
  guestMigration?: GuestMigrationSetting
}

interface IWorkspace {
  id?: string
  name: string
  description: string
  image?: string
  hasImage?: boolean
  language: string
  lastUsed?: boolean
  lastLogin?: Date | string
  subdomains?: Subdomain[]
  createdOn?: Date
  updatedOn?: Date
  createdBy?: string
  type: WorkspaceType
  size: string
  goals?: string[]
  isApproved?: boolean
  invitationSent?: boolean
  allowCredentialsLogin?: boolean
  isJoinable?: boolean
  isFull?: boolean
  allowSocialLogin?: boolean
  allowSSOLogin?: boolean
  isPaidPlan?: boolean
  settings?: WorkspaceSettings
  isScimActivated?: boolean
  allowAutomaticScimUserBooking?: boolean
  selfAttribution?: string
  previousTool?: PreviousTool | string
  publicImageToken?: string
  sameDomainSignupEnabled?: boolean
  sameDomainSignupDomains?: string[]
  sameDomainSignupRoleId?: string
}

/**
 * The workspace of awork
 */
export class Workspace implements IWorkspace {
  id: string
  name: string
  description: string
  image?: string
  hasImage?: boolean
  language: string
  lastUsed?: boolean
  lastLogin?: Date | string
  subdomains?: Subdomain[]
  createdOn?: Date
  updatedOn?: Date
  createdBy?: string
  type: WorkspaceType
  size: string
  goals?: string[]
  isApproved?: boolean
  invitationSent?: boolean
  allowCredentialsLogin?: boolean
  isJoinable?: boolean
  isFull?: boolean
  allowSocialLogin?: boolean
  allowSSOLogin?: boolean
  isPaidPlan?: boolean
  settings?: WorkspaceSettings
  isScimActivated?: boolean
  allowAutomaticScimUserBooking?: boolean
  selfAttribution?: string
  previousTool?: PreviousTool | string
  memberCount: number
  publicImageToken?: string
  sameDomainSignupEnabled?: boolean
  sameDomainSignupDomains?: string[]
  sameDomainSignupRoleId?: string

  constructor(workspace: IWorkspace) {
    Object.assign(this, workspace)

    this.createdOn = new Date(this.createdOn)
  }

  getImage(width = 500, height = 500): string {
    if (this.publicImageToken) {
      return `${apiEndpoint}/files/images/workspaces?${new URLSearchParams({
        token: this.publicImageToken
      }).toString()}`
    }

    const pixelRatio = getPixelRatio()
    const queryOptions: any = {
      crop: false,
      width: Math.floor(width * pixelRatio),
      height: Math.floor(height * pixelRatio),
      v: this.hasImage ? new Date(this.updatedOn).getTime() : 1
    }

    // Set the url
    return `${apiEndpoint}/files/images/workspaces/${this.id}?${new URLSearchParams(queryOptions).toString()}`
  }

  getDefaultSubdomain(accountService?: AccountService): Subdomain {
    if (this.subdomains && this.subdomains.length > 0) {
      const defaultSubdomain = this.subdomains.find(subdomain => subdomain.isDefault)

      return defaultSubdomain ? defaultSubdomain : this.subdomains[0]
    } else {
      // If the workspace does not have subdomain, logout the user (store corrupted or API bad response)
      if (accountService) {
        accountService.logout(null, null, null, { message: `Workspace does not have subdomain` })
      }

      return {
        name: 'www',
        isDefault: false
      }
    }
  }

  /**
   * Returns whether a feature is toggled for this specific workspace
   * @param {WorkspaceFeature} feature
   * @returns {boolean}
   */
  isFeatureEnabled(feature: WorkspaceFeature): boolean {
    // In the future we could have more features to toggle, for this reason we use a switch
    switch (feature) {
      case WorkspaceFeature.Scim:
        return this.settings?.scimFeature?.show
      default:
        return true
    }
  }

  /**
   * Determines if the current workspace was created before the free plan removal
   * @returns {boolean}
   */
  get isGrandfatheredWorkspace(): boolean {
    // Free plan is removed from 31.01.2020
    const freePlanRemovalDate = new Date(2020, 0, 31)

    return this.createdOn.getTime() < freePlanRemovalDate.getTime()
  }

  /**
   * Get workspace URL
   * E.g: https://custom-subdomain.awork.com
   */
  get workspaceUrl(): string {
    const hostname = getHostname()
    return `https://${this.getDefaultSubdomain().name}.${hostname}`
  }
}
