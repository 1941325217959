import {
  addDays,
  addHours,
  addSeconds,
  addWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInYears,
  endOfDay,
  format,
  getISOWeek,
  getMonth,
  getYear,
  isSameISOWeek,
  isSameWeek,
  isSameYear,
  isTomorrow,
  isWithinRange,
  isYesterday,
  startOfDay,
  subDays,
  subHours,
  subSeconds
} from '@awork/_shared/functions/date-fns-wrappers'
import { stringToTime } from '@awork/_shared/functions/time-operations'
import type { Time } from '@angular/common'
import { Translations } from '@awork/i18n/translations'
import { ISOLanguage } from '@awork/_shared/models/account.model'

export enum DateCategories {
  Now = 'NOW',
  WithinLastHour = 'WITHIN_LAST_HOUR',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  LastWeek = 'LAST_WEEK',
  FiveDaysAgo = 'FIVE_DAYS_AGO',
  WithinAnHour = 'WITHIN_AN_HOUR',
  Tomorrow = 'TOMORROW',
  InFiveDays = 'IN_FIVE_DAYS',
  None = 'NONE'
}

export interface DateRange {
  startDate: Date
  endDate: Date
}

/**
 * Converts a date to 'YYYY-MM-DD' string
 * @param {Date | string} date
 * @param {boolean} useUTC - If true, it will use UTC date values
 * @return {string}
 */
export function dateToISO(date: Date | string, useUTC?: boolean): string {
  let newDate: Date

  if (date && !(date instanceof Date)) {
    newDate = new Date(date)
  } else {
    newDate = date as Date
  }

  if (!newDate) {
    return null
  }

  if (useUTC) {
    const year = newDate.getUTCFullYear()
    const month = String(newDate.getUTCMonth() + 1).padStart(2, '0')
    const date = String(newDate.getUTCDate()).padStart(2, '0')

    return `${year}-${month}-${date}`
  }

  return format(newDate, 'yyyy-MM-dd')
}

export function stringToDate(dateStr: string, isMonthFirst = false): Date {
  if (dateStr.trim().includes('T')) {
    return !!Date.parse(dateStr) ? new Date(dateStr) : null
  }
  let date: Date
  let separator = '/'

  // Normalize string
  dateStr = dateStr.trim().replace(',', '')

  // Get what is after the first white space
  const timeString = dateStr.includes(' ') ? dateStr.substr(dateStr.indexOf(' ') + 1) : null
  let time: Time

  if (dateStr.includes('-')) {
    separator = '-'
  } else if (dateStr.includes('.')) {
    separator = '.'
  }

  if (timeString) {
    time = stringToTime(timeString)
  }

  const inputDate = dateStr
    .trim()
    .substring(0, dateStr.indexOf(' ') > 0 ? dateStr.indexOf(' ') : undefined)
    .split(separator)

  // Remove last item if empty (dateStr ended with separator)
  if (!inputDate[inputDate.length - 1]) {
    inputDate.pop()
  }

  if (inputDate?.length > 1) {
    // If input length === 2, it means the year is not included, so by default here we add current year as default
    if (inputDate.length === 2) {
      inputDate[2] = `${new Date().getFullYear()}`
    }

    const yearInput = Number(inputDate[2])
    const year = inputDate[2].length === 2 ? getFullYearFromTwoDigits(yearInput) : yearInput
    const month = isMonthFirst ? Number(inputDate[0]) - 1 : Number(inputDate[1]) - 1
    const day = isMonthFirst ? Number(inputDate[1]) : Number(inputDate[0])

    date = new Date(year, month, day, time ? time.hours : null, time ? time.minutes : null)
  }

  return date && !isNaN(date.getTime()) ? date : null
}

/**
 * Converts a date to c# timestap
 * https://stackoverflow.com/questions/7966559/how-to-convert-javascript-date-object-to-ticks
 * @param {Date} date
 * @return {string}
 */
export function dateToCSharpTicksForLinqToQueryString(date: Date): string {
  // the number of .net ticks at the unix epoch
  const epochTicks = 621355968000000000

  // there are 10000 .net ticks per millisecond
  const ticksPerMillisecond = 10000

  // calculate the total number of .net ticks for your date
  const csharpTicks = epochTicks + date.getTime() * ticksPerMillisecond

  // Linq to query string needs a 'L' at the end to indidcate it's a long and not an int.
  return csharpTicks + 'L'
}

export function getWeekYearString(
  date: Date | string,
  translations: Translations['en'] & { currentLang: ISOLanguage }
): string {
  const today = new Date()

  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (isSameISOWeek(date, today)) {
    return translations.dates.thisWeek
  }

  if (isSameISOWeek(date, addWeeks(today, -1))) {
    return translations.dates.lastWeek
  }

  const week = getISOWeek(date)
  const year = getYear(date)

  // Don't include year if date is from current year
  if (isSameYear(date, today)) {
    return `${translations.dates.calendarWeekShort} ${week}`
  }

  return `${translations.dates.calendarWeekShort} ${week} ${year}`
}

export function getMonthYearString(
  date: Date | string,
  translations: Translations['en'] & { currentLang: ISOLanguage }
): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  const translatedMonthArray = [
    translations.DateSelectorComponent.january,
    translations.DateSelectorComponent.february,
    translations.DateSelectorComponent.march,
    translations.DateSelectorComponent.april,
    translations.DateSelectorComponent.may,
    translations.DateSelectorComponent.june,
    translations.DateSelectorComponent.july,
    translations.DateSelectorComponent.august,
    translations.DateSelectorComponent.september,
    translations.DateSelectorComponent.october,
    translations.DateSelectorComponent.november,
    translations.DateSelectorComponent.december
  ]

  const month = getMonth(date)
  const year = getYear(date)

  return `${translatedMonthArray[month]}, ${year}`
}

/**
 * Returns the date category were the date belongs
 * @param {Date | string} value
 * @param {Date} now
 * @returns {DateCategories}
 */
export function getDateCategory(value: Date | string, now = new Date()): DateCategories {
  if (value === 'today') {
    value = new Date()
  }

  const dateValue = value instanceof Date ? value : new Date(value)

  const isEarlierNow = isWithinRange(dateValue, subSeconds(now, 60), now)
  if (isEarlierNow) {
    return DateCategories.Now
  }

  const isWithinLastHour = isWithinRange(dateValue, subHours(now, 1), now)
  if (isWithinLastHour) {
    return DateCategories.WithinLastHour
  }

  const isEarlierToday = isWithinRange(dateValue, startOfDay(now), now)
  if (isEarlierToday) {
    return DateCategories.Today
  }

  if (isYesterday(dateValue)) {
    return DateCategories.Yesterday
  }

  const is5DaysAgo = isWithinRange(dateValue, subDays(now, 5), now)
  if (is5DaysAgo) {
    return DateCategories.FiveDaysAgo
  }

  // TODO: maybe we should use difference in calendar weeks?
  const isLastWeek = isSameWeek(dateValue, addWeeks(now, -1))
  if (isLastWeek) {
    return DateCategories.LastWeek
  }

  const isLaterNow = isWithinRange(dateValue, now, addSeconds(now, 60))
  if (isLaterNow) {
    return DateCategories.Now
  }

  const isWithinHour = isWithinRange(dateValue, now, addHours(now, 1))
  if (isWithinHour) {
    return DateCategories.WithinAnHour
  }

  const isLaterToday = isWithinRange(dateValue, now, endOfDay(now))
  if (isLaterToday) {
    return DateCategories.Today
  }

  if (isTomorrow(dateValue)) {
    return DateCategories.Tomorrow
  }

  const isIn5Days = isWithinRange(dateValue, now, addDays(now, 5))
  if (isIn5Days) {
    return DateCategories.InFiveDays
  }

  return DateCategories.None
}

/**
 * Returns the date with the time adjusted to 00:00 of the local timezone
 * This is useful when we need only the date (without time), in the local
 * timezone as T:00:00 from the original utc date (for example for birthdates)
 * @param {Date|string} date
 */
export function adjustDateToLocalDateStart(date: Date | string): Date {
  if (!(date instanceof Date)) {
    date = new Date(date)
  } else {
    date = new Date(date.getTime()) // Avoid mutating the original date's reference
  }

  // Sets the utc date to 00:00:00
  date.setUTCHours(0, 0, 0, 0)

  // Applies the timezone offset so the local representation stays at 00:00:00
  const timezoneOffset = date.getTimezoneOffset() * 60000
  date.setTime(date.getTime() + timezoneOffset)

  return date
}

/**
 * Transform a date to YYYYMMDD number
 * @param {Date | string} date
 * @returns {Date}
 */
export function dateToNumeric(date: Date | string): number {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return Number.parseInt(`${year}${month}${day}`)
}

/**
 * Check if a date is an instance of Date
 * @param {Date | string} date
 * @returns {boolean}
 */
export function isInstanceOfDate(date: Date | string): date is Date {
  return date instanceof Date
}

/**
 * Checks if a date is within two dates ignoring the times
 * @param {Date | string} date
 * @param {Date} start
 * @param {Date} end
 * @returns {boolean}
 */
export function isDateWithinRange(date: string | Date, start: Date, end: Date): boolean {
  const dayToCheck = isInstanceOfDate(date) ? date : new Date(date)

  const numericDay = dateToNumeric(dayToCheck)
  const numericStart = dateToNumeric(start)
  const numericEnd = dateToNumeric(end)

  return numericDay >= numericStart && numericDay <= numericEnd
}

/**
 * Checks if two date ranges overlap ignoring the times
 * @param {{start: Date, end: Date}} rangeA
 * @param {{start: Date, end: Date}} rangeB
 * @returns {boolean}
 */
export function areDatesOverlapping(rangeA: { start: Date; end: Date }, rangeB: { start: Date; end: Date }): boolean {
  const rangeAStart = dateToNumeric(rangeA.start)
  const rangeAEnd = dateToNumeric(rangeA.end)

  const rangeBStart = dateToNumeric(rangeB.start)
  const rangeBEnd = dateToNumeric(rangeB.end)

  return rangeAStart <= rangeBEnd && rangeAEnd >= rangeBStart
}

/**
 * Return local date without time from UTC string
 * @param utcString
 */
export function getLocalDateFromUTCString(utcString: Date | string): Date {
  if (utcString instanceof Date) {
    return utcString
  }

  const day = new Date(utcString).getUTCDate()
  const month = new Date(utcString).getUTCMonth()
  const year = new Date(utcString).getUTCFullYear()

  return new Date(year, month, day)
}

/**
 * Returns the difference between two dates and the unit of the difference
 * @param {string | Date} leftDate
 * @param {string | Date} rightDate
 * @returns {{difference: number, unit: "years" | "months" | "days" | "hours"}}
 */
export function formattedDateDifference(
  leftDate: string | Date,
  rightDate: string | Date
): { difference: number; unit: 'years' | 'months' | 'days' | 'hours' } {
  let years = 0
  let months = 0
  let days = 0
  let hours = 0

  years = differenceInYears(leftDate, rightDate)
  if (years) {
    return { difference: Math.abs(years), unit: 'years' }
  }

  months = differenceInMonths(leftDate, rightDate)
  if (months) {
    return { difference: Math.abs(months), unit: 'months' }
  }

  days = differenceInDays(leftDate, rightDate)
  if (days) {
    return { difference: Math.abs(days), unit: 'days' }
  }

  hours = differenceInHours(leftDate, rightDate)
  if (hours) {
    return { difference: Math.abs(hours), unit: 'hours' }
  }

  return { difference: 0, unit: 'hours' }
}

/**
 * Returns segments of the difference between two dates given a segment size
 * @param {Date} start
 * @param {Date} end
 * @param {number} segmentSize - in days
 * @returns {DateRange}
 */
export function getRangeSegments(start: Date, end: Date, segmentSize: number): DateRange[] {
  const endDate = new Date(end)
  endDate.setUTCHours(23, 59, 59)
  let startDate = new Date(start)
  startDate.setUTCHours(0, 0, 0, 0)

  let segmentEndDate = addDays(startDate, segmentSize - 1)
  segmentEndDate.setUTCHours(23, 59, 59)

  let dateSegments = []
  while (dateToNumeric(segmentEndDate) < dateToNumeric(endDate)) {
    dateSegments.push({ startDate, endDate: segmentEndDate })

    startDate = addDays(segmentEndDate, 1)
    segmentEndDate = addDays(startDate, segmentSize - 1)
    startDate.setUTCHours(0, 0, 0, 0)
  }

  const lastEndDate = dateSegments[dateSegments.length - 1]?.endDate
  const hasRemainingDays =
    dateToNumeric(startDate) < dateToNumeric(endDate) ||
    (lastEndDate && dateToNumeric(lastEndDate) < dateToNumeric(endDate))

  if (hasRemainingDays) {
    endDate.setUTCHours(23, 59, 59)
    dateSegments.push({ startDate, endDate })
  }

  return dateSegments
}

/**
 * Calculates the full year from two digits utilizing a "windowing" technique
 * of max 100 years (https://www.uic.edu/depts/accc/software/isodates/fixing.html)
 * Ex: if today is 2022, any value lower than 42 is 2000s otherwise 1900s
 * @param {number} input
 * @param {number} year
 */
export function getFullYearFromTwoDigits(input: number, year?: number): number {
  if (!year) {
    year = new Date().getFullYear()
  }

  const window = 80
  const minYear = year - window

  let result = input + 1900

  if (result < minYear) {
    result += 100
  }

  return result
}

/**
 * Returns the last month given a date with the first date
 * as the first day of the month
 * Ex: if today is 04/2024 returns 03/2024, if it's January returns 12/2023
 * @param {Date | string} date
 * @returns {Date}
 */
export function getLastMonth(date: Date | string): Date {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  date.setDate(1)

  if (date.getMonth() === 0) {
    date.setFullYear(date.getFullYear() - 1)

    date.setMonth(11)
  } else {
    date.setMonth(date.getMonth() - 1)
  }

  return date
}
