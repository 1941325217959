import { app } from '@awork/environments/environment'
import { AccountState, ISOLanguage } from '@awork/_shared/models/account.model'
import { PersistState } from '@awork/core/state/signal-store/persistState'
import { AppState } from '@awork/core/state/app.store'

export type Language = 'en' | 'de'

export interface LanguageObj {
  language: Language
  iso: ISOLanguage
}

interface StoredLanguage {
  appLanguage?: ISOLanguage
  accountLanguage?: ISOLanguage
}

/**
 * Gets the language from localStorage.
 * @param {boolean} iso
 */
export default function getLanguage(iso?: false): Language
export default function getLanguage(iso?: true): ISOLanguage
export default function getLanguage(iso = false): Language | ISOLanguage {
  let storedLang: LanguageObj = {
    language: 'en',
    iso: 'en-GB'
  }

  if (app === 'web') {
    // Try to get the language from the localStorage
    try {
      const store = localStorage.getItem('awLanguage')
      storedLang = store ? JSON.parse(store) : storedLang
    } catch (_) {}
  } else {
    storedLang = getDeviceLanguage()
  }

  return iso ? storedLang.iso : storedLang.language
}

/**
 * Sets the language according to queryParam, store or browser language.
 * Also, it store it in localStorage
 */
export async function setLanguage(): Promise<LanguageObj> {
  const urlLang = new URL(location.href).searchParams.get('lang')?.toLowerCase() as Language | undefined
  const browserLang = window.navigator.language?.toLowerCase() as ISOLanguage | Language
  const storage = await getStorage()
  let accountLang: ISOLanguage | undefined
  let appLang: Language | undefined
  let localStoredLang: ISOLanguage | undefined

  const langObj: LanguageObj = {
    language: 'en',
    iso: 'en-GB'
  }

  // Try to get the stored language
  if (localStorage.getItem('awLanguage')) {
    try {
      const store = localStorage.getItem('awLanguage')
      const localStoredLangObj: LanguageObj = JSON.parse(store)
      localStoredLang = localStoredLangObj?.iso?.toLowerCase() as ISOLanguage | undefined
    } catch (_) {}
  }

  if ((storage.app || storage.account !== null) && !localStoredLang) {
    const storedLanguage = await getStoredLanguage(storage)

    accountLang = storedLanguage.accountLanguage?.toLowerCase() as ISOLanguage | undefined
    appLang = storedLanguage.appLanguage?.toLowerCase() as Language | undefined
  }

  if (
    urlLang !== null ||
    localStoredLang !== undefined ||
    accountLang !== undefined ||
    appLang !== undefined ||
    browserLang !== undefined
  ) {
    if (
      ['de', 'de-at', 'de-ch', 'de-de', 'de-li', 'de-lu'].includes(
        urlLang || localStoredLang || accountLang || appLang || browserLang
      )
    ) {
      langObj.language = 'de'
      langObj.iso = 'de-DE'
    }
  }

  // Store the language
  try {
    localStorage.setItem('awLanguage', JSON.stringify(langObj))
  } catch (_) {}

  return langObj
}

/**
 * Gets the stored language
 */
async function getStoredLanguage(storage: { account: string; app: string }): Promise<StoredLanguage> {
  const storedLanguage: StoredLanguage = {}

  try {
    const account: AccountState = typeof storage.account === 'string' ? JSON.parse(storage.account) : storage?.account
    const app: AppState = typeof storage.app === 'string' ? JSON.parse(storage.app) : storage?.app

    if (app) {
      storedLanguage.appLanguage = app.language
    }

    if (account) {
      storedLanguage.accountLanguage = account.language
    }
  } catch (_) {}

  return storedLanguage
}

/**
 * Gets the device language
 */
function getDeviceLanguage(): LanguageObj {
  const langObj: LanguageObj = {
    language: 'en',
    iso: 'en-GB'
  }

  const deviceLanguage = window.navigator.language.toLowerCase()

  if (deviceLanguage) {
    if (['de', 'de-at', 'de-ch', 'de-de', 'de-li', 'de-lu'].includes(deviceLanguage)) {
      langObj.language = 'de'
      langObj.iso = 'de-DE'
    }
  }

  return langObj
}

/**
 * Gets the account and app stores
 * @returns {Promise<{app: unknown, account: unknown} | {app: any, account: any}>}
 */
async function getStorage() {
  const isIndexedDBCapable = PersistState.isIndexedDBCapable()

  if (isIndexedDBCapable) {
    const accountStore = await PersistState.instance?.getItem('account')
    const appStore = await PersistState.instance?.getItem('app')

    return { account: accountStore?.['state'], app: appStore?.['state'] }
  } else {
    const accountStore = localStorage.getItem('account')
    const appStore = localStorage.getItem('app')

    return { account: accountStore?.['state'], app: appStore?.['state'] }
  }
}

/**
 * Gets the date format according to the language
 * @param showTime - True to show the datetime, false to show only date
 * @param showYear - True to show the year, false to hide year
 * @returns {string}
 */
export function getDateFormat(showTime = false, showYear = true): string {
  const lang = getLanguage()
  const yearFormat = showYear ? `${getDateSeparator()}yy` : ''

  if (lang === 'de') {
    return showTime ? `dd.MM${yearFormat} H:mm` : `dd.MM${yearFormat}`
  } else {
    return showTime ? `dd/MM${yearFormat} h:mm a` : `dd/MM${yearFormat}`
  }
}

/**
 * Gets the date separator according to the language
 * @returns {string}
 */
export function getDateSeparator(): string {
  const lang = getLanguage()

  return lang === 'de' ? '.' : '/'
}
