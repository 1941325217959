<article class="hotkey-info">
  <p
    *ngIf="label()"
    class="hotkey-info__label aw-2-txt--bold aw-2-txt--light-steel aw-2-pr--s"
  >
    {{ label() }}
  </p>

  <span
    *ngIf="firstKey()"
    class="hotkey-info__key aw-2-txt--light-steel aw-2-b--light-steel"
    [class.txt-small]="firstShownKey().toString().length > 2"
    [class.txt-tiny]="firstShownKey().toString().length > 3"
  >
    {{ firstShownKey() }}
  </span>

  <span
    *ngIf="firstKey() && secondKey()"
    class="hotkey-info__key aw-2-txt--light-steel aw-2-b--light-steel aw-2-ml--xs"
    [class.txt-small]="secondShownKey().toString().length > 2"
    [class.txt-tiny]="secondShownKey().toString().length > 3"
  >
    {{ secondShownKey() }}
  </span>

  <span
    *ngIf="firstKey() && secondKey() && thirdKey()"
    class="hotkey-info__key aw-2-txt--light-steel aw-2-b--light-steel aw-2-ml--xs"
    [class.txt-small]="thirdShownKey().toString().length > 2"
    [class.txt-tiny]="thirdShownKey().toString().length > 3"
  >
    {{ thirdShownKey() }}
  </span>
</article>
