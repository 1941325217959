import { Color, DeprecatedColor } from '@awork/_shared/types/color'
import { User } from '@awork/features/user/models/user.model'
import { getMappedColor } from '@awork/_shared/functions/map-deprecated-colors'
import { Icon } from '@awork/_shared/static-data/icons'

export const teamColors = [
  Color.Red,
  Color.Orange,
  Color.Yellow,
  Color.Teal,
  Color.Green,
  Color.Cyan,
  Color.Blue,
  Color.Purple,
  Color.Indigo
] as const

export type TeamColor = (typeof teamColors)[number]

export interface ITeam {
  id: string
  name: string
  color?: TeamColor | DeprecatedColor
  icon: Icon
  userIds?: string[]
  users?: User[]
  projectIds?: string[]
}

export class Team implements ITeam {
  id: string
  color?: TeamColor
  icon: Icon
  name: string
  userIds?: string[]
  users?: User[]
  projectIds?: string[]

  constructor(team: ITeam) {
    Object.assign(this, team)

    this.color = getMappedColor(team.color) as TeamColor
  }
}
