import { Observable, Subject, filter, take, ReplaySubject } from 'rxjs'
import { getBrowserInfo } from '@awork/_shared/functions/browser-info'
import { coerce, satisfies } from 'semver'

/**
 * IndexedDB wrapper based on IDB-Keyval: https://github.com/jakearchibald/idb-keyval
 * Had to copy the library's class/functions because there is no way to change the DB version
 * awork DB is version 3
 */
class Store {
  readonly _dbp: Promise<IDBDatabase>

  constructor(
    dbName = 'keyval-store',
    readonly storeName = 'keyval',
    readonly dbVersion = 1
  ) {
    this._dbp = new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(dbName, dbVersion)

      openRequest.onerror = errorEvent => {
        console.log(`Error loading database: ${errorEvent.type} ${openRequest.error}`)
        reject(openRequest.error)
      }

      openRequest.onsuccess = () => resolve(openRequest.result)

      // DB does not exists or not latest version
      openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = openRequest.result

        db.onerror = function (errorEvent) {
          console.log('Error loading database: ' + errorEvent.type)
        }

        const request = event.target as IDBOpenDBRequest

        // Check if object store exists
        const objectStoreExists = request.result?.objectStoreNames?.contains?.(storeName)

        // Delete old test DB and store key
        if (event.oldVersion && event.oldVersion < 4) {
          window.indexedDB.deleteDatabase('awTest')

          const store = request.transaction.objectStore(storeName)

          store.clear()
        }

        // First time setup: create an empty object store
        if (event.oldVersion === 0 || !objectStoreExists) {
          db.createObjectStore(storeName)
        }
      }
    })
  }

  _withIDBStore(type: IDBTransactionMode, callback: (store: IDBObjectStore) => void): Promise<void> {
    return this._dbp.then(
      db =>
        new Promise<void>((resolve, reject) => {
          const transaction = db.transaction(this.storeName, type)

          transaction.oncomplete = () => resolve()
          transaction.onabort = transaction.onerror = () => reject(transaction.error)
          callback(transaction.objectStore(this.storeName))
        })
    )
  }
}

function get<Type>(key: IDBValidKey, store: Store): Promise<Type> {
  const t0 = performance.now()

  let req: IDBRequest
  return store
    ._withIDBStore('readonly', dbStore => {
      req = dbStore.get(key)
    })
    .then(() => req.result)
    .catch(error => {
      if (error?.name === 'NotFoundError') {
        window.indexedDB.deleteDatabase('awork')

        location.reload()
      }
    })
    .finally(() => {
      const t1 = performance.now()

      if (window['awDebug']) {
        console.log(`Store restored - ${key}: ${Math.round(t1 - t0)} ms.`)
      }
    })
}

function set(key: IDBValidKey, value: any, store: Store): Promise<void> {
  const t0 = performance.now()

  return store
    ._withIDBStore('readwrite', dbStore => {
      dbStore.put(value, key)
    })
    .finally(() => {
      const t1 = performance.now()

      if (window['awDebug']) {
        console.log(`Store persisted - ${key}: ${Math.round(t1 - t0)} ms.`)
      }
    })
}

function clear(store: Store): Promise<void> {
  return store._withIDBStore('readwrite', dbStore => {
    dbStore.clear()
  })
}

/**
 * Async storage used by Signal store and Entity signal store's persist state storage
 */
export class PersistState {
  static instance: PersistState
  private store = new Store('awork', 'awState', 4)

  private onInit$ = new ReplaySubject<void>()
  private persisted$ = new Subject<string>()
  private restored$ = new Subject<string>()

  storage: PersistState | null

  constructor() {
    PersistState.instance = this
    this.storage = PersistState.isIndexedDBCapable() ? this : null
  }

  static init(): PersistState {
    const persistState = new PersistState()

    persistState.onInit$.next()

    return persistState
  }

  /**
   * Determines if the browser is indexedDB capable
   * @returns {boolean}
   */
  static isIndexedDBCapable(): boolean {
    try {
      const browserInfo = getBrowserInfo()
      const isOldSafari = browserInfo.name === 'Safari' && !satisfies(coerce(browserInfo.version), '>=15.x')

      return window.indexedDB && !isOldSafari
    } catch (_) {
      return false
    }
  }

  /**
   * Gets the item from the persistent storage
   * @param {string} key
   * @returns {Promise<T>}
   */
  getItem<T>(key: string): Promise<T> {
    return get<T>(key, this.store).then(value => {
      this.restored$.next(key)
      return value
    })
  }

  /**
   * Sets the item in the persistent storage
   * The write operation is debounced
   * @param {string} key
   * @param value
   */
  setItem(key: string, value: any): void {
    set(key, value, this.store).then(() => {
      this.persisted$.next(key)
    })
  }

  clear(): Promise<void> {
    return clear(this.store)
  }

  /**
   * Emits when the storage is initialized
   */
  onInit(): Observable<void> {
    return this.onInit$.asObservable()
  }

  /**
   * Emits when the specified store is persisted in device storage
   * @param {string} storeName
   * @returns {Observable<string>}
   */
  onPersisted(storeName: string): Observable<string> {
    return this.persisted$.pipe(
      filter(key => key === storeName),
      take(1)
    )
  }

  /**
   * Emits when the specified store is restored from device storage
   * If no storeName is provided, it emits when any store is restored
   * @param {string} storeName
   * @returns {Observable<string>}
   */
  onRestored(storeName?: string): Observable<string> {
    return storeName
      ? this.restored$.pipe(
          filter(key => key === storeName),
          take(1)
        )
      : this.restored$.asObservable()
  }
}
