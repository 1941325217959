<article
  #wrapper
  class="toast aw-2-sh--l aw-2-p--l"
  [@toastAnimation]
  [style.top.px]="top"
  [style.zIndex]="zIndex"
  [class.hideOnPrint]="printService.isPrinting"
  (@toastAnimation.done)="animationDone()"
>
  <article class="toast__wrapper">

    <!-- Close button -->
    <div *ngIf="(isHover || isTouchDevice) && options?.type !== 'fileUpload'" class="hide-button">
      <aw-close-button
        [size]="Size.s"
        (click)="close($event)"
      />
    </div>

    <!-- ----------------- -->
    <!-- Content templates -->
    <!-- ----------------- -->
    <ng-container [ngSwitch]="template">

      <!-- --------------------------- -->
      <!-- Default: Color + Icon + Text-->
      <!-- --------------------------- -->
      <ng-container *ngSwitchCase="'default'">
        <article
          #contentWrapper
          class="content-wrapper aw-2-pos--relative"
        >

          <!-- Image/Icon -->
          <article class="icon-wrapper">
            <ng-container [ngSwitch]="figureType">

              <!-- Image -->
              <div *ngSwitchCase="'image'" class="image" >
                <img [attr.src]="options?.imageSrc"/>
              </div>

              <!-- Icon -->
              <div
                *ngSwitchCase="'icon'"
                class="avatar type-icon"
                [ngClass]="'aw-2-bg--' + colorClass"
              >
                <i
                  class="aw-2-icn aw-2-pos--center-all"
                  [ngClass]="'aw-2-icn--' + colorClass"
                >
                  {{ icon }}
                </i>
              </div>

              <!-- Avatar -->
              <div *ngSwitchCase="'avatar'" class="aw-2-mr--s" >
                <aw-notification-avatar [notification]="options?.notification"/>
              </div>

              <!-- Emoji -->
              <div *ngSwitchCase="'emoji'" class="avatar gradient">
                <span class="aw-2-pos--center-all">{{ options.emoji }}</span>
              </div>
            </ng-container>
          </article>

          <!-- Text -->
          <div class="text-wrapper">
            <ng-container>
              <!-- Type name -->
              <p *ngIf="type" class="aw-2-txt--body-tiny" [ngClass]="'aw-2-txt--' + colorClass">{{ type }}</p>

              <!-- Title -->
              <div *ngIf="options?.title" class="text aw-2-txt--bold aw-2-txt--night" [innerHTML]="options?.title"></div>

              <!-- Subtitle -->
              <div
                *ngIf="options?.subtitle"
                class="text aw-2-txt--body-small aw-2-txt--steel aw-2-txt--bold"
                [innerHTML]="options?.subtitle">
              </div>

              <!-- Comment message -->
              <div *ngIf="message" class="text aw-2-txt--night aw-2-txt--boldt" [innerHTML]="message"></div>
            </ng-container>
          </div>

          <!-- Reminder's entity list -->
          <ng-container *ngIf="isNotificationGroup()">
            <article class="list-wrapper aw-2-mt--m">
              <div
                *ngFor="let notification of options?.notification?.notifications"
                class="list-item aw-2-txt--body-small"
                (click)="executeAction($event, notification)"
              >
                {{ notification.entityName }}
              </div>
            </article>
          </ng-container>
        </article>
      </ng-container>

      <!-- ----------- -->
      <!-- File upload -->
      <!-- ----------- -->
      <ng-container *ngSwitchCase="'upload'">
        <div class="file-upload-wrapper">
          <aw-file-upload-progress *ngFor="let fileUpload of fileUploads; trackBy: trackFileUploads" class="aw-2-p--0" [fileUpload]="fileUpload"/>
        </div>
      </ng-container>
    </ng-container>

    <article *ngIf="showActionButton" class="aw-2-pt--s">
      <aw-button
        [color]="buttonColor"
        [size]="Size.m"
        [type]="'secondary'"
        [text]="options?.actionText || options?.notification?.actionText"
        [widthType]="'block'"
        (click)="executeAction($event, options?.notification)"
      />
    </article>

  </article>
</article>
