import { Injectable } from '@angular/core'
import { IQSubscription } from '@awork/_shared/models/subscription.model'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'

export type SubscriptionState = IQSubscription

export function createInitialState(): IQSubscription {
  return {
    workspaceId: null,
    planId: null,
    contractEnd: null,
    status: null,
    previousStatus: null,
    bookedSeats: null,
    usedUserSeats: null,
    usedGuestSeats: null,
    remainingUsers: null,
    remainingGuests: null,
    totalProjects: null,
    trialEnd: null,
    usedPaidTrial: null
  }
}
const PERSIST_DELAY = 2000

@Injectable({ providedIn: 'root' })
export class SubscriptionStore extends SignalStore<SubscriptionState> {
  constructor() {
    super({
      initialState: createInitialState(),
      name: 'subscription',
      persistDelay: PERSIST_DELAY
    })
  }

  /**
   * Updates the remaining seats
   * @param amount
   */
  updateRemainingSeats(amount = -1): void {
    this.update(subscription => {
      return { remainingUsers: subscription.remainingUsers + amount }
    })
  }
}
