var langRegionMap = {
  en: 'US',
  zh: 'CN',
  zh_hans: 'CN',
  hans: 'CN',
  wuu: 'CN',
  hsn: 'CN',
  hak: 'CN',
  nan: 'CN',
  gan: 'CN',
  hi: 'IN',
  te: 'IN',
  mr: 'IN',
  ta: 'IN',
  gu: 'IN',
  kn: 'IN',
  or: 'IN',
  ml: 'IN',
  pa_guru: 'IN',
  bho: 'IN',
  awa: 'IN',
  as: 'IN',
  mwr: 'IN',
  mai: 'IN',
  mag: 'IN',
  bgc: 'IN',
  hne: 'IN',
  dcc: 'IN',
  dz: 'BT',
  tn: 'BW',
  am: 'ET',
  om: 'ET',
  quc: 'GT',
  id: 'ID',
  jv: 'ID',
  su: 'ID',
  mad: 'ID',
  ms_arab: 'ID',
  ga: 'IE',
  he: 'IL',
  jam: 'JM',
  ja: 'JP',
  km: 'KH',
  ko: 'KR',
  lo: 'LA',
  mh: 'MH',
  my: 'MM',
  mt: 'MT',
  ne: 'NP',
  fil: 'PH',
  ceb: 'PH',
  ilo: 'PH',
  ur: 'PK',
  pa: 'PK',
  pa_arab: 'PK',
  arab: 'PK',
  lah: 'PK',
  ps: 'PK',
  sd: 'PK',
  sd_arab: 'PK',
  skr: 'PK',
  gn: 'PY',
  th: 'TH',
  tts: 'TH',
  aeb: 'TN',
  zh_hant: 'TW',
  hant: 'TW',
  sm: 'WS',
  zu: 'ZA',
  sn: 'ZW',
  arq: 'DZ',
  ar: 'EG',
  arz: 'EG',
  fa: 'IR',
  az_arab: 'IR',
  ary: 'MA',
  bn: 'BD',
  rkt: 'BD',
  dv: 'MV'
};
export default langRegionMap;
