const parse = require('./parse.js');
const diff = (version1, version2) => {
  const v1 = parse(version1, null, true);
  const v2 = parse(version2, null, true);
  const comparison = v1.compare(v2);
  if (comparison === 0) {
    return null;
  }
  const v1Higher = comparison > 0;
  const highVersion = v1Higher ? v1 : v2;
  const lowVersion = v1Higher ? v2 : v1;
  const highHasPre = !!highVersion.prerelease.length;
  const lowHasPre = !!lowVersion.prerelease.length;
  if (lowHasPre && !highHasPre) {
    // Going from prerelease -> no prerelease requires some special casing

    // If the low version has only a major, then it will always be a major
    // Some examples:
    // 1.0.0-1 -> 1.0.0
    // 1.0.0-1 -> 1.1.1
    // 1.0.0-1 -> 2.0.0
    if (!lowVersion.patch && !lowVersion.minor) {
      return 'major';
    }

    // Otherwise it can be determined by checking the high version

    if (highVersion.patch) {
      // anything higher than a patch bump would result in the wrong version
      return 'patch';
    }
    if (highVersion.minor) {
      // anything higher than a minor bump would result in the wrong version
      return 'minor';
    }

    // bumping major/minor/patch all have same result
    return 'major';
  }

  // add the `pre` prefix if we are going to a prerelease version
  const prefix = highHasPre ? 'pre' : '';
  if (v1.major !== v2.major) {
    return prefix + 'major';
  }
  if (v1.minor !== v2.minor) {
    return prefix + 'minor';
  }
  if (v1.patch !== v2.patch) {
    return prefix + 'patch';
  }

  // high and low are preleases
  return 'prerelease';
};
module.exports = diff;