import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { Contact } from '@awork/_shared/models/contact.model'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { getAssetImage } from '@awork/_shared/functions/get-asset-image'
import { AccountState } from '@awork/_shared/models/account.model'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { CompanyQuery } from '@awork/features/company/state/company.query'
import { Tag } from '@awork/_shared/models/tag.model'

const companyEntityList: string[] = [
  'GmbH',
  'GmbH & Co. KG',
  'Inc',
  'AG & Co. KG',
  'AG',
  'Ltd',
  'EEIG',
  'SE',
  'SCE',
  'SPE'
]

interface ICompany {
  id: string
  name: string
  description: string
  industry: string
  image: string
  hasImage: boolean
  tags: Tag[]
  companyContactInfos?: Contact[]
  updatedOn?: Date
  resourceVersion: number
  projectsCount?: number
  projectsInProgressCount?: number
}

/**
 * The company in the awork
 */
export class Company implements ICompany {
  id: string
  name: string
  description: string
  industry: string
  image: string
  hasImage: boolean
  tags: Tag[]
  companyContactInfos?: Contact[]
  updatedOn?: Date
  resourceVersion: number // the version from the API MV
  projectsCount?: number
  projectsInProgressCount?: number
  typeName = 'Company'

  constructor(company: Partial<ICompany>) {
    Object.assign(this, company)

    if (this.companyContactInfos) {
      this.companyContactInfos = this.companyContactInfos.map(contact => new Contact(contact))
    }
  }

  static isCompany(company: any): company is Company {
    return (company as Company)?.typeName === 'Company'
  }

  get urlName(): string {
    return decodeURIComponent(this.name.replace(/ /g, '-'))
  }

  static get defaultImage(): string {
    return getAssetImage('visuals/logo-white-on-blue.svg')
  }

  /**
   * Get the image url of the company if set. Else returns the default image
   * @param width
   * @param height
   * @param companyQuery
   * @param crop
   */
  getImage(width = 500, height = 500, companyQuery?: CompanyQuery, crop = true): string {
    const pixelRatio = getPixelRatio()

    // Get the company from the store to use the updatedOn in case this model does not have one
    if (!this.updatedOn && companyQuery) {
      const company = companyQuery.getCompany(this.id)

      if (company) {
        this.updatedOn = company.updatedOn
      }
    }

    let queryOptions: any = {
      width: Math.floor(width * pixelRatio),
      height: Math.floor(height * pixelRatio),
      v: this.updatedOn ? new Date(this.updatedOn).getTime() : undefined
    }

    if (crop) {
      queryOptions.crop = true
    }

    const accountState = this.getAccountState()
    // To fetch the images also locally
    if (isLiveMobile && !!accountState) {
      const accessToken = accountState.accessToken as string

      queryOptions = {
        ...queryOptions,
        jwt: accessToken,
        'aw-mobile': 'true'
      }
    }

    const url = `${apiEndpoint}/files/images/companies/${this.id}?${new URLSearchParams(queryOptions).toString()}`

    // Fallback to default if there is no image, or there is no access
    // token - otherwise the image requests would be unauthorized
    const shouldUseDefault = isLiveMobile ? !this.hasImage || queryOptions.jwt === null : !this.hasImage

    return shouldUseDefault ? Company.defaultImage : url
  }

  /**
   * Returns the account state
   * @returns {AccountState}
   */
  getAccountState(): AccountState {
    return AccountQuery.instance?.getAccount()
  }

  /**
   * The initials of the company
   * @returns {string}
   */
  get initials(): string {
    if (!this.name) {
      return null
    }

    const companyName = this.removeLegalEntity(this.name)

    const upperCaseInitials = companyName.match(/[A-Z]/g)
    const initials = upperCaseInitials || companyName.match(/\b[a-zA-Z]/g)

    return initials ? initials.slice(0, 2).toString().toUpperCase().replace(/,/g, '') : null
  }

  /**
   * Removes company's legal entities from name
   * @returns {string}
   */
  private removeLegalEntity(legalName): string {
    return companyEntityList.reduce((companyName, entity) => {
      return legalName.includes(entity) ? legalName.split(entity)[0] : companyName
    }, legalName)
  }
}
