export type BoardGroupByOptions = 'user' | 'list' | 'none'

export enum GroupByOptions {
  none = 'none',
  project = 'project',
  projectType = 'projectType',
  typeOfWork = 'typeOfWork',
  upcomingDaily = 'upcomingDaily',
  upcomingWeekly = 'upcomingWeekly',
  upcomingMonthly = 'upcomingMonthly',
  projectStatus = 'projectStatus',
  projectStatusType = 'projectStatusType',
  responsibleUser = 'responsibleUser',
  dueMonth = 'dueMonth',
  dueWeek = 'dueWeek',
  client = 'client',
  user = 'user',
  list = 'list',
  taskLists = 'taskLists'
}

export enum ProjectSortByOptions {
  client = 'client',
  status = 'status',
  dueDate = 'dueDate',
  startDate = 'startDate',
  name = 'name',
  budget = 'budget',
  taskProgress = 'taskProgress',
  timeProgress = 'timeProgress',
  default = 'default'
}

export enum TimeTrackingReportSortByOptions {
  dateUpdated = 'dateUpdated',
  reportName = 'reportName'
}

export enum DocsGroupByOptions {
  none = 'none',
  task = 'task',
  fileType = 'fileType',
  user = 'user'
}

export enum DocsSortByOptions {
  date = 'date',
  name = 'name',
  task = 'task'
}

export enum TimeTrackingTimeFrameOptions {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  AllTime = 'allTime'
}

export enum TimeTrackingGroupingOptions {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
  User = 'user',
  TypeOfWork = 'typeOfWork',
  Billability = 'billability',
  Project = 'project'
}
