import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { Task } from '@awork/features/task/models/task.model'
import { Project } from '@awork/features/project/models/project.model'
import { TimeTracking } from '@awork/features/time-tracking/models/time-tracking.model'

type ExpirableEntity = Task | Project | TimeTracking
/**
 * Delete all expired entities from the store
 */
export function deleteExpiredEntities<Entity extends ExpirableEntity>(store: EntitySignalStore<Entity>): void {
  const ttl = 172_800_000 // 2 days
  const nowTimespan = new Date().getTime()

  store.remove((entity: Entity) => !entity.modifiedOn || nowTimespan - entity.modifiedOn > ttl)
}
