import { SignalStore, StoreState } from '@awork/core/state/signal-store/signalStore'
import { computed, Signal } from '@angular/core'
import { distinctUntilChanged, map, Observable } from 'rxjs'
import { getState } from '@ngrx/signals'
import { compareKeys } from '@awork/core/state/signal-store/helpers'

/**
 * Query class for signal stores.
 * Provides functions to query the store.
 *
 * ### Usage example
 * ```ts
 * @Injectable({ providedIn: 'root' })
 * export class AppQuery extends SignalQuery<AppState> {
 *   constructor(private appStore: AppStore) {
 *     super(appStore)
 *   }
 * }
 * ```
 */
export class SignalQuery<State extends object = {}> {
  private readonly signalStore: SignalStore<State>
  private readonly state: StoreState<State>
  private readonly stateSignal: Signal<State>

  constructor(store: SignalStore<State>) {
    this.signalStore = store
    this.state = store.getState()
    this.stateSignal = computed(() => getState(this.state))
  }

  /**
   * Gets the loading state
   * @returns {Signal<boolean>}
   */
  queryLoading(): Signal<boolean> {
    return this.signalStore.getIsLoading()
  }

  /**
   * Selects the loading state
   * @return {Observable<boolean>}
   */
  selectLoading(): Observable<boolean> {
    return this.signalStore.isLoading$
  }

  /**
   * Gets the error state
   * @returns {Signal<Error>}
   */
  queryError(): Signal<Error> {
    return this.signalStore.getError()
  }

  /**
   * Selects the error state
   * @return {Observable<Error>}
   */
  selectError(): Observable<Error> {
    return this.signalStore.error$
  }

  /**
   * Selects the state
   * Optionally, it can select a property of the state
   * @returns {Observable<State | State[K]>}
   */
  select(): Observable<State>
  select<K extends keyof State>(property: K): Observable<State[K]>
  select<K extends keyof State>(property?: K): Observable<State | State[K]> {
    if (property) {
      return this.signalStore.state$.pipe(
        distinctUntilChanged(compareKeys([property as string])),
        map(state => state[property] as State[K])
      )
    }
    return this.signalStore.state$
  }

  /**
   * Gets the value of the state
   * @returns {State}
   */
  getValue(): State {
    return getState(this.state)
  }

  /**
   * Gets the state
   * Optionally, it can get a property of the state
   * @returns {Signal<State | State[K]>}
   */
  query(): Signal<State>
  query<K extends keyof State>(property: K): Signal<State[K]>
  query<K extends keyof State>(property?: K): Signal<State | State[K]> {
    if (property) {
      return this.state[property] as Signal<State[K]>
    }

    return this.stateSignal
  }
}
