const SemVer = require('../classes/semver');
const parse = (version, options, throwErrors = false) => {
  if (version instanceof SemVer) {
    return version;
  }
  try {
    return new SemVer(version, options);
  } catch (er) {
    if (!throwErrors) {
      return null;
    }
    throw er;
  }
};
module.exports = parse;