var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
function stringifyAttribute(name, value) {
  if (!value) {
    return '';
  }
  var stringified = '; ' + name;
  if (value === true) {
    return stringified; // boolean attributes shouldn't have a value
  }
  return stringified + '=' + value;
}
function stringifyAttributes(attributes) {
  if (typeof attributes.expires === 'number') {
    var expires = new Date();
    expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e+5);
    attributes.expires = expires;
  }
  return stringifyAttribute('Expires', attributes.expires ? attributes.expires.toUTCString() : '') + stringifyAttribute('Domain', attributes.domain) + stringifyAttribute('Path', attributes.path) + stringifyAttribute('Secure', attributes.secure) + stringifyAttribute('SameSite', attributes.sameSite);
}
export function encode(name, value, attributes) {
  return encodeURIComponent(name).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent) // allowed special characters
  .replace(/\(/g, '%28').replace(/\)/g, '%29') // replace opening and closing parens
  + '=' + encodeURIComponent(value)
  // allowed special characters
  .replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent) + stringifyAttributes(attributes);
}
export function parse(cookieString) {
  var result = {};
  var cookies = cookieString ? cookieString.split('; ') : [];
  for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
    var cookie = cookies_1[_i];
    var parts = cookie.split('=');
    var value = parts.slice(1).join('=');
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }
    try {
      var name_1 = decodeURIComponent(parts[0]);
      result[name_1] = value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
    } catch (e) {
      // ignore cookies with invalid name/value encoding
    }
  }
  return result;
}
export function getAll() {
  return parse(document.cookie);
}
export function get(name) {
  return getAll()[name];
}
export function set(name, value, attributes) {
  document.cookie = encode(name, value, __assign({
    path: '/'
  }, attributes));
}
export function remove(name, attributes) {
  set(name, '', __assign(__assign({}, attributes), {
    expires: -1
  }));
}