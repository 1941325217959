// @ts-ignore
import _cloneShallow from 'lodash.clone'
// @ts-ignore
import _cloneDeep from 'lodash.clonedeep'
import _deepEqual from 'fast-deep-equal'
import camelcase from 'camelcase'
import { floatRegex } from '@awork/_shared/static-data/regular-expressions'

/**
 * Convert a string to camel case.
 *
 * ```
 * camelCase('camel-case')
 * // => 'camelCase'
 * ```
 */
export function camelCase(string?: string): string {
  return string !== null && string !== undefined ? camelcase(string) : ''
}

/**
 * Create a shallow clone of a value.
 *
 * ```
 * a = { prop: {} }
 * b = cloneShallow(a)
 * a === b
 * // => false
 * a.prop === b.prop
 * // => true
 * ```
 *
 * Note: Use `cloneDeep` if you need a deep clone instead.
 */
export function cloneShallow<T>(value: T): T {
  return _cloneShallow(value)
}

/**
 * Create a deep clone of a value.
 *
 * ```
 * a = { prop: {} }
 * b = cloneDeep(a)
 * a === b
 * // => false
 * a.prop === b.prop
 * // => false
 * ```
 *
 * Note: Use `cloneShallow` if you need a shallow clone instead.
 */
export function cloneDeep<T>(value: T): T {
  return _cloneDeep(value)
}

/**
 * Flatten an array of arrays.
 *
 * ```
 * flattenDeep([0, [1]])
 * // => [0, 1]
 * ```
 */
export function flatten<T>(array: (T | T[])[]): T[] {
  return array.reduce((acc: T[], arr) => acc.concat(arr), []) as T[]
}

/**
 * Group the items of an array with a grouping function.
 *
 * ```
 * groupBy([6.1, 4.2, 6.3], n => Math.round(n))
 * // => { '4': [4.2], '6': [6.1, 6.2] }
 * ```
 */
export function groupBy<T>(array: T[], group?: (t: T) => string | number): { [x: string]: T[] } {
  return array.reduce((acc, item) => {
    const key = group(item)
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(item)
    return acc
  }, {})
}

/**
 * Compare two values deeply.
 *
 * ```
 * {} === {}
 * // => false
 * deepEqual({}, {})
 * // => true
 * ```
 */
export function deepEqual<T>(a: T, b: T): boolean {
  return _deepEqual(a, b)
}

/**
 * Sort an array in ascending order based on the selected property.
 *
 * ```
 * users = [{ name: 'b' }, { name: 'a' }]
 * sortBy(users, u => u.name)
 * // => [{ name: 'a' }, { name: 'b' }]
 * ```
 *
 * Note: Use `sortByDesc` if you need to sort in descending order.
 */
export function sortByAsc<T>(array: T[], select: (t: T) => string | number | Date): T[] {
  return array.sort((a, b) => sort(select(a), select(b)))
}

/**
 * Sort an array in descending order based on the selected property.
 *
 * ```
 * users = [{ age: 10 }, { age: 20 }]
 * sortByDesc(users, u => u.age)
 * // => [{ age: 20 }, { age: 10 }]
 * ```
 *
 * Note: Use `sortByAsc` if you need to sort in ascending order.
 */
export function sortByDesc<T>(array: T[], select: (t: T) => string | number | Date): T[] {
  return array.sort((a, b) => sort(select(b), select(a)))
}

function sort<T extends string | number | Date>(a: T, b: T): number {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b
  } else if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime()
  } else if (a === null || a === undefined) {
    return 1
  } else if (b === null || b === undefined) {
    return -1
  } else {
    throw new Error(`Sorting is not supported for type '${Object.prototype.toString.call(a)}'`)
  }
}

/**
 * Checks if value is null or undefined
 * @param value
 */
export function isNill(value): boolean {
  return value === null || value === undefined
}

/**
 * Checks if value is a valid Float
 * @param value
 */
export function isFloat(value): boolean {
  return floatRegex.test(String(value))
}

/**
 * Tries to safely parse a JSON string, it returns null if it fails
 * @param {string} value
 * @param {string} errorMessage
 * @returns {any}
 */
export function parseJSON(value: string, errorMessage?: string): any {
  try {
    return JSON.parse(value)
  } catch (error) {
    return null
  }
}

/**
 * Tries to safely stringify an object, it returns null if it fails
 * @param {Record<string, any>} value
 * @returns {string}
 */
export function stringifyJSON(value: Record<string, any>): string {
  try {
    return JSON.stringify(value)
  } catch (error) {
    return null
  }
}

/**
 * Converts the HTML entities in to their corresponding characters.
 * @param value
 */
export function unescape(value: string): string {
  const htmlUnescapes = {
    '&amp;': '&',
    '&nbsp;': ' ',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': `'`,
    '&#160;': ' '
  }

  const reEscapedHtml = /&(?:amp|lt|nbsp|gt|quot|#(0+)?(39|160));/g

  if (!value || !RegExp(reEscapedHtml.source).test(value)) {
    return value
  }

  return value.replace(reEscapedHtml, entity => htmlUnescapes[entity])
}
