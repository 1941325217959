import { EnvironmentInjector, Injectable, inject, runInInjectionContext } from '@angular/core'
import { Feature, FeatureFlagsToken } from '@awork/_shared/feature-flags/feature-flag.features'

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(private injector: EnvironmentInjector) {}

  private featureFlags = inject(FeatureFlagsToken)

  /**
   * Returns true if every criterion is true for the given feature
   * @param {Feature} featureName
   * @returns {boolean}
   */
  isFeatureEnabled(featureName: Feature): boolean {
    return runInInjectionContext(this.injector, () => !!this.featureFlags[featureName]?.every(criterion => criterion()))
  }
}
