import { NgClass, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, input, viewChild } from '@angular/core'
import { Color, MainColor } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'

@Component({
  selector: 'aw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  progress = input(0) // %
  size = input<Size.s | Size.m>(Size.m)
  overflow = input<boolean>(false)
  color = input<MainColor>(Color.Blue)
  lightColor = input<boolean>(false)
  label = input<string>('')

  progressBar = viewChild<ElementRef>('progressBar')

  overflowWidth = computed(() => this.getOverflowWidth())
  progressWidth = computed(() => this.getProgressWidth())

  protected readonly sizes = Size

  /**
   * Sets the width of the progress bar
   * @withSignal {progressBar}
   */
  private getProgressWidth(): number {
    return this.progress() / 100
  }

  /**
   * Sets the overflow width of the progress bar
   */
  private getOverflowWidth(): number {
    if (this.progress() <= 100) {
      return 0
    }
    const overflow = this.progress() - 100
    return Math.round((overflow / this.progress()) * 100)
  }
}
