import { environment } from '@awork/environments/environment'

export default function sendBeacon(url: string, body: string | Blob): void {
  // TODO: In the future, we could save failed Beacons in `localStorage`,
  // retry sending them when the app is opened again, and try reporting
  // the failure appropriately.

  // Beacons are used for tracking and metrics only. These should
  // be disabled locally.
  if (environment !== 'local') {
    if ('sendBeacon' in navigator) {
      try {
        const queued = navigator.sendBeacon(url, body)
        if (!queued) {
          console.warn(`Failed to send beacon to URL '${url}'`)
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.name === 'SecurityError' && error.message.includes('http://crbug.com/490015')) {
            fallback(url, body)
          } else {
            throw error
          }
        }
      }
    } else {
      fallback(url, body)
    }
  } else {
    console.warn(`Skipped sending beacon to URL '${url}'`)
  }
}

function fallback(url: string, body: string | Blob): void {
  const xhr = new XMLHttpRequest()
  xhr.onerror = () => console.warn(`Failed to send beacon to URL '${url}'`)
  xhr.open('POST', url, true)
  if (body instanceof Blob && body.type) {
    xhr.setRequestHeader('Content-Type', body.type)
  }
  xhr.send(body)
}
