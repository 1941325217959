import { Color, DeprecatedColor } from '@awork/_shared/types/color'

const colorMap: { [key in DeprecatedColor]: Color } = {
  [DeprecatedColor.Coral]: Color.Orange,
  [DeprecatedColor.Arctic]: Color.Cyan,
  [DeprecatedColor.Azure]: Color.Blue,
  [DeprecatedColor.Violet]: Color.Indigo,
  [DeprecatedColor.Pink]: Color.Red
}

/**
 * Maps a deprecated color to a supported one
 * @param {string} - the color to be mapped
 * @returns {Color} - the mapped color
 */
export function getMappedColor(color: Color | DeprecatedColor): Color {
  return colorMap[color] ?? color
}
