import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { Injectable } from '@angular/core'
import { TransientEvents } from '@awork/_shared/models/transient-events.model'
import { UserSetting } from '@awork/_shared/models/user-setting.model'
import {
  ITaskViewListsSetting,
  IProjectTaskListSetting,
  IProjectViewSetting,
  IViewPersistenceSetting,
  IEntityTimesSettings
} from '@awork/framework/models/view-setting.model'
import { GroupByOptions } from '@awork/_shared/models/group-by-options.model'
import { SortingDirection } from '@awork/_shared/models/sort-by-option.model'
import { DEFAULT_FRAMEWORK_VIEW_SETTINGS } from '@awork/framework/state/settings.query'

export enum SortByOptions {
  taskName = 'taskName',
  createdOn = 'createdOn',
  startOn = 'startOn',
  dueOn = 'dueOn',
  typeOfWork = 'typeOfWork',
  effort = 'effort',
  taskStatus = 'taskStatus',
  user = 'user',
  default = 'default'
}

export interface IGroupingSorting {
  groupBy?: GroupByOptions
  sortBy?: SortByOptions
  orderBy?: SortingDirection
}

export interface NotificationUserSetting {
  event: TransientEvents
  shownOn: Date
}

export interface SettingsState {
  notifications: NotificationUserSetting[]
  viewSettings: IViewPersistenceSetting
  projectTaskListSettings: IProjectTaskListSetting[]
  taskViewListsSettings: ITaskViewListsSetting[]
  taskListWidgetSettings: ITaskViewListsSetting[]
  projectSettings: IProjectViewSetting[]
  entityTimesSettings: IEntityTimesSettings[]
  hideTimers: boolean
  sortingGrouping: IGroupingSorting
}

export function createInitialState(): SettingsState {
  return {
    notifications: [],
    viewSettings: {},
    projectTaskListSettings: [],
    taskViewListsSettings: [],
    taskListWidgetSettings: [],
    projectSettings: [],
    entityTimesSettings: [],
    hideTimers: false,
    sortingGrouping: {}
  }
}

@Injectable({ providedIn: 'root' })
export class SettingsStore extends SignalStore<SettingsState> {
  constructor() {
    super({ name: 'settings', initialState: createInitialState() })
  }

  /**
   * Sets localStorage key 'awDarkMode' used to show the App's framework loader with the proper theme
   * @param {Readonly<SettingsState>} prevState
   * @param {Readonly<Partial<SettingsState>>} nextState
   * @returns {Partial<SettingsState>}
   */
  preUpdateState(
    prevState: Readonly<SettingsState>,
    nextState: Readonly<Partial<SettingsState>>
  ): Partial<SettingsState> {
    localStorage.setItem('awDarkMode', nextState.viewSettings?.framework?.darkMode || 'auto')
    localStorage.setItem('awMenu', nextState.viewSettings?.framework?.menuPinned ? 'true' : 'false')
    localStorage.setItem(
      'awMenuWidth',
      nextState.viewSettings?.framework?.menuWidth?.toString() || DEFAULT_FRAMEWORK_VIEW_SETTINGS.menuWidth.toString()
    )
    return nextState
  }

  /**
   * Updates the notification settings in the store
   * @param notificationSettings
   */
  updateNotificationSettings(notificationSettings: NotificationUserSetting[]): void {
    this.update({
      notifications: notificationSettings
    })
  }

  /**
   * Updates the view setting in the store
   * @param {IViewPersistenceSetting} viewSetting
   */
  updateViewSettings(viewSetting: IViewPersistenceSetting): void {
    this.applyMigrations(viewSetting)

    this.update({ viewSettings: viewSetting })
  }

  /**
   * Updates the view setting in the store
   * @param viewSetting
   */
  updateProjectTaskListViewSettings(viewSetting: IProjectTaskListSetting): void {
    this.update(state => {
      const projectTaskListSettings = state.projectTaskListSettings
        ? state.projectTaskListSettings.filter(setting => setting.projectId !== viewSetting.projectId)
        : []

      if (viewSetting.projectId) {
        projectTaskListSettings.push(viewSetting)
      }

      return { projectTaskListSettings }
    })
  }

  /**
   * Updates the view setting in the store
   * @param viewSetting
   */
  updateProjectViewSettings(viewSetting: IProjectViewSetting): void {
    this.update(state => {
      const projectSettings = state.projectSettings
        ? state.projectSettings.filter(setting => setting.projectId !== viewSetting.projectId)
        : []

      if (viewSetting.projectId) {
        projectSettings.push(viewSetting)
      }

      return { projectSettings }
    })
  }

  /**
   * Updates a specific view setting in the store
   * @param viewSettings
   * @param targetedSetting
   */
  updateViewSetting(viewSettings: UserSetting, targetedSetting: string): void {
    this.update(state => {
      const matchedSetting: UserSetting[] = state.viewSettings[targetedSetting]
      if (matchedSetting && matchedSetting.length > 0) {
        const setting = matchedSetting.find(match => match.key === viewSettings.key)
        if (setting) {
          setting.value = viewSettings.value
        } else {
          matchedSetting.push(viewSettings)
        }
      } else {
        state.viewSettings[targetedSetting] = [viewSettings]
      }
      return state
    })
  }

  /**
   * Updates the task view setting in the store
   * @param {ITaskViewListsSetting} viewSetting
   */
  updateTaskViewListsSettings(viewSetting: ITaskViewListsSetting): void {
    this.update(state => {
      const taskViewListsSettings = state.taskViewListsSettings
        ? state.taskViewListsSettings.filter(setting => setting.id !== viewSetting.id)
        : []

      if (viewSetting.id) {
        taskViewListsSettings.push(viewSetting)
      }

      return { taskViewListsSettings }
    })
  }

  /**
   * Updates the dashboard task lists setting in the store
   * @param {ITaskViewListsSetting} viewSetting
   */
  updateTaskListWidgetSettings(viewSetting: ITaskViewListsSetting): void {
    this.update(state => {
      const taskListWidgetSettings = state.taskListWidgetSettings
        ? state.taskListWidgetSettings.filter(setting => setting.id !== viewSetting.id)
        : []

      if (viewSetting.id) {
        taskListWidgetSettings.push(viewSetting)
      }

      return { taskListWidgetSettings }
    })
  }

  /**
   * Update Grouping and Sorting
   * @param options
   */
  updateGroupingAndSortingOptions(options: IGroupingSorting): void {
    this.update({ sortingGrouping: options })
  }

  /**
   * Updates the entity times settings of a specific entity
   * @param {IEntityTimesSettings} viewSettings
   */
  updateEntityTimesSettings(viewSettings: IEntityTimesSettings): void {
    this.update(state => {
      const entityTimesSettings = state.entityTimesSettings
        ? state.entityTimesSettings.filter(
            setting => setting.entityId !== viewSettings.entityId && setting.entityType !== viewSettings.entityType
          )
        : []

      entityTimesSettings.push(viewSettings)

      return { entityTimesSettings }
    })
  }

  /**
   * Applies migrations to the view settings.
   * The migrations are applied to keep the view settings up to date with the current version of the app
   * @param {IEntityTimesSettings} viewSetting
   */
  private applyMigrations(viewSetting: IViewPersistenceSetting): void {
    this.applyListColumnsMigration(viewSetting)
  }

  /**
   * Applies the migration for the list columns.
   * The migration takes the selected columns from the large list and moves them to the 'default' key
   * to use them as the default selected columns.
   * Remove this migration 6 months after the release of the custom fields.
   * @param {IViewPersistenceSetting} viewSetting
   */
  private applyListColumnsMigration(viewSetting: IViewPersistenceSetting): void {
    if (!viewSetting?.listColumns?.largeList?.projectTask) {
      return
    }

    if (viewSetting.listColumns.largeList.projectTask instanceof Array) {
      viewSetting.listColumns.largeList.projectTask = {
        default: viewSetting.listColumns.largeList.projectTask
      }
    }
  }
}
