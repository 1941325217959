export default function isTest(): boolean {
  try {
    return (
      location.hostname === 'localhost' &&
      (process?.env?.JEST_WORKER_ID !== undefined || process?.env?.NODE_ENV === 'test')
    )
  } catch (_) {
    return false
  }
}
