export interface IProjectMemberBase {
  id: string
  userId: string
  firstName: string
  lastName: string
  updatedOn?: Date
}

export class ProjectMemberBase implements IProjectMemberBase {
  id: string
  userId: string
  firstName: string
  lastName: string
  updatedOn?: Date

  constructor(projectMember: Partial<ProjectMemberBase>) {
    Object.assign(this, projectMember)

    if (!this.userId) {
      this.userId = this.id
    }
  }
  // Not consistent with User model (firstName || lastName], but better for ordering and dangerous to refactor to fullName
  get name(): string {
    return `${this.firstName} ${this.lastName}`.trim()
  }
}
