import { Injectable } from '@angular/core'
import { Workspace } from '@awork/features/workspace/models/workspace.model'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'

@Injectable({ providedIn: 'root' })
export class WorkspaceStore extends EntitySignalStore<Workspace> {
  constructor() {
    super({ name: 'workspace', entityConstructor: workspace => new Workspace(workspace) })
  }

  preUpdateEntity(prevEntity: Readonly<Workspace>, nextEntity: Readonly<Workspace>): Workspace {
    const settings = nextEntity.settings || prevEntity.settings || {}

    return { ...nextEntity, settings }
  }

  preAddEntity(newEntity: Readonly<Workspace>): Workspace {
    const settings = newEntity.settings || {}

    const entityWithSettings = { ...newEntity, settings }

    return { ...entityWithSettings }
  }

  /**
   * Sets the current workspace
   * @param {string} id
   */
  setCurrentWorkspace(id: string): void {
    this.setActive(id)
  }
}
