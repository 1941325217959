import { patchState as patchState$1, signalStoreFeature, withState, withComputed, withMethods, withHooks, getState } from '@ngrx/signals';
import { signal, effect, inject, PLATFORM_ID, computed, isSignal, untracked } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';
import { setAllEntities, addEntity, updateEntity, removeEntity } from '@ngrx/signals/entities';
const storeRegistry = signal({});
let currentActionNames = new Set();
let synchronizationInitialized = false;
function initSynchronization() {
  effect(() => {
    if (!connection) {
      return;
    }
    const stores = storeRegistry();
    const rootState = {};
    for (const name in stores) {
      const store = stores[name];
      rootState[name] = store();
    }
    const names = Array.from(currentActionNames);
    const type = names.length ? names.join(', ') : 'Store Update';
    currentActionNames = new Set();
    connection.send({
      type
    }, rootState);
  });
}
function getValueFromSymbol(obj, symbol) {
  if (typeof obj === 'object' && obj && symbol in obj) {
    return obj[symbol];
  }
}
function getStoreSignal(store) {
  const [signalStateKey] = Object.getOwnPropertySymbols(store);
  if (!signalStateKey) {
    throw new Error('Cannot find State Signal');
  }
  return getValueFromSymbol(store, signalStateKey);
}
let connection;
/**
 * required for testing. is not exported during build
 */
function reset() {
  connection = undefined;
  synchronizationInitialized = false;
  storeRegistry.set({});
}
/**
 * @param name store's name as it should appear in the DevTools
 */
function withDevtools(name) {
  return store => {
    const isServer = isPlatformServer(inject(PLATFORM_ID));
    if (isServer) {
      return store;
    }
    const extensions = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (!extensions) {
      return store;
    }
    if (!connection) {
      connection = extensions.connect({
        name: 'NgRx Signal Store'
      });
    }
    const storeSignal = getStoreSignal(store);
    storeRegistry.update(value => ({
      ...value,
      [name]: storeSignal
    }));
    if (!synchronizationInitialized) {
      initSynchronization();
      synchronizationInitialized = true;
    }
    return store;
  };
}
/**
 * @deprecated Has been renamed to `updateState`
 */
const patchState = (state, action, ...rest) => {
  updateState(state, action, ...rest);
};
/**
 * Wrapper of `patchState` for DevTools integration. Next to updating the state,
 * it also sends the action to the DevTools.
 * @param stateSource state of Signal Store
 * @param action name of action how it will show in DevTools
 * @param updaters updater functions or objects
 */
function updateState(stateSource, action, ...updaters) {
  currentActionNames.add(action);
  return patchState$1(stateSource, ...updaters);
}
function assertActionFnSpecs(obj) {
  if (!obj || typeof obj !== 'object') {
    throw new Error('%o is not an Action Specification');
  }
}
function payload() {
  return {};
}
const noPayload = {};
function createActionFns(actionFnSpecs, reducerRegistry, effectsRegistry, state) {
  const actionFns = {};
  for (const type in actionFnSpecs) {
    const actionFn = payload => {
      const fullPayload = {
        ...payload,
        type
      };
      const reducer = reducerRegistry[type];
      if (reducer) {
        reducer(state, fullPayload);
      }
      const effectSubjects = effectsRegistry[type];
      if (effectSubjects?.length) {
        for (const effectSubject of effectSubjects) {
          effectSubject.next(fullPayload);
        }
      }
      return fullPayload;
    };
    actionFn.type = type.toString();
    actionFns[type] = actionFn;
  }
  return actionFns;
}
function createPublicAndAllActionsFns(actionFnSpecs, reducerRegistry, effectsRegistry, state) {
  if ('public' in actionFnSpecs || 'private' in actionFnSpecs) {
    const privates = actionFnSpecs['private'] || {};
    const publics = actionFnSpecs['public'] || {};
    assertActionFnSpecs(privates);
    assertActionFnSpecs(publics);
    const privateActionFns = createActionFns(privates, reducerRegistry, effectsRegistry, state);
    const publicActionFns = createActionFns(publics, reducerRegistry, effectsRegistry, state);
    return {
      all: {
        ...privateActionFns,
        ...publicActionFns
      },
      publics: publicActionFns
    };
  }
  const actionFns = createActionFns(actionFnSpecs, reducerRegistry, effectsRegistry, state);
  return {
    all: actionFns,
    publics: actionFns
  };
}
function fillReducerRegistry(reducer, actionFns, reducerRegistry) {
  function on(action, reducerFn) {
    reducerRegistry[action.type] = reducerFn;
  }
  reducer(actionFns, on);
  return reducerRegistry;
}
function fillEffects(effects, actionFns, effectsRegistry = {}) {
  function create(action) {
    const subject = new Subject();
    if (!(action.type in effectsRegistry)) {
      effectsRegistry[action.type] = [];
    }
    effectsRegistry[action.type].push(subject);
    return subject.asObservable();
  }
  const effectObservables = effects(actionFns, create);
  return Object.values(effectObservables);
}
function startSubscriptions(observables) {
  return observables.map(observable => observable.subscribe());
}
function processRedux(actionFnSpecs, reducer, effects, store) {
  const reducerRegistry = {};
  const effectsRegistry = {};
  const actionsMap = createPublicAndAllActionsFns(actionFnSpecs, reducerRegistry, effectsRegistry, store);
  const actionFns = actionsMap.all;
  const publicActionsFns = actionsMap.publics;
  fillReducerRegistry(reducer, actionFns, reducerRegistry);
  const effectObservables = fillEffects(effects, actionFns, effectsRegistry);
  const subscriptions = startSubscriptions(effectObservables);
  return {
    methods: publicActionsFns,
    subscriptions: subscriptions
  };
}
/**
 * @param redux redux
 *
 * properties do not start with `with` since they are not extension functions on their own.
 *
 * no dependency to NgRx
 *
 * actions are passed to reducer and effects, but it is also possible to use other actions.
 * effects provide forAction and do not return anything. that is important because effects should stay inaccessible
 */
function withRedux(redux) {
  return store => {
    const {
      methods
    } = processRedux(redux.actions, redux.reducer, redux.effects, store);
    return {
      ...store,
      methods
    };
  };
}
function getCallStateKeys(config) {
  const prop = config?.collection;
  return {
    callStateKey: prop ? `${config.collection}CallState` : 'callState',
    loadingKey: prop ? `${config.collection}Loading` : 'loading',
    loadedKey: prop ? `${config.collection}Loaded` : 'loaded',
    errorKey: prop ? `${config.collection}Error` : 'error'
  };
}
function withCallState(config) {
  const {
    callStateKey,
    errorKey,
    loadedKey,
    loadingKey
  } = getCallStateKeys(config);
  return signalStoreFeature(withState({
    [callStateKey]: 'init'
  }), withComputed(state => {
    const callState = state[callStateKey];
    return {
      [loadingKey]: computed(() => callState() === 'loading'),
      [loadedKey]: computed(() => callState() === 'loaded'),
      [errorKey]: computed(() => {
        const v = callState();
        return typeof v === 'object' ? v.error : null;
      })
    };
  }));
}
function setLoading(prop) {
  if (prop) {
    return {
      [`${prop}CallState`]: 'loading'
    };
  }
  return {
    callState: 'loading'
  };
}
function setLoaded(prop) {
  if (prop) {
    return {
      [`${prop}CallState`]: 'loaded'
    };
  } else {
    return {
      callState: 'loaded'
    };
  }
}
function setError(error, prop) {
  let errorMessage;
  if (!error) {
    errorMessage = '';
  } else if (typeof error === 'object' && 'message' in error) {
    errorMessage = String(error.message);
  } else {
    errorMessage = String(error);
  }
  if (prop) {
    return {
      [`${prop}CallState`]: {
        error: errorMessage
      }
    };
  } else {
    return {
      callState: {
        error: errorMessage
      }
    };
  }
}
function capitalize(str) {
  return str ? str[0].toUpperCase() + str.substring(1) : str;
}
function getDataServiceKeys(options) {
  const filterKey = options.collection ? `${options.collection}Filter` : 'filter';
  const selectedIdsKey = options.collection ? `selected${capitalize(options.collection)}Ids` : 'selectedIds';
  const selectedEntitiesKey = options.collection ? `selected${capitalize(options.collection)}Entities` : 'selectedEntities';
  const updateFilterKey = options.collection ? `update${capitalize(options.collection)}Filter` : 'updateFilter';
  const updateSelectedKey = options.collection ? `updateSelected${capitalize(options.collection)}Entities` : 'updateSelected';
  const loadKey = options.collection ? `load${capitalize(options.collection)}Entities` : 'load';
  const currentKey = options.collection ? `current${capitalize(options.collection)}` : 'current';
  const loadByIdKey = options.collection ? `load${capitalize(options.collection)}ById` : 'loadById';
  const setCurrentKey = options.collection ? `setCurrent${capitalize(options.collection)}` : 'setCurrent';
  const createKey = options.collection ? `create${capitalize(options.collection)}` : 'create';
  const updateKey = options.collection ? `update${capitalize(options.collection)}` : 'update';
  const updateAllKey = options.collection ? `updateAll${capitalize(options.collection)}` : 'updateAll';
  const deleteKey = options.collection ? `delete${capitalize(options.collection)}` : 'delete';
  // TODO: Take these from @ngrx/signals/entities, when they are exported
  const entitiesKey = options.collection ? `${options.collection}Entities` : 'entities';
  const entityMapKey = options.collection ? `${options.collection}EntityMap` : 'entityMap';
  const idsKey = options.collection ? `${options.collection}Ids` : 'ids';
  return {
    filterKey,
    selectedIdsKey,
    selectedEntitiesKey,
    updateFilterKey,
    updateSelectedKey,
    loadKey,
    entitiesKey,
    entityMapKey,
    idsKey,
    currentKey,
    loadByIdKey,
    setCurrentKey,
    createKey,
    updateKey,
    updateAllKey,
    deleteKey
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withDataService(options) {
  const {
    dataServiceType,
    filter,
    collection: prefix
  } = options;
  const {
    entitiesKey,
    filterKey,
    loadKey,
    selectedEntitiesKey,
    selectedIdsKey,
    updateFilterKey,
    updateSelectedKey,
    currentKey,
    createKey,
    updateKey,
    updateAllKey,
    deleteKey,
    loadByIdKey,
    setCurrentKey
  } = getDataServiceKeys(options);
  const {
    callStateKey
  } = getCallStateKeys({
    collection: prefix
  });
  return signalStoreFeature(withState(() => ({
    [filterKey]: filter,
    [selectedIdsKey]: {},
    [currentKey]: undefined
  })), withComputed(store => {
    const entities = store[entitiesKey];
    const selectedIds = store[selectedIdsKey];
    return {
      [selectedEntitiesKey]: computed(() => entities().filter(e => selectedIds()[e.id]))
    };
  }), withMethods(store => {
    const dataService = inject(dataServiceType);
    return {
      [updateFilterKey]: filter => {
        patchState$1(store, {
          [filterKey]: filter
        });
      },
      [updateSelectedKey]: (id, selected) => {
        patchState$1(store, state => ({
          [selectedIdsKey]: {
            ...state[selectedIdsKey],
            [id]: selected
          }
        }));
      },
      [loadKey]: async () => {
        const filter = store[filterKey];
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          const result = await dataService.load(filter());
          patchState$1(store, prefix ? setAllEntities(result, {
            collection: prefix
          }) : setAllEntities(result));
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      },
      [loadByIdKey]: async id => {
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          const current = await dataService.loadById(id);
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
          patchState$1(store, {
            [currentKey]: current
          });
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      },
      [setCurrentKey]: current => {
        patchState$1(store, {
          [currentKey]: current
        });
      },
      [createKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          const created = await dataService.create(entity);
          patchState$1(store, {
            [currentKey]: created
          });
          patchState$1(store, prefix ? addEntity(created, {
            collection: prefix
          }) : addEntity(created));
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      },
      [updateKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          const updated = await dataService.update(entity);
          patchState$1(store, {
            [currentKey]: updated
          });
          const updateArg = {
            id: updated.id,
            changes: updated
          };
          const updater = collection => updateEntity(updateArg, {
            collection
          });
          patchState$1(store, prefix ? updater(prefix) : updateEntity(updateArg));
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      },
      [updateAllKey]: async entities => {
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          const result = await dataService.updateAll(entities);
          patchState$1(store, prefix ? setAllEntities(result, {
            collection: prefix
          }) : setAllEntities(result));
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      },
      [deleteKey]: async entity => {
        patchState$1(store, {
          [currentKey]: entity
        });
        store[callStateKey] && patchState$1(store, setLoading(prefix));
        try {
          await dataService.delete(entity);
          patchState$1(store, {
            [currentKey]: undefined
          });
          patchState$1(store, prefix ? removeEntity(entity.id, {
            collection: prefix
          }) : removeEntity(entity.id));
          store[callStateKey] && patchState$1(store, setLoaded(prefix));
        } catch (e) {
          store[callStateKey] && patchState$1(store, setError(e, prefix));
          throw e;
        }
      }
    };
  }));
}
const defaultOptions = {
  maxStackSize: 100,
  keys: [],
  skip: 0
};
function getUndoRedoKeys(collections) {
  if (collections) {
    return collections.flatMap(c => [`${c}EntityMap`, `${c}Ids`, `selected${capitalize(c)}Ids`, `${c}Filter`]);
  }
  return ['entityMap', 'ids', 'selectedIds', 'filter'];
}
function withUndoRedo(options) {
  let previous = null;
  let skipOnce = false;
  const normalized = {
    ...defaultOptions,
    ...options
  };
  //
  // Design Decision: This feature has its own
  // internal state.
  //
  const undoStack = [];
  const redoStack = [];
  const canUndo = signal(false);
  const canRedo = signal(false);
  const updateInternal = () => {
    canUndo.set(undoStack.length !== 0);
    canRedo.set(redoStack.length !== 0);
  };
  const keys = [...getUndoRedoKeys(normalized.collections), ...normalized.keys];
  return signalStoreFeature(withComputed(() => ({
    canUndo: canUndo.asReadonly(),
    canRedo: canRedo.asReadonly()
  })), withMethods(store => ({
    undo() {
      const item = undoStack.pop();
      if (item && previous) {
        redoStack.push(previous);
      }
      if (item) {
        skipOnce = true;
        patchState$1(store, item);
        previous = item;
      }
      updateInternal();
    },
    redo() {
      const item = redoStack.pop();
      if (item && previous) {
        undoStack.push(previous);
      }
      if (item) {
        skipOnce = true;
        patchState$1(store, item);
        previous = item;
      }
      updateInternal();
    }
  })), withHooks({
    onInit(store) {
      effect(() => {
        const cand = keys.reduce((acc, key) => {
          const s = store[key];
          if (s && isSignal(s)) {
            return {
              ...acc,
              [key]: s()
            };
          }
          return acc;
        }, {});
        if (normalized.skip > 0) {
          normalized.skip--;
          return;
        }
        if (skipOnce) {
          skipOnce = false;
          return;
        }
        //
        // Deep Comparison to prevent duplicated entries
        // on the stack. This can e.g. happen after an undo
        // if the component sends back the undone filter
        // to the store.
        //
        if (JSON.stringify(cand) === JSON.stringify(previous)) {
          return;
        }
        // Clear redoStack after recorded action
        redoStack.splice(0);
        if (previous) {
          undoStack.push(previous);
        }
        if (redoStack.length > normalized.maxStackSize) {
          undoStack.unshift();
        }
        previous = cand;
        // Don't propogate current reactive context
        untracked(() => updateInternal());
      });
    }
  }));
}
const NOOP = () => {};
const StorageSyncStub = {
  clearStorage: NOOP,
  readFromStorage: NOOP,
  writeToStorage: NOOP
};
function withStorageSync(configOrKey) {
  const {
    key,
    autoSync = true,
    select = state => state,
    parse = JSON.parse,
    stringify = JSON.stringify,
    storage: storageFactory = () => localStorage
  } = typeof configOrKey === 'string' ? {
    key: configOrKey
  } : configOrKey;
  return signalStoreFeature(withMethods((store, platformId = inject(PLATFORM_ID)) => {
    if (isPlatformServer(platformId)) {
      console.warn(`'withStorageSync' provides non-functional implementation due to server-side execution`);
      return StorageSyncStub;
    }
    const storage = storageFactory();
    return {
      /**
       * Removes the item stored in storage.
       */
      clearStorage() {
        storage.removeItem(key);
      },
      /**
       * Reads item from storage and patches the state.
       */
      readFromStorage() {
        const stateString = storage.getItem(key);
        if (stateString) {
          patchState$1(store, parse(stateString));
        }
      },
      /**
       * Writes selected portion to storage.
       */
      writeToStorage() {
        const slicedState = select(getState(store));
        storage.setItem(key, stringify(slicedState));
      }
    };
  }), withHooks({
    onInit(store, platformId = inject(PLATFORM_ID)) {
      if (isPlatformServer(platformId)) {
        return;
      }
      if (autoSync) {
        store.readFromStorage();
        effect(() => {
          store.writeToStorage();
        });
      }
    }
  }));
}

/** With pagination comes in two flavors the first one is local pagination or in memory pagination. For example we have 2000 items which we want
 * to display in a table and the response payload is small enough to be stored in the memory. But we can not display all 2000 items at once
 * so we need to paginate the data. The second flavor is server side pagination where the response payload is too large to be stored in the memory
 * and we need to fetch the data from the server in chunks. In the second case we 'could' also cache the data in the memory but that could lead to
 * other problems like memory leaks and stale data. So we will not cache the data in the memory in the second case.
 * This feature implements the local pagination.
 */
function withPagination(options) {
  const {
    pageKey,
    pageSizeKey,
    entitiesKey,
    selectedPageEntitiesKey,
    totalCountKey,
    pageCountKey,
    pageNavigationArrayMaxKey,
    pageNavigationArrayKey,
    setPageSizeKey,
    nextPageKey,
    previousPageKey,
    lastPageKey,
    firstPageKey,
    gotoPageKey,
    hasNextPageKey,
    hasPreviousPageKey
  } = createPaginationKeys(options);
  return signalStoreFeature(withState({
    [pageKey]: 0,
    [pageSizeKey]: 10,
    [pageNavigationArrayMaxKey]: 7
  }), withComputed(store => {
    const entities = store[entitiesKey];
    const page = store[pageKey];
    const pageSize = store[pageSizeKey];
    const pageNavigationArrayMax = store[pageNavigationArrayMaxKey];
    return {
      // The derived enitites which are displayed on the current page
      [selectedPageEntitiesKey]: computed(() => {
        const pageSizeValue = pageSize();
        const pageValue = page();
        return entities().slice(pageValue * pageSizeValue, (pageValue + 1) * pageSizeValue);
      }),
      [totalCountKey]: computed(() => entities().length),
      [pageCountKey]: computed(() => {
        const totalCountValue = entities().length;
        const pageSizeValue = pageSize();
        if (totalCountValue === 0) {
          return 0;
        }
        return Math.ceil(totalCountValue / pageSizeValue);
      }),
      [pageNavigationArrayKey]: computed(() => createPageArray(page(), pageSize(), entities().length, pageNavigationArrayMax())),
      [hasNextPageKey]: computed(() => {
        return page() < pageSize();
      }),
      [hasPreviousPageKey]: computed(() => {
        return page() > 1;
      })
    };
  }), withMethods(store => {
    return {
      [setPageSizeKey]: size => {
        patchState$1(store, setPageSize(size, options));
      },
      [nextPageKey]: () => {
        patchState$1(store, nextPage(options));
      },
      [previousPageKey]: () => {
        patchState$1(store, previousPage(options));
      },
      [lastPageKey]: () => {
        const lastPage = store[pageCountKey]();
        if (lastPage === 0) return;
        patchState$1(store, gotoPage(lastPage - 1, options));
      },
      [firstPageKey]: () => {
        patchState$1(store, firstPage());
      },
      [gotoPageKey]: page => {
        patchState$1(store, gotoPage(page, options));
      }
    };
  }));
}
function gotoPage(page, options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: page
  };
}
function setPageSize(pageSize, options) {
  const {
    pageSizeKey
  } = createPaginationKeys(options);
  return {
    [pageSizeKey]: pageSize
  };
}
function nextPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: currentPage => currentPage + 1
  };
}
function previousPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: currentPage => Math.max(currentPage - 1, 1)
  };
}
function firstPage(options) {
  const {
    pageKey
  } = createPaginationKeys(options);
  return {
    [pageKey]: 1
  };
}
function setMaxPageNavigationArrayItems(maxPageNavigationArrayItems, options) {
  const {
    pageNavigationArrayMaxKey
  } = createPaginationKeys(options);
  return {
    [pageNavigationArrayMaxKey]: maxPageNavigationArrayItems
  };
}
function createPaginationKeys(options) {
  const entitiesKey = options?.collection ? `${options.collection}Entities` : 'entities';
  const selectedPageEntitiesKey = options?.collection ? `selectedPage${capitalize(options?.collection)}Entities` : 'selectedPageEntities';
  const pageKey = options?.collection ? `${options.collection}CurrentPage` : 'currentPage';
  const pageSizeKey = options?.collection ? `${options.collection}PageSize` : 'pageSize';
  const totalCountKey = options?.collection ? `${options.collection}TotalCount` : 'totalCount';
  const pageCountKey = options?.collection ? `${options.collection}PageCount` : 'pageCount';
  const pageNavigationArrayMaxKey = options?.collection ? `${options.collection}PageNavigationArrayMax` : 'pageNavigationArrayMax';
  const pageNavigationArrayKey = options?.collection ? `${options.collection}PageNavigationArray` : 'pageNavigationArray';
  const setPageSizeKey = options?.collection ? `set${capitalize(options.collection)}PageSize` : 'setPageSize';
  const nextPageKey = options?.collection ? `next${capitalize(options.collection)}Page` : 'nextPage';
  const previousPageKey = options?.collection ? `previous${capitalize(options.collection)}Page` : 'previousPage';
  const lastPageKey = options?.collection ? `last${capitalize(options.collection)}Page` : 'lastPage';
  const firstPageKey = options?.collection ? `first${capitalize(options.collection)}Page` : 'firstPage';
  const gotoPageKey = options?.collection ? `goto${capitalize(options.collection)}Page` : 'gotoPage';
  const hasNextPageKey = options?.collection ? `hasNext${capitalize(options.collection)}Page` : 'hasNextPage';
  const hasPreviousPageKey = options?.collection ? `hasPrevious${capitalize(options.collection)}Page` : 'hasPreviousPage';
  return {
    pageKey,
    pageSizeKey,
    entitiesKey,
    selectedPageEntitiesKey,
    totalCountKey,
    pageCountKey,
    pageNavigationArrayKey,
    pageNavigationArrayMaxKey,
    setPageSizeKey,
    nextPageKey,
    previousPageKey,
    lastPageKey,
    firstPageKey,
    gotoPageKey,
    hasNextPageKey,
    hasPreviousPageKey
  };
}
function createPageArray(currentPage, itemsPerPage, totalItems, paginationRange) {
  // Convert paginationRange to number in case it's a string
  paginationRange = +paginationRange;
  // Calculate total number of pages
  const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
  const halfWay = Math.ceil(paginationRange / 2);
  const isStart = currentPage <= halfWay;
  const isEnd = totalPages - halfWay < currentPage;
  const isMiddle = !isStart && !isEnd;
  const ellipsesNeeded = paginationRange < totalPages;
  const pages = [];
  for (let i = 1; i <= totalPages && i <= paginationRange; i++) {
    let pageNumber = i;
    if (i === paginationRange) {
      pageNumber = totalPages;
    } else if (ellipsesNeeded) {
      if (isEnd) {
        pageNumber = totalPages - paginationRange + i;
      } else if (isMiddle) {
        pageNumber = currentPage - halfWay + i;
      }
    }
    const openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
    const closingEllipsesNeeded = i === paginationRange - 1 && (isMiddle || isStart);
    const label = ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded) ? '...' : pageNumber;
    pages.push({
      label,
      value: pageNumber
    });
  }
  return pages;
}

/**
 * Generated bundle index. Do not edit.
 */

export { capitalize, createPageArray, firstPage, getCallStateKeys, getDataServiceKeys, getUndoRedoKeys, gotoPage, nextPage, noPayload, patchState, payload, previousPage, setError, setLoaded, setLoading, setMaxPageNavigationArrayItems, setPageSize, updateState, withCallState, withDataService, withDevtools, withPagination, withRedux, withStorageSync, withUndoRedo };
