import { emailRegex } from '@awork/_shared/static-data/regular-expressions'

export type ISOLanguage = 'en-GB' | 'de-DE'

interface ExternalAccount {
  provider: string
  externalUserId: string
}

export interface AccountState {
  email: string
  refreshAt: Date
  refreshToken?: string
  accessToken?: string
  isImpersonated?: boolean
  id?: string
  firstName?: string
  lastName?: string
  language?: ISOLanguage
  timezone?: string
  passwordIsAutoGenerated?: boolean
  googleUserId?: string
  appleUserId?: string
  externalAccounts?: ExternalAccount[]
}

export class Account {
  email: string
  isImpersonated: boolean
  refreshAt: Date
  id?: string
  firstName?: string
  lastName?: string
  language?: ISOLanguage
  timezone?: string
  passwordIsAutoGenerated: boolean
  googleUserId?: string
  appleUserId?: string
  emailConfirmed?: boolean

  constructor(email: string, expiresIn?: number, refreshAt?: Date) {
    this.email = email
    this.setIsImpersonated()

    if (expiresIn) {
      this.setRefreshAt(expiresIn)
    } else {
      this.refreshAt = refreshAt
    }
  }

  /**
   * Creates an Account object from a AccountState object
   * @param {AccountState} state
   * @return {Account & AccountState}
   */
  static fromState(state: AccountState): Account {
    const account = new Account(state.email)
    return Object.assign(account, state)
  }

  /**
   * Sets if the current account is an impersonation
   */
  setIsImpersonated() {
    if (this.email && this.email.includes('-AW-')) {
      const emails = this.email.split('-AW-')

      // Check if there are 2 valid emails
      if (emails.length === 2 && emailRegex.test(emails[0]) && emailRegex.test(emails[1])) {
        this.isImpersonated = true
      }
    }
  }

  /**
   * Set the time when the session should be refreshed
   * @param {number} expiresIn - Time in seconds
   */
  setRefreshAt(expiresIn: number) {
    const now = new Date()
    this.refreshAt = new Date(now.getTime() + expiresIn * 1000)
  }

  get formattedTimezone(): string {
    return this.timezone ? this.timezone.replace(/_/g, ' ') : 'Europe/Berlin'
  }

  get formattedLanguage(): string {
    switch (this.language) {
      case 'en-GB':
        return 'English'
      case 'de-DE':
        return 'Deutsch'
    }
  }
}
