import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter
} from '@angular/core'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { NgClass } from '@angular/common'
import { WithGlobals } from '../../../classes/with-globals'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['../base-icon-button.scss', './remove-button.component.scss'],
  standalone: true,
  imports: [NgClass, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveButtonComponent extends WithGlobals implements OnChanges, OnInit {
  @Input() size: ButtonSize = Size.m
  @Input() clear: boolean
  @Input() tooltip: string = q.translations.common.clear

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>()

  onClickHandler(event: MouseEvent) {
    event.stopPropagation()

    if (this.disabled) {
      return
    }

    this.onClick.emit(event)
  }

  removeButtonClasses: string[]

  ngOnInit() {
    this.removeButtonClasses = this.getClasses()
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.size && !changes.size.isFirstChange()) || (changes.clear && !changes.clear.isFirstChange())) {
      this.removeButtonClasses = this.getClasses()
    }
  }

  /**
   * Initializes all classes used to style the remove button.
   * @returns {string[]} The classes used to style the remove button.
   */
  getClasses(): string[] {
    return [`btn-icon--${this.size}`, ...(this.clear ? ['btn-icon--clear'] : [])]
  }
}
