import { NgClass, UpperCasePipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core'
import { Color, MainColor } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'
import { AvatarPixelSizes, AvatarSize } from '../../../types/avatar-sizes'

@Component({
  selector: 'aw-avatar-initials',
  templateUrl: './avatar-initials.component.html',
  styleUrls: ['./avatar-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, UpperCasePipe]
})
export class AvatarInitialsComponent implements OnChanges, OnInit {
  @Input({ required: true }) initials: string
  @Input() size: AvatarSize = Size.m

  color: MainColor | Color.Steel = Color.Blue
  classes: string[] = []
  avatarPixelSizes = AvatarPixelSizes

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this.setClasses()
    }
  }

  /**
   * Sets size and color classes
   */
  private setClasses(): void {
    this.setColorClass()
    this.classes = [`avt-initials--${this.size}`, `avt-initials--${this.color}`]
    this.cdr.markForCheck()
  }

  /**
   * Sets a random background color based on the initials
   */
  private setColorClass(): void {
    const colors: MainColor[] = [Color.Red, Color.Blue, Color.Yellow, Color.Purple, Color.Green]
    if (!this.initials) {
      this.initials = ''
    }

    // With 2 initials, there are 26*26 possible combinations
    // We got 5 colors.
    // We use the first letters index to decide on one of the colors.
    // The second letter shifts this for some more randomness
    const getIndexInAlphabet = (character: string): number => {
      if (!character) {
        return -1
      }

      const alphabet = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
      ]
      return alphabet.indexOf(character.toLowerCase())
    }

    const firstLetter = this.initials.length > 0 ? this.initials[0] : 'a'
    const secondLetter = this.initials.length > 1 ? this.initials[1] : 'a'
    let colorIndex = Math.floor((5 * getIndexInAlphabet(firstLetter)) / 26)
    const colorShift = Math.floor((5 * getIndexInAlphabet(secondLetter)) / 26)

    const shiftDown = colorIndex - colorShift
    const shiftUp = colorIndex + colorShift
    const blueIndex = 1

    if (shiftUp < 4) {
      colorIndex = shiftUp
    } else if (shiftDown >= 0) {
      colorIndex = shiftDown
    } else {
      colorIndex = blueIndex
    }

    this.color = colors[colorIndex]
  }
}
