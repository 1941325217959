import { NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'

@Component({
  selector: 'aw-hotkey-info',
  standalone: true,
  imports: [NgIf],
  templateUrl: './hotkey-info.component.html',
  styleUrls: ['./hotkey-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotkeyInfoComponent {
  // DI dependencies
  private browserService = inject(BrowserService)

  // Inputs
  firstKey = input<string | number>('')
  secondKey = input<string | number>('')
  thirdKey = input<string | number>('')
  label = input<string>('')

  // Computed
  firstShownKey = computed(() => this.replaceIfPC(this.firstKey()))
  secondShownKey = computed(() => this.replaceIfPC(this.secondKey()))
  thirdShownKey = computed(() => this.replaceIfPC(this.thirdKey()))

  /**
   * Replaces the ⌘ key with ctrl for PC
   * @param {string | number} value
   * @returns {string | number}
   */
  private replaceIfPC(value: string | number): string | number {
    if (!value) {
      return ''
    }

    return !this.browserService.isAppleDevice ? value.toString().replace('⌘', 'ctrl') : value
  }
}
