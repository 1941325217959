<button
  #btn
  class="btn"
  [attr.type]="isSubmit ? 'submit' : 'button'"
  [ngClass]="buttonClasses"
  [style.min-width.px]="minWidth"
  (click)="clickEventHandler($event)"
>
  <ng-container [ngSwitch]="state">
    <ng-template *ngSwitchCase="'default'" [ngTemplateOutlet]="default" />
    <ng-template *ngSwitchCase="'loading'" [ngTemplateOutlet]="loadingState" />
    <ng-template *ngSwitchCase="'success'" [ngTemplateOutlet]="successState" />
  </ng-container>
</button>

<ng-template #default>
  <i *ngIf="leftIcon" class="aw-2-icn">{{ leftIcon }}</i>

  <span
    *ngIf="text"
    [class.aw-2-pl--s]="rightIcon"
    [class.aw-2-pr--s]="leftIcon"
  >
    {{ text }}
  </span>

  <i *ngIf="rightIcon" class="aw-2-icn">{{ rightIcon }}</i>
</ng-template>

<ng-template #loadingState>
  <i *ngIf="leftIcon" class="aw-2-icn">{{ leftIcon }}</i>

  <span *ngIf="text">{{ loadingText }}</span>

  <aw-dots-loader [size]="sizes.s" [color]="getLoaderColor()" />
</ng-template>

<ng-template #successState>
  <i class="aw-2-icn" @scaleBig>check</i>
</ng-template>
