import { Observable, EMPTY } from 'rxjs'

import isTest from '@awork/_shared/functions/is-test'
import { ResizeObserverStub } from '@awork/core/mocks_stubs/globals/resize-observer'

type CreateObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver) => void

/**
 * Returns true if the ResizeObserver API is available in the browser
 * Returns false if it is running in a test
 * @returns {boolean}
 */
export function isResizeObserverAvailable(): boolean {
  if (!window.ResizeObserver || isTest()) {
    return false
  }

  return true
}

/**
 * Create a ResizeObserver or a stub if it is running in a test
 * @param {CreateObserverCallback} observerCallback
 * @returns {ResizeObserver}
 */
export function createResizeObserver(observerCallback: CreateObserverCallback): ResizeObserver {
  if (isTest()) {
    return new ResizeObserverStub()
  }

  return new ResizeObserver(observerCallback)
}

/**
 * Observable that emits when the element is resized.
 * Returns EMPTY observable if element doesn't exist.
 * Internally uses the ResizeObserver API.
 * @param {HTMLElement | null | undefined} element
 * @returns {Observable<ResizeObserverEntry>}
 */
export function resizeObservable(element?: HTMLElement | null): Observable<ResizeObserverEntry> {
  if (!element || !(element instanceof HTMLElement)) {
    return EMPTY
  }

  return new Observable(subscriber => {
    const resizeObserver = createResizeObserver(entries => {
      entries.forEach(entry => subscriber.next(entry))
    })

    resizeObserver.observe(element)

    return function unsubscribe(): void {
      resizeObserver.unobserve(element)
      resizeObserver.disconnect()
    }
  })
}
