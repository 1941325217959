<label *ngIf="label" class="form-label">{{ label }}</label>

<input
  #formInput
  awAutoFocus
  type="text"
  class="form-input"
  [autoFocus]="autoFocus"
  [forceFocus]="forceFocus"
  [formControl]="control"
  [attr.placeholder]="placeholder || null"
  [attr.readonly]="disabled || readonly ? '' : null"
  [attr.maxlength]="maxLength || null"
  [value]="value"
  [class.with-left-icon]="leftIcon"
  [class.with-right-icon]="rightIcon || rightButton"
  [class.readonly]="readonly && !disabled"
  (keyup)="keyup.emit($event)"
  (keydown)="keydown.emit($event)"
  (blur)="blur.emit($event)"
  (focus)="focus.emit($event)"
  (change)="change.emit($event)"
/>

<i *ngIf="leftIcon" class="aw-2-icn icon left-icon" [class.disabled]="disabled">{{ leftIcon }}</i>
<i *ngIf="rightIcon" class="aw-2-icn icon right-icon" [class.disabled]="disabled">{{ rightIcon }}</i>

<aw-icon-button
  *ngIf="rightButton"
  [icon]="rightButton"
  [tooltip]="rightButtonTooltip"
  (click)="onButtonClick.emit($event)"
/>

<aw-info-box *ngIf="errorText" [@slideOpenNgIf] [text]="errorText" [color]="colors.Red" />

<aw-info-box *ngIf="infoText" [@slideOpenNgIf] [text]="infoText" [color]="colors.Steel" />
