import { UserBase } from '@awork/features/user/models/user.model.base'
import { ProjectBase } from '@awork/features/project/models/project.model.base'

export interface ParentTask {
  id: string
  name: string
}

export interface ITaskBase {
  id: string
  parentId?: string
  parent?: { name: string; id: string }
  parentTask?: ParentTask
  name: string
  assignees?: UserBase[]
  projectId?: string
  project?: ProjectBase
  startOn?: Date
  plannedDuration?: number
  dueOn?: Date
  laneOrder?: number
  autoLaneOrder?: number
  createdFromTaskId?: string
  createdOn?: Date
  updatedOn?: Date
}

export class TaskBase implements ITaskBase {
  id: string
  parentId?: string
  parent?: { name: string; id: string }
  parentTask?: ParentTask
  name: string
  assignees?: UserBase[]
  projectId?: string
  project?: ProjectBase
  startOn?: Date
  plannedDuration?: number
  dueOn?: Date
  laneOrder?: number
  autoLaneOrder?: number
  createdFromTaskId?: string
  createdOn?: Date
  updatedOn?: Date

  constructor(task: Partial<ITaskBase>) {
    Object.assign(this, task)

    if (this.startOn) {
      this.startOn = new Date(this.startOn)
    }
    if (this.dueOn) {
      this.dueOn = new Date(this.dueOn)
    }
    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }
    if (this.updatedOn) {
      this.updatedOn = new Date(this.updatedOn)
    }
  }

  get endOn(): Date {
    return this.dueOn ? new Date(this.dueOn) : undefined
  }

  set endOn(value: Date) {
    this.dueOn = value
  }
}
