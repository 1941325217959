function getItem(itemName: string): string {
  try {
    return localStorage?.getItem(itemName)
  } catch (error) {
    return null
  }
}

function setItem(itemName: string, item: Object): void {
  try {
    localStorage?.setItem(itemName, JSON.stringify(item))
  } catch (_) {}
}

function removeItem(itemName: string): void {
  try {
    localStorage?.removeItem(itemName)
  } catch (_) {}
}

function removeItems(itemNames: string[]): void {
  try {
    itemNames.forEach(itemName => localStorage?.removeItem(itemName))
  } catch (_) {}
}

export default {
  getItem,
  setItem,
  removeItem,
  removeItems
}
