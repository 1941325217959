import { Injectable } from '@angular/core'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'

export interface EventState {
  usesStartStop: boolean // True, if the current user uses start stop behavior
  firstLogin: string | Date // First login of the current user for the current day
}

export type EventType = keyof EventState

function createInitialState(): EventState {
  return {
    usesStartStop: null,
    firstLogin: null
  }
}

@Injectable({ providedIn: 'root' })
export class EventStore extends SignalStore<EventState> {
  constructor() {
    super({
      name: 'event',
      initialState: createInitialState()
    })
  }

  /**
   * Updates the corresponding event's value
   * @param eventName
   * @param value
   */
  updateEvent<eventName extends keyof EventState>(eventName: EventType, value: EventState[eventName]): void {
    this.update({ [eventName]: value })
  }
}
