import type { CustomFieldDefinitionLink } from '@awork/features/project/models/custom-field-definition-link.model'
import { CustomFieldTypeValue } from '@awork/features/project/models/custom-field-type.model'
import type { ICustomFieldSelectionOption } from '@awork/features/project/models/custom-field-definition.model'
import type { DropdownOption } from '@awork/_shared/models/select-option.model'
import { getDateFormat } from '@awork/_shared/functions/get-language'
import type { User } from '@awork/features/user/models/user.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { FieldSize } from '@awork/_shared/static-data/field-sizes'
import replace from '@awork/_shared/functions/replace'

interface ICustomField {
  customFieldDefinitionId: string
  customFieldDefinition: CustomFieldDefinitionLink
  textValue?: string
  numberValue?: number
  dateValue?: Date
  booleanValue?: boolean
  userIdValue?: string
  selectionOptionIdValue?: string
}

export type CustomFieldValue = string | number | Date | boolean

export class CustomField implements ICustomField {
  customFieldDefinitionId: string
  customFieldDefinition: CustomFieldDefinitionLink
  textValue?: string
  numberValue?: number
  dateValue?: Date
  booleanValue?: boolean
  userIdValue?: string
  selectionOptionIdValue?: string
  hasError: boolean
  errorText: string

  constructor(customField: ICustomField) {
    Object.assign(this, customField)
  }

  /**
   * Sets the custom field value according to the type
   * @param {CustomFieldValue} value
   */
  set value(value: CustomFieldValue) {
    switch (this.customFieldDefinition.type) {
      case CustomFieldTypeValue.Text:
      case CustomFieldTypeValue.Link:
        this.textValue = value as string
        break
      case CustomFieldTypeValue.Number:
        this.numberValue = value as number
        break
      case CustomFieldTypeValue.Date:
      case CustomFieldTypeValue.Datetime:
        this.dateValue = value as Date
        break
      case CustomFieldTypeValue.Boolean:
        this.booleanValue = value as boolean
        break
      case CustomFieldTypeValue.User:
        this.userIdValue = value as string
        break
      case CustomFieldTypeValue.Select:
      case CustomFieldTypeValue.ColoredSelect:
        this.selectionOptionIdValue = value as string
        break
    }
  }

  /**
   * Gets the custom field value according to the type
   * @returns {CustomFieldValue}
   */
  get value(): CustomFieldValue {
    switch (this.customFieldDefinition.type) {
      case CustomFieldTypeValue.Text:
      case CustomFieldTypeValue.Link:
        return this.textValue
      case CustomFieldTypeValue.Number:
        return this.numberValue
      case CustomFieldTypeValue.Date:
      case CustomFieldTypeValue.Datetime:
        return this.dateValue
      case CustomFieldTypeValue.Boolean:
        return this.booleanValue
      case CustomFieldTypeValue.User:
        return this.userIdValue
      case CustomFieldTypeValue.Select:
      case CustomFieldTypeValue.ColoredSelect:
        return this.selectionOptionIdValue
    }
  }

  /**
   * Gets the date format used by the date pipe.
   * @withSignal customField
   * @returns {string}
   */
  get dateFormat(): string {
    if (
      this.customFieldDefinition.type !== CustomFieldTypeValue.Date &&
      this.customFieldDefinition.type !== CustomFieldTypeValue.Datetime
    ) {
      return undefined
    }

    const showTime = this.customFieldDefinition.type === CustomFieldTypeValue.Datetime

    return getDateFormat(showTime)
  }

  /**
   * Gets the selection value of the custom field.
   * @returns {ICustomFieldSelectionOption}
   */
  get selectionValue(): ICustomFieldSelectionOption {
    return this.customFieldDefinition.selectionOptions.find(
      selectionValue => selectionValue.id === this.selectionOptionIdValue
    )
  }

  /**
   * Gets the custom field definition selection values as dropdown options
   * @returns {DropdownOption[]}
   */
  get selectOptions(): DropdownOption[] {
    return this.customFieldDefinition.selectOptions
  }

  /**
   * Gets the selected user of the custom field from the store
   * @returns {User}
   */
  get selectedUser(): User {
    if (this.userIdValue && UserQuery.instance) {
      const user = UserQuery.instance.queryUser(this.userIdValue)
      return user()
    }

    return null
  }

  /**
   * Validates the value of the custom field according to its type.
   * Sets the error text if the value is invalid.
   * @param {CustomFieldValue} value
   * @returns {boolean}
   */
  validateValue(value: CustomFieldValue): boolean {
    let isValid: boolean
    this.errorText = ''

    if (!value) {
      isValid = true
    } else {
      switch (this.customFieldDefinition.type) {
        case CustomFieldTypeValue.Text:
        case CustomFieldTypeValue.Link:
          isValid = value?.toString().length < FieldSize.UserProvidedIndexedValue
          this.errorText = isValid
            ? ''
            : replace(q.translations.CustomFieldModel.validation.maxLength, {
                length: FieldSize.UserProvidedIndexedValue
              })
          break
        case CustomFieldTypeValue.Number:
          isValid = this.isValidNumber(value)
          break
        default:
          isValid = true
      }
    }

    this.hasError = !isValid
    return isValid
  }

  /**
   * Checks if the value is a valid number
   * @param {string | number | boolean | Date} value
   * @returns {boolean}
   */
  isValidNumber(value: string | number | boolean | Date): boolean {
    const number = Number(value)
    return !isNaN(number) && isFinite(number)
  }
}
