import { cloneDeep } from '@awork/_shared/functions/lodash'
import { generateGUID } from '@awork/_shared/functions/guid-generator'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { GenericEntity } from '@awork/core/state/signal-store/types'

type OperationType = 'add' | 'update' | 'remove'

interface Operation<Entity> {
  type: OperationType
  entities: Entity[]
}

export class EntitySignalStoreHistory<Entity extends GenericEntity = { id: string }> {
  private operationMap: Map<string, Operation<Entity>> = new Map<string, Operation<Entity>>()

  constructor(private store: EntitySignalStore<Entity>) {}

  /**
   * Sets the current operation with the type and the required entities
   * Add: the new entities
   * Update: the previous entities
   * Remove: the removed entities
   * @param {Operation} operation
   * @returns {string} operationId
   */
  startOperation(operation: Operation<Entity>): string {
    const operationId = generateGUID()
    const { type, entities } = operation

    this.operationMap.set(operationId, { type, entities: cloneDeep(entities) })

    return operationId
  }

  /**
   * Undo the last store operation
   * @param {string} operationId
   */
  undo(operationId: string): void {
    if (!this.operationMap.has(operationId)) {
      throw new Error('No operation to undo')
    }

    const { type, entities } = this.operationMap.get(operationId)

    switch (type) {
      case 'add':
        this.store.remove(entities.map(e => e.id))
        break
      case 'update':
        const entitiesMap = new Map<string, Entity>()
        entities.forEach(entity => entitiesMap.set(entity.id, entity))

        this.store.update(
          entities.map(e => e.id),
          (e: Entity) => entitiesMap.get(e.id)
        )
        break
      case 'remove':
        this.store.add(entities)
        break
    }

    this.operationMap.delete(operationId)
  }

  /**
   * Ends the current operation by deleting it from the operation map
   * @param {string} operationId
   */
  endOperation(operationId: string): void {
    if (this.operationMap.has(operationId)) {
      this.operationMap.delete(operationId)
    }
  }
}
