/**
 * Builds the the marketing properties event data from the aw_url cookie or url
 * @param {
 *  url: string,
 *  awUrlCookie: string
 * }
 * @returns {Record<string, string>}
 */
export function buildMarketingPropertiesFromAwUrlCookieOrUrl({
  url,
  awUrlCookie
}: Partial<{
  url: string
  awUrlCookie: string
}>): Record<string, string> {
  const source = awUrlCookie ?? url

  if (!source) {
    return {}
  }

  try {
    const sourceSearchParams = new URLSearchParams(new URL(source).search)

    const result = {}
    for (const [key, value] of sourceSearchParams.entries()) {
      result[key] = value
    }

    return result
  } catch (_) {
    return {}
  }
}
