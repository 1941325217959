import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false

  private mediaQueryList: MediaQueryList

  constructor(
    private router: Router,
    private location: Location,
    private browserService: BrowserService
  ) {}

  printDocument(module: string, documentName: string, documentData: string[]) {
    this.isPrinting = true
    this.router.navigate(
      [
        `./${module}`,
        {
          outlets: {
            print: ['print', documentName, documentData.join()]
          }
        }
      ],
      { skipLocationChange: false }
    )
  }

  onDataReady() {
    const isSafari = this.browserService.getBrowserName() === 'safari'

    setTimeout(() => {
      if (isSafari) {
        // window.print() is not working properly in safary. execCommand is deprecated, this will be updated in the future
        document.execCommand('print', false, null)
      } else {
        window.print()
      }
    })

    // Prevent Safari printing issue that caused by printing confirmation modal

    this.mediaQueryList = window.matchMedia('print')

    const mediaQuerListCallBack = () => this.onPrintClose()

    // Electron does not trigger the window.frames.onfocus event
    // so we need to listen to the window.onafterprint event
    window.onafterprint = () => this.onPrintClose()

    // Triggers when the print dialog is closed
    window.frames.onfocus = () => {
      if (!window.location.href.includes('print')) {
        return
      }

      mediaQuerListCallBack()
    }
  }

  /**
   * Handles the print dialog close event
   * @param {MediaQueryList} mediaQueryList
   */
  private onPrintClose(): void {
    if (this.mediaQueryList && !this.mediaQueryList.matches) {
      this.isPrinting = false
      this.location.back()
      // Reset mediaQueryList to prevent any un-needed redirection
      this.mediaQueryList = null
    }
  }
}
