import { FormControl, UntypedFormGroup } from '@angular/forms'
import { LogService } from '@awork/_shared/services/log-service/log.service'

/**
 * Validates the form, if there is some errors, set the error messages per field
 * @param {FormGroup} form - The form to be validated
 * @param {Object} formErrors - The object with the actual error messages
 * @param {Object} validationMessages - The validation error messages available
 * @returns {boolean} - true if there is some error, false otherwise.
 */
export function showFormErrors(
  form: UntypedFormGroup | FormControl,
  formErrors: Object,
  validationMessages: Object
): boolean {
  let hasError = false

  for (const field of Object.keys(formErrors)) {
    // clear previous error message (if any)
    formErrors[field] = ''
    const control = form instanceof UntypedFormGroup ? form.get(field) : form

    if (control && !control.valid && control.errors) {
      hasError = true
      const messages = validationMessages[field]

      if (!messages && LogService.instance) {
        LogService.instance.logMessage('Missing validation message for field: ' + field)
      }

      // Shows only the first error
      formErrors[field] += messages?.[Object.keys(control.errors)[0]] || q.translations.common.invalidFormField
    }
  }

  return hasError
}
