import { UserSetting } from '@awork/_shared/models/user-setting.model'
import { IPlannerSetting } from '@awork/features/planner/models/planner-settings-model'
import {
  BoardGroupByOptions,
  DocsGroupByOptions,
  DocsSortByOptions,
  GroupByOptions,
  TimeTrackingGroupingOptions,
  TimeTrackingTimeFrameOptions
} from '@awork/_shared/models/group-by-options.model'
import { SortByOption, SortingDirection } from '@awork/_shared/models/sort-by-option.model'
import {
  AggregationOptions,
  TimelineFilters,
  ValidStandardTimeReportEntityType,
  ViewModesTimeTrackingGrouping
} from '@awork/features/time-tracking/models/types'

export type DurationFormatType = 'decimal_days' | 'auto' | 'hours_minutes'

export type ListEntity = 'company' | 'project' | 'projectTask' | 'user' | 'myTasks' | 'planner'

interface ColumnSelection {
  company: string[]
  project: string[]
  projectTask: { [projectId: string]: string[]; default?: string[] }
  user: string[]
  myTasks: string[]
  planner: string[]
}

export interface ListColumns {
  smallList: ColumnSelection
  largeList: ColumnSelection
}

/**
 * Model for the global view persistence user setting
 */
export interface IViewPersistenceSetting {
  userList?: UserSetting[]
  companyList?: UserSetting[]
  projectPage?: UserSetting[]
  taskView?: UserSetting[]
  projectDocs?: IProjectDocsSetting
  taskTimeline?: ITaskTimelineSetting
  privateTaskList?: IProjectTaskListSetting
  timeTrackingList?: UserSetting[]
  myTimeTracking?: UserSetting[]
  timeTrackingStandardTimeReport?: UserSetting[]
  dynamicDurationFormat?: DurationFormatType
  framework?: IFrameworkViewSetting
  listColumns?: Partial<ListColumns>
  planner?: Partial<IPlannerSetting>
  projectTemplates?: IProjectTemplateViewSetting
  splitView?: UserSetting[]
  connectSettingsPage?: UserSetting[]
  templateProjectModal?: ITemplateProjectModalSetting
}

/**
 * View settings with type of UserSetting[]
 */
export const VIEW_PERSISTENCE_USER_SETTINGS: readonly (keyof IViewPersistenceSetting)[] = [
  'userList',
  'companyList',
  'projectPage',
  'taskView',
  'timeTrackingList',
  'timeTrackingStandardTimeReport',
  'myTimeTracking',
  'splitView',
  'connectSettingsPage'
]

/**
 * View settings with primitive value
 */
export const VIEW_PERSISTENCE_VALUE_SETTINGS: readonly (keyof IViewPersistenceSetting)[] = ['dynamicDurationFormat']

export type DarkModeSetting = 'on' | 'auto' | 'off'

export interface IProjectDocsSetting {
  sortByOption: DocsSortByOptions
  sortByOptionDir: SortingDirection
  groupByOption: DocsGroupByOptions
  activeTab: 'gallery' | 'list'
}

export enum TemplateModalTab {
  ALL = 'all',
  CUSTOM = 'custom',
  BY_AWORK = 'byAwork'
}

export interface ITemplateProjectModalSetting {
  tab: TemplateModalTab
}

/**
 * Model for the framework view persistance
 */
export interface IFrameworkViewSetting {
  menuPinned?: boolean
  menuWidth?: number
  hideTimers?: boolean
  darkMode?: DarkModeSetting
  companiesWithLogoSuggested?: string[]
  menuItemsExpandedState?: { group: string; expanded: boolean }[]
  recentlyVisitedProjects?: string[]
  pinnedProjects?: string[]
  keepNotificationOnNavigation?: boolean
  showSuggestedTimes?: boolean
}

/**
 * Model for the time tracking view setting
 */
export interface ITimeTrackingViewSetting {
  selectedFilter?: string
  viewMode?: {
    value?: string
    grouping?: string
  }
  datespan?: {
    value?: TimelineFilters
    aggregation?: AggregationOptions
  }
}

/**
 * Model for the task view setting
 */
export interface IProjectTaskListSetting {
  projectId?: string
  showDone?: boolean
  showOldDoneTasks?: boolean
  lists?: { id: string; collapsed: boolean }[]
  users?: { id: string; collapsed: boolean }[]
  collapsedTasks?: CollapsedEntities
  viewMode?: string
  zoomLevel?: number
  sidebarExpanded?: boolean
  sortingOption?: SortByOption
  groupOption?: GroupByOptions
  boardGroupOption?: BoardGroupByOptions
  isListCompactWidth?: boolean
}

export interface IProjectViewSetting {
  projectId?: string
  viewMode?: string
}

export interface IProjectTemplateViewSetting {
  favoriteTemplates?: string[]
}

/**
 * Minimal object for storing entity ids as object keys with a isCollapsed boolean value.
 * This allows us to store a shorter string on the api and to easily convert to
 * a map using Object.fromEntries()
 */
export type CollapsedEntities = { [entityId: string]: boolean }

/**
 * Model for task view lists Settings
 */
export interface ITaskViewListsSetting {
  id?: string
  groupOption?: GroupByOptions
  sortingOption?: SortByOption
  lastViewed?: boolean
  showDone?: boolean
  showScheduledTasks?: boolean
  showPlannedTasks?: boolean
  collapsedTasks?: CollapsedEntities
  isListCompactWidth?: boolean
}

/**
 * Model for the task timeline setting
 */
export interface ITaskTimelineSetting {
  sidebarExpanded?: boolean
  sidebarWidth?: number
  moveDependentTasks?: boolean
}

/**
 * Model for the entity times setting, used for standard time report
 */
export interface IEntityTimesSettings {
  entityId: string
  entityType: ValidStandardTimeReportEntityType
  viewMode?: ViewModesTimeTrackingGrouping
  showUnplannedTasks?: boolean
  timeframe?: TimeTrackingTimeFrameOptions
  groupBy?: TimeTrackingGroupingOptions
  showDoneTasks?: boolean
}
