import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import { AppState, AppStore } from '@awork/core/state/app.store'
import { Injectable } from '@angular/core'
import { Observable, filter, take, map } from 'rxjs'
import { ISOLanguage } from '@awork/_shared/models/account.model'

@Injectable({ providedIn: 'root' })
export class AppQuery extends SignalQuery<AppState> {
  constructor(protected store: AppStore) {
    super(store)
  }

  /**
   * Selects the trace ids including the success and error calls' trace ids
   * @param {number} limit
   * @returns {Observable<{ traceIds: string[]; traceIdsError: string[] }>}
   */
  selectTraces(limit = 10): Observable<{ traceIds: string[]; traceIdsError: string[] }> {
    return this.select().pipe(
      map(appState => {
        return {
          traceIds: appState.traceIds.slice(limit * -1),
          traceIdsError: appState.traceIdsError.slice(limit * -1)
        }
      })
    )
  }

  /**
   * Gets the trace ids including the success and error calls' trace ids
   * @param {number} limit
   * @returns {{ traceIds: string[]; traceIdsError: string[] }}
   */
  getTraces(limit = 10): { traceIds: string[]; traceIdsError: string[] } {
    return {
      traceIds: this.getValue().traceIds.slice(limit * -1),
      traceIdsError: this.getValue().traceIdsError.slice(limit * -1)
    }
  }

  /**
   * Selects the last version check date
   * @returns {Observable<Date>}
   */
  selectVersionCheck(): Observable<Date> {
    return this.select('versionCheck').pipe(
      map(versionCheck => new Date(versionCheck)),
      take(1)
    )
  }

  /**
   * Selects the login flag
   * @returns {Observable<boolean>}
   */
  selectIsLoggedIn(): Observable<boolean> {
    return this.select('isLoggedIn')
  }

  /**
   * Gets the login flag
   * @returns {boolean}
   */
  getIsLoggedIn(): boolean {
    return this.getValue().isLoggedIn
  }

  /**
   * Selects the isSwitchingWorkspace flag
   * @returns {Observable<boolean>}
   */
  selectIsSwitchingWorkspace(): Observable<boolean> {
    return this.select('isSwitchingWorkspace')
  }

  /**
   * Selects the language
   * @returns {Observable<ISOLanguage>}
   */
  selectLanguage(): Observable<ISOLanguage> {
    return this.select('language').pipe(take(1))
  }

  /**
   * Selects the referral code
   * @returns {Observable<string>}
   */
  selectReferralCode(): Observable<string> {
    return this.select('referralCode').pipe(take(1))
  }

  /**
   * Selects the last sync date
   * @returns {Date}
   */
  selectLastSyncDate(): Date {
    return this.getValue().lastSyncDate
  }

  /**
   * Selects the registration id for push notifications
   * @returns {string}
   */
  selectPushNotificationRegistrationId(): string {
    return this.getValue().pushNotificationRegistrationId
  }

  /**
   * Selects the isInMSTeams flag
   * @returns {Observable<boolean>}
   */
  selectIsInMSTeams(): Observable<boolean> {
    return this.select('isMSTeams').pipe(
      filter(isMSTeams => isMSTeams),
      take(1)
    )
  }

  /**
   * Gets the isInMSTeams flag
   * @returns {boolean}
   */
  getIsInMSTeams(): boolean {
    return this.getValue().isMSTeams
  }

  /**
   * Gets the debug flag
   * @returns {boolean}
   */
  getDebug(): boolean {
    return this.getValue().debug
  }

  /**
   * Gets the last time the cache were wiped
   * @returns {Date}
   */
  getCacheWiped(): Date {
    return this.getValue().cacheWiped
  }

  /**
   * Gets the last rendered route
   * @returns {string}
   */
  getLastRoute(): string {
    return this.getValue().lastRoute
  }

  /**
   * Gets the flag used to show to the user the API error caused by AdBlockers
   * @returns {boolean}
   */
  getAdBlockerErrorOccurred(): boolean {
    return this.getValue().adBlockerErrorOccurred
  }

  /**
   * Selects the flag used to determine if the app is checking the version
   * @returns {Observable<boolean>}
   */
  selectCheckingVersion(): Observable<boolean> {
    return this.select('checkingVersion')
  }

  /**
   * Gets the flag used to determine if the app should open links in the desktop app
   * @returns {boolean}
   */
  getOpenLinksInDesktopApp(): boolean {
    return this.getValue().openLinksInDesktopApp
  }
}
