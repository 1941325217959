import { Criterion } from './types'

/**
 * Returns true if the criterion is false
 * @param {Criterion} criterion
 * @returns {boolean}
 */
export const isNotCriterion =
  (criterion: Criterion): Criterion =>
  () =>
    !criterion()

/**
 * Returns true if any criterion is true
 * @param {Criterion[]} criteria
 * @returns {boolean}
 */
export const isAnyCriterionTrue =
  (criteria: Criterion[]): Criterion =>
  () =>
    criteria.some(criterion => criterion())

/**
 * Returns true if every criterion is true
 * @param {Criterion[]} criteria
 * @returns {boolean}
 */
export const isEveryCriterionTrue =
  (criteria: Criterion[]): Criterion =>
  () =>
    criteria.every(criterion => criterion())
