import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MainSize, Size } from '@awork/_shared/types/size'
import { Color } from '@awork/_shared/types/color'
import { NgClass } from '@angular/common'

@Component({
  selector: 'aw-dots-loader',
  templateUrl: './dots-loader.component.html',
  styleUrls: ['./dots-loader.component.scss'],
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotsLoaderComponent {
  @Input() color: Color = Color.White
  @Input() size: MainSize = Size.s

  colorClass: string
}
