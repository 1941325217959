import { User } from '@awork/features/user/models/user.model'
import { IProjectMemberBase, ProjectMemberBase } from '@awork/features/project/models/project-member.model.base'

interface IProjectMember {
  fullName?: string
  hasImage: boolean
  projectRoleId: string
  projectRoleName: string
  isResponsible: boolean
  isDeactivated: boolean
  position: string
  toBeAdded?: boolean
  isExternal: boolean
}

export class ProjectMember extends ProjectMemberBase implements IProjectMember {
  fullName?: string
  hasImage: boolean
  projectRoleId: string
  projectRoleName: string
  isResponsible: boolean
  isDeactivated: boolean
  updatedOn?: Date
  position: string
  toBeAdded?: boolean
  typeName = 'ProjectMember'
  isExternal: boolean

  constructor(member: Partial<IProjectMember & IProjectMemberBase>) {
    super(member)
  }

  static isProjectMember(projectMember: any): projectMember is ProjectMember {
    return (projectMember as ProjectMember)?.typeName === 'ProjectMember'
  }

  get user(): User {
    return new User(this, 'ProjectMember.user')
  }

  get image(): string {
    return this.user.image
  }

  profileImage(width = 500, height = 500): string {
    return this.user.profileImage(width, height)
  }
}
