import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { Color, IconButtonColor } from '@awork/_shared/types/color'
import { NgClass } from '@angular/common'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['../base-icon-button.scss', './icon-button.component.scss'],
  standalone: true,
  imports: [NgClass, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent implements OnChanges, OnInit {
  @Input({ required: true }) icon: string
  @Input() selectedIcon: string
  @Input() tooltip: string
  @Input() color: IconButtonColor = Color.Blue
  @Input() size: ButtonSize = Size.m

  @Input() inRow: boolean
  @Input() isSelected: boolean

  @HostBinding('class.disabled')
  @Input()
  disabled = false

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault()

    if (this.disabled) {
      event.stopPropagation()
    }
  }

  iconButtonClasses: string[]
  elementRef: ElementRef = inject(ElementRef)

  ngOnInit() {
    this.iconButtonClasses = this.getIconButtonClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.color && !changes.color.isFirstChange()) ||
      (changes.size && !changes.size.isFirstChange()) ||
      (changes.inRow && !changes.inRow.isFirstChange()) ||
      (changes.isSelected && !changes.isSelected.isFirstChange())
    ) {
      this.iconButtonClasses = this.getIconButtonClasses()
    }
  }

  /**
   * Initializes all classes used to style the icon button.
   * @returns {string[]} The classes used to style the icon button.
   */
  getIconButtonClasses(): string[] {
    return [
      `btn-icon--${this.color}`,
      `btn-icon--${this.size}`,
      ...(this.inRow ? ['btn-icon--row'] : []),
      ...(this.isSelected ? ['btn-icon--selected'] : [])
    ]
  }
}
