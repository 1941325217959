import { computed, Injectable, Signal } from '@angular/core'
import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import {
  FeatureFlag,
  FeatureFlagKey,
  FeatureFlagDefinition,
  FeatureFlagStore,
  FeatureFlagType
} from '@awork/_shared/state/feature-flag.store'

@Injectable({ providedIn: 'root' })
export class FeatureFlagQuery extends SignalQuery<FeatureFlagDefinition> {
  static instance: FeatureFlagQuery // Used to query the store in models (user, company, etc.)

  constructor(protected store: FeatureFlagStore) {
    super(store)
  }

  /**
   * Gets the value of a feature flag
   * @param {FeatureFlag} flag
   * @returns {Signal<FeatureFlagType<FeatureFlag>>}
   */
  queryFlag<K extends FeatureFlag>(flag: K): Signal<FeatureFlagType<K>> {
    return computed(() => {
      const flagKey = Object.keys(FeatureFlag).find(key => FeatureFlag[key] === flag)
      const featureFlag = this.query(flagKey as FeatureFlagKey) as Signal<FeatureFlagType<K>>
      return featureFlag()
    })
  }
}
