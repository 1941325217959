import { User } from '@awork/features/user/models/user.model'

export type UserRole = {
  isDeactivated: boolean
  userId: string
  roleId: string
}

export class Role {
  id: string
  name: string
  isAdminRole: boolean
  isGuestRole?: boolean
  isPreset: boolean
  users?: User[]

  constructor(role: any) {
    Object.assign(this, role)

    this.setTranslatedName()
  }

  /**
   * Sets translatable names to the roles
   * @private
   */
  private setTranslatedName(): void {
    if (this.isGuestRole && this.isPreset) {
      this.name = q.translations.RoleModel.guest
    }
  }
}
