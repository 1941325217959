function getWeekStartByRegion(regionCode, regionDayMap) {
  var code = regionDayMap[typeof regionCode === 'string' ? regionCode.toUpperCase() : regionCode];
  return typeof code === 'number' ? code : 1;
}
function getWeekStartByLocale(locale, langRegionMap, regionDayMap) {
  if (locale) {
    var data = locale.toLowerCase().split(/[-_]/);
    var language = data[0];
    var country;
    if (data[1] && data[1].length === 4) {
      language += "_" + data[1];
      country = data[2];
    } else {
      country = data[1];
    }
    if (!country) {
      country = langRegionMap[language];
    }
    if (country) {
      return getWeekStartByRegion(country.match(/^\d+$/) ? Number(country) : country, regionDayMap);
    }
  }
  return 1;
}
export { getWeekStartByRegion, getWeekStartByLocale };
