import { Injectable } from '@angular/core'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { Permissions, ProjectRolePermissions } from '@awork/features/workspace/models/permissions.model'

export interface PermissionsState {
  userId: string
  roleId: string
  isAdmin: boolean
  isBlocked: boolean
  permissions: Permissions[]
  projectRolesPermissions: ProjectRolePermissions[]
  deactivatedMenuItems: string[]
}

export function createInitialState(): PermissionsState {
  return {
    userId: null,
    roleId: null,
    isAdmin: false,
    isBlocked: false,
    permissions: [],
    projectRolesPermissions: [],
    deactivatedMenuItems: []
  }
}

@Injectable({ providedIn: 'root' })
export class PermissionsStore extends SignalStore<PermissionsState> {
  constructor() {
    super({
      initialState: createInitialState(),
      name: 'permissions'
    })
  }
}
