import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { NgIf } from '@angular/common'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { AutoFocusDirective } from '../../../directives/auto-focus/auto-focus.directive'
import { TextFieldAutocompleteDirective } from './directives/text-field-autocomplete.directive'
import { WithGlobals } from '../../../classes/with-globals'
import { Color } from '@awork/_shared/types/color'
import { IconButtonComponent } from '../../icon-buttons/icon-button/icon-button.component'
import { slideOpenNgIf } from '../../../animations/slide-open.animation'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'aw-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['../styles.scss', './text-field.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOpenNgIf],
  imports: [AutoFocusDirective, InfoBoxComponent, NgIf, ReactiveFormsModule, IconButtonComponent],
  hostDirectives: [
    {
      directive: TextFieldAutocompleteDirective,
      inputs: ['autocompleteOptions', 'preShowAutocompleteOptions', 'maxShowOptions', 'autocompletePopupType'],
      outputs: ['onAutocompleteSelect'] // eslint-disable-line
    }
  ]
})
export class TextFieldComponent extends WithGlobals {
  @Input() control = new FormControl('')
  @Input() label: string
  @Input() placeholder: string
  @Input() disabled = false
  @Input() readonly = false
  @Input() errorText: string
  @Input() infoText: string
  @Input() maxLength: number
  @Input() autoFocus: boolean
  @Input() forceFocus: boolean
  @Input() value: string
  @Input() rightButton: string
  @Input() rightButtonTooltip: string
  @Input() leftIcon: string
  @Input() rightIcon: string

  @Output() keyup: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>()
  @Output() keydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>()
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() change: EventEmitter<Event> = new EventEmitter<Event>()
  @Output() changed: EventEmitter<string> = new EventEmitter<string>()
  @Output() onButtonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()

  @ViewChild('formInput') formInput: ElementRef<HTMLInputElement>

  protected readonly colors = Color

  private destroyRef = inject(DestroyRef)

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => this.changed.emit(value))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.control.setValue(changes.value.currentValue)
    }
  }

  /**
   * Sets the input in focus
   */
  public setFocus(): void {
    if (!this.disabled) {
      this.formInput.nativeElement.focus()
    }
  }

  /**
   * Sets the input text to selected
   */
  public setSelect(): void {
    if (!this.disabled) {
      this.formInput.nativeElement.select()
    }
  }
}
