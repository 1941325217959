import { randAlphaNumeric } from '@ngneat/falso'

export function randomInt(max, min = 1): number {
  return Math.floor(Math.random() * max) + min
}

export function randomArray(max: number, min?: number): void[] {
  return new Array(randomInt(max, min || 1)).fill(void 0)
}

export function randomChar(): string {
  return randAlphaNumeric().toString()
}

export function randomUuid(): string {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
    .split('')
    .map(x => (x === '-' ? '-' : randomChar()))
    .join('')
}
