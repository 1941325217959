<figure class="avt" [ngClass]="'avt--' + size">
  <img
    *ngIf="imageUrl"
    priority
    class="avt__image"
    [ngSrc]="imageUrl"
    [attr.alt]="workspace.name"
    [width]="avatarPixelSizes[size]"
    [height]="avatarPixelSizes[size]"
  />

  <figcaption
    *ngIf="showText"
    class="avt__text"
    [class.avt__text--multiline]="multiline"
    [class.avt__text--multi-text]="showSecondaryText && workspace.memberCount"
    [style.height.px]="avatarPixelSizes[size]"
  >
    @if (workspace.name) {
    <span
      class="avt__text__primary"
      [ngStyle]="{'-webkit-line-clamp': lines}"
      [attr.title]="workspace.name"
    >
      {{ workspace.name }}
    </span>
    } @else {
    <span
      class="avt__text__primary aw-2-txt--italic aw-2-txt--light-steel"
      [ngStyle]="{'-webkit-line-clamp': lines}"
      [attr.title]="translations.noName"
    >
      {{ translations.noName }}
    </span>
    }

    <span
      *ngIf="showSecondaryText && workspace.memberCount"
      class="avt__text__secondary"
    >
      {{ workspace.memberCount > 1 ?
        translate(translations.membersCount, {members: workspace.memberCount}) :
        translations.memberCount
      }}
    </span>
  </figcaption>
</figure>
