import {
  AutomationTrigger,
  AutomationTriggerCategory,
  AutomationTriggerType,
  AutomationTriggerValueType
} from '@awork/features/project/models/automation-trigger.model'
import {
  AutomationAction,
  AutomationActionCategory,
  AutomationActionType,
  AutomationActionValueType
} from '@awork/features/project/models/automation-action.model'
import { ErrorResponse } from '@awork/_shared/services/api-client/ApiClient'
import isTest from '@awork/_shared/functions/is-test'
import replace from '@awork/_shared/functions/replace'
import { User } from '@awork/features/user/models/user.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { Project } from '@awork/features/project/models/project.model'
import { ProjectTemplate } from '@awork/features/project/models/project-template.model'
import { sortByAsc } from '@awork/_shared/functions/lodash'

export enum AutomationValueType {
  name = 'name',
  message = 'message',
  status = 'status',
  statusFrom = 'statusFrom',
  statusTo = 'statusTo',
  amount = 'amount',
  unit = 'unit',
  list = 'list',
  config = 'config',
  date = 'date',
  checklistItems = 'checklistItems',
  projectRole = 'projectRole',
  project = 'project',
  url = 'url',
  body = 'body',
  text = 'text',
  weekday = 'weekday',
  days = 'days',
  hours = 'hours',
  type = 'type',
  task = 'task',
  user = 'user',
  tag = 'tag',
  authheader = 'authheader',
  taskBundle = 'taskBundle'
}

export const AutomationTriggerActionColors = [
  '#FF4398',
  '#FF7A6A',
  '#FFD26A',
  '#32EE9B',
  '#9BF2D2',
  '#81E6F1',
  '#69BDFF',
  '#3187F6',
  '#C17FFF',
  '#8559E4',
  '#6C3BC1',
  '#FF4398'
]

export class AutomationRelatedUser {
  key: string

  constructor(key: string) {
    this.key = key
  }

  getName() {
    return q.translations.AutomationUserSelectorComponent[this.key]
  }
}

export interface AutomationEntityType {
  id: string
  name: string
  type?: string
  removeOldAssignments?: boolean
}

export type changedOperation = 'create' | 'update' | 'delete'

export interface AutomationTriggerActionCategory {
  name: string
  icon: string
  color: string
  key: AutomationTriggerCategory | AutomationActionCategory
  types: {
    key: AutomationTriggerType | AutomationActionType
    text: string
  }[]
}

export interface TextObject {
  type: 'text' | 'placeholder'
  value: AutomationTriggerValueType | AutomationActionValueType
  name?: string
  entityName?: string
  changeOperation?: changedOperation
  invalid?: boolean
}

export type AutomationEntity = ProjectTemplate | Project

export interface IAutomation {
  id: string
  enabled: boolean
  failed?: ErrorResponse
  trigger: AutomationTrigger
  createdBy?: string
  updatedBy?: string
  updatedOn?: Date
  createdByAutomationIdFromTemplate?: boolean
  actions: AutomationAction[]
  changeOperation?: changedOperation
  projectId?: string
}

export class Automation implements IAutomation {
  id: string
  enabled: boolean
  failed?: ErrorResponse
  trigger: AutomationTrigger
  createdBy?: string
  createdOn?: Date
  updatedBy?: string
  updatedOn?: Date
  createdByAutomationIdFromTemplate: boolean
  changeOperation?: changedOperation
  actions: AutomationAction[]
  projectId?: string
  projectTemplateId?: string
  creator: User

  translate = isTest() ? this.replaceTestNoOp : replace

  constructor(automation: IAutomation) {
    Object.assign(this, automation)

    this.creator = this.createdBy && UserQuery.instance ? UserQuery.instance.getUser(this.createdBy) : null
  }

  /**
   * Gets the automation text used in the widget
   * @returns {string}
   */
  get text(): string {
    const moreActions = this.actions.length - 1
    const moreActionsText =
      moreActions > 1
        ? this.translate(q.translations.AutomationModel.moreActions, { number: moreActions.toString() })
        : moreActions === 1
          ? q.translations.AutomationModel.oneMoreAction
          : ''

    const listText = this.actions.length ? sortByAsc(this.actions, a => a.order)[0].listText : ''

    return this.trigger?.listText + q.translations.AutomationModel.then + listText + moreActionsText
  }

  /**
   * Gets the icon based on the trigger
   * @returns {string}
   */
  get icon(): string {
    return this.trigger?.icon
  }

  /**
   * Gets the color based on the trigger
   * @returns {string}
   */
  get color(): string {
    return this.trigger?.color
  }

  static createNew(presetValues?: Object): Automation {
    return new Automation({
      id: undefined,
      createdBy: UserQuery.instance.getCurrentUser().id,
      actions: [],
      trigger: undefined,
      enabled: true,
      ...presetValues
    })
  }

  /**
   * Type guard to check if the automation's entity is a project
   * @param {AutomationEntity} entity
   * @returns {entity is Project}
   */
  static isProject(entity: AutomationEntity): entity is Project {
    return entity instanceof Project
  }

  /**
   * Type guard to check if the automation's entity is a project template
   * @param {AutomationEntity} entity
   * @returns {entity is ProjectTemplate}
   */
  static isProjectTemplate(entity: AutomationEntity): entity is ProjectTemplate {
    return entity instanceof ProjectTemplate
  }

  private replaceTestNoOp(msg: string, _: { [x: string]: string | number }): string {
    return msg + ''
  }

  get errorMessage(): string {
    switch (this.failed?.code) {
      case 'not-found-in-trigger':
      case 'invalid-model-in-trigger':
      case 'invalid-operation-in-trigger':
        return q.translations.AutomationModel.triggerErrorMessage
      case 'invalid-operation-in-action':
      case 'invalid-model-in-action':
      case 'not-found-in-action':
        return q.translations.AutomationModel.actionErrorMessage
      default:
        return q.translations.AutomationModel.defaultErrorMessage
    }
  }
}
