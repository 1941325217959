import { Injectable } from '@angular/core'
import { UserQuery } from '@awork/features/user/state/user.query'
import { hash } from '@awork/_shared/functions/hash'
import {
  abTests,
  TestGroup,
  TestGroupKey,
  TestGroupSlug,
  TestSlug
} from '@awork/_shared/services/ab-test-service/ab-test'
import { AppQuery } from '@awork/core/state/app.query'
import localStorageHelper from '@awork/_shared/functions/local-storage'
import { getOrCreateAnonymousId } from '@awork/_shared/functions/anonymous-id'

@Injectable({ providedIn: 'root' })
export class ABTestService {
  constructor(
    private userQuery: UserQuery,
    private appQuery: AppQuery
  ) {}

  /**
   * Returns whether the user is in a test group
   * @param {TestSlug} slug
   * @param {TestGroupSlug} testGroupSlug
   * @returns {boolean}
   */
  isTestGroup(slug: TestSlug, testGroupSlug: string = TestGroupSlug.Test): boolean {
    const testGroup = this.getTestGroup(slug, testGroupSlug)

    if (!testGroup) {
      return false
    }

    /*
     * Manually set for local debugging.
     * The test group slug, to be used, is retrieved from local storage using the test slug as the key.
     */
    const localTestGroup = this.getTestGroup(slug, localStorageHelper.getItem(slug))
    if (localTestGroup) {
      return testGroup === localTestGroup
    }

    if (!this.testHasStarted(slug)) {
      const defaultTestGroup = this.getDefaultTestGroup(slug)
      return defaultTestGroup ? defaultTestGroup.slug === testGroupSlug : false
    }

    return testGroup.keys.includes(this.getTestGroupKey())
  }

  /**
   * Returns the default test group of the current user
   * @returns {TestGroupKey}
   */
  getTestGroupKey(): TestGroupKey {
    const isLoggedIn = this.appQuery.getIsLoggedIn()

    const identifier = isLoggedIn ? this.userQuery.getCurrentUser().id : getOrCreateAnonymousId()
    const hashedIdentifier = hash(identifier)
    const groupedIdentifier = hashedIdentifier % 4

    const groupKey = Object.keys(TestGroupKey)[groupedIdentifier] as TestGroupKey

    return groupKey
  }

  /**
   * Returns whether the test has started
   * @param {TestSlug} slug
   * @returns {boolean}
   */
  private testHasStarted(slug: TestSlug): boolean {
    if (!abTests.has(slug)) {
      return false
    }

    return abTests.get(slug).startDate <= new Date()
  }

  /**
   * Returns the test group for the given slug and test group slug
   * @param {TestSlug} slug
   * @param {TestGroupSlug} testGroupSlug
   * @returns {TestGroup}
   */
  private getTestGroup(slug: TestSlug, testGroupSlug: string): TestGroup {
    if (!abTests.has(slug)) {
      throw new Error(`Cannot find A/B test with slug '${slug}'`)
    }

    return abTests.get(slug).groups?.find(group => group.slug === testGroupSlug)
  }

  /**
   * Returns the default test group for the given slug
   * @param {TestSlug} slug
   * @returns {TestGroup}
   */
  private getDefaultTestGroup(slug: TestSlug): TestGroup {
    if (!abTests.has(slug)) {
      throw new Error(`Cannot find A/B test with slug '${slug}'`)
    }

    return abTests.get(slug).groups?.find(group => group.isDefault)
  }

  /**
   * Returns whether the user is in a test group to target through intercom
   * @returns {boolean}
   */
  isIntercomTestGroup(): boolean {
    const user = this.userQuery.getCurrentUser()
    const hashedUserId = hash(user.id)

    return hashedUserId % 5 === 0
  }
}
