export class AutomationRelatedUser {
  key: string
  constructor(key: string) {
    this.key = key
  }
  getName() {
    return q.translations.AutomationUserSelectorComponent[this.key]
  }
  getNameForThenCase() {
    return q.translations.AutomationUserSelectorComponent[this.key + 'Thencase']
  }
}
