import { Injectable } from '@angular/core'
import { LogService } from '@awork/_shared/services/log-service/log.service'

/**
 * Methods to calculate the position of the tooltip
 */
@Injectable({ providedIn: 'root' })
export class PositionService {
  constructor(private logService: LogService) {}

  private get window(): Window {
    return window
  }

  private get document(): Document {
    return window.document
  }

  /**
   * Returns the computed style for a specific property of a DOM element
   */
  public getStyle(nativeEl: any, cssProp: string): any {
    // IE
    if (nativeEl.currentStyle) {
      return nativeEl.currentStyle[cssProp]
    }

    if (this.window.getComputedStyle) {
      return this.window.getComputedStyle(nativeEl)[cssProp]
    }
    // finally try and get inline style
    return nativeEl.style[cssProp]
  }

  /**
   * Checks if a given element is statically positioned
   * @param nativeEl - raw DOM element
   */
  public isStaticPositioned(nativeEl: any): any {
    return (this.getStyle(nativeEl, 'position') || 'static') === 'static'
  }

  /**
   * Checks if a given element is fixed positioned
   * @param nativeEl - raw DOM element
   */
  public isFixedPositioned(nativeEl: any): any {
    while (nativeEl && nativeEl !== window) {
      if (this.getStyle(nativeEl, 'position') === 'fixed') {
        return true
      }
      nativeEl = nativeEl.parentElement
    }
    return false
  }

  /**
   * Checks if a given element is in a relative or absolute positioned container
   * @param nativeEl - raw DOM element
   */
  public isRelativeOrAbsolutePositioned(nativeEl: any): any {
    while (nativeEl && nativeEl !== window) {
      if (this.getStyle(nativeEl, 'position') === 'relative' || this.getStyle(nativeEl, 'position') === 'absolute') {
        return true
      }
      nativeEl = nativeEl.parentElement
    }
    return false
  }

  /**
   * returns the closest, non-statically positioned parentOffset of a given element
   * @param nativeEl
   */
  private parentOffsetEl(nativeEl: any) {
    let offsetParent = nativeEl.offsetParent || this.document
    while (offsetParent && offsetParent !== this.document && this.isStaticPositioned(offsetParent)) {
      offsetParent = offsetParent.offsetParent
    }
    return offsetParent || this.document
  }

  /**
   * The .position() method allows us to retrieve the current position of an element (specifically its margin box)
   * relative to the offset parent (specifically its padding box, which excludes margins and borders).
   * Contrast this with .offset(), which retrieves the current position relative to the document.
   * When positioning a new element near another one and within the same containing DOM element,
   * .position() is the more useful.
   *
   * http://api.jquery.com/position/
   */
  public position(nativeEl: any): { width: number; height: number; top: number; left: number } {
    const elBCR = this.offset(nativeEl, 'PositionService#position[1]')

    let offsetParentBCR = { top: 0, left: 0 }
    const offsetParentEl = this.parentOffsetEl(nativeEl)

    if (offsetParentEl !== this.document) {
      offsetParentBCR = this.offset(offsetParentEl, 'PositionService#position[2]')
      offsetParentBCR.top -= offsetParentEl.clientTop - offsetParentEl.scrollTop
      offsetParentBCR.left -= offsetParentEl.clientLeft - offsetParentEl.scrollLeft
    }

    const boundingClientRect = nativeEl.getBoundingClientRect()

    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: elBCR.top - offsetParentBCR.top,
      left: elBCR.left - offsetParentBCR.left
    }
  }

  /**
   * The .offset() method allows us to retrieve the current position of an element (specifically its border box,
   * which excludes margins) relative to the document. Contrast this with .position(),
   * which retrieves the current position relative to the offset parent.
   * When positioning a new element on top of an existing one for global manipulation
   * (in particular, for implementing drag-and-drop), .offset() is more useful.
   *
   * http://api.jquery.com/offset/
   */
  public offset(nativeEl: any, caller: string): { width: number; height: number; top: number; left: number } {
    if (!nativeEl) {
      this.logService.sendLogDNA(
        'FATAL',
        `PositionService#offset called by ${caller} without providing a 'nativeEl'`,
        null
      )
      return {
        width: 0,
        height: 0,
        top: 0,
        left: 0
      }
    } else {
      const boundingClientRect = nativeEl.getBoundingClientRect()
      const isFixed = this.isFixedPositioned(nativeEl)
      return {
        width: boundingClientRect.width || nativeEl.offsetWidth,
        height: boundingClientRect.height || nativeEl.offsetHeight,
        top:
          boundingClientRect.top +
          (!isFixed &&
            (this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop)),
        left:
          boundingClientRect.left +
          (!isFixed &&
            (this.window.pageXOffset || this.document.documentElement.scrollLeft || this.document.body.scrollLeft))
      }
    }
  }

  /**
   * Checks if an element is in the current view of the screen and returns true, otherwise false
   */
  elementInViewport(el: any): boolean {
    let top = el.offsetTop
    let left = el.offsetLeft
    const width = el.offsetWidth
    const height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    )
  }
}
