// parse out just the options we care about
const looseOption = Object.freeze({
  loose: true
});
const emptyOpts = Object.freeze({});
const parseOptions = options => {
  if (!options) {
    return emptyOpts;
  }
  if (typeof options !== 'object') {
    return looseOption;
  }
  return options;
};
module.exports = parseOptions;