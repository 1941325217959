import { Injectable } from '@angular/core'
import { TeamStore } from '@awork/features/team/state/team.store'
import { Observable, map, switchMap } from 'rxjs'
import { Team } from '@awork/features/team/models/team.model'
import { UserQuery } from '@awork/features/user/state/user.query'
import { User } from '@awork/features/user/models/user.model'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'
import { Order } from '@awork/core/state/signal-store/types'

@Injectable({ providedIn: 'root' })
export class TeamQuery extends EntitySignalQuery<Team> {
  constructor(
    protected store: TeamStore,
    private userQuery: UserQuery
  ) {
    super(store)
  }

  /**
   * Selects team by id
   * @param {string} id
   * @returns {Observable<Team>}
   */
  selectTeam(id: string): Observable<Team> {
    return this.selectEntity(id).pipe(
      map(team => {
        if (!team) {
          return null
        }

        this.setUsers(team)
        return team
      })
    )
  }

  /**
   * Gets team by id
   * @param {string} id
   * @returns {Team}
   */
  getTeam(id: string): Team {
    const team = this.getEntity(id)

    if (!team.id) {
      return null
    }

    this.setUsers(team)
    return team
  }

  /**
   * Selects all teams
   * @param {number} limit
   * @param {string} searchQuery
   * @returns {Observable<Team[]>}
   */
  selectAllTeams(limit?: number, searchQuery?: string, excludedIds?: string[]): Observable<Team[]> {
    return this.selectAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: team => {
        const isNotExcluded = !excludedIds?.includes(team.id)
        const matchesQuery = !searchQuery || team.name?.toLowerCase().includes(searchQuery.toLowerCase())

        return isNotExcluded && matchesQuery
      },
      limitTo: limit
    }).pipe(
      map(teams =>
        teams.map(team => {
          this.setUsers(team)
          return team
        })
      )
    )
  }

  /**
   * Selects teams by id
   * @param {string[]} ids
   * @returns {Observable<Team[]>}
   */
  selectTeamsById(ids: string[]): Observable<Team[]> {
    return this.selectAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: team => ids.includes(team.id)
    }).pipe(
      map(teams =>
        teams.map(team => {
          this.setUsers(team)
          return team
        })
      )
    )
  }

  /**
   * Gets teams by id
   * @param {string[]} ids
   * @returns {Team[]}
   */
  getTeamsById(ids: string[]): Team[] {
    return this.getAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: team => ids.includes(team.id)
    }).map(team => {
      this.setUsers(team)
      return team
    })
  }

  /**
   * Get all teams
   * @param {number} limit
   * @param {string} searchQuery
   * @returns {Team[]}
   */
  getAllTeams(limit?: number, searchQuery?: string): Team[] {
    return this.getAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: searchQuery ? team => team.name.includes(searchQuery) : undefined,
      limitTo: limit
    }).map(team => {
      this.setUsers(team)
      return team
    })
  }

  /**
   * Selects the teams where the user belongs
   * @param {string} userId
   * @returns {Observable<Team[]>}
   */
  selectUserTeams(userId: string): Observable<Team[]> {
    return this.selectAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: team => team.userIds?.includes(userId)
    }).pipe(
      map(teams =>
        teams.map(team => {
          this.setUsers(team)
          return team
        })
      )
    )
  }

  /**
   * Gets the teams where the user belongs
   * @param {string} userId
   * @returns {Team[]}
   */
  getUserTeams(userId: string): Team[] {
    return this.getAll({
      sortBy: 'name',
      sortByOrder: Order.ASC,
      filterBy: team => team.userIds?.includes(userId)
    }).map(team => {
      this.setUsers(team)
      return team
    })
  }

  /**
   * Selects the users of a team
   * @param {string} teamId
   * @returns {Observable<User[]>}
   */
  selectTeamUsers(teamId: string): Observable<User[]> {
    return this.selectEntity(teamId).pipe(
      switchMap(team => {
        return this.userQuery.selectUsersById(team.userIds)
      })
    )
  }

  /**
   * Sets the team's users using the user store
   * @param {Team} team
   */
  setUsers(team: Team): void {
    if (team.userIds) {
      team.users = this.userQuery.getUsersByIds(team.userIds)
    }
  }
}
