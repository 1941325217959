export interface UserSettingValue {
  [x: string]: UserSetting[]
}

export interface IUserSetting {
  entityId?: string
  entityName?: string
  key: string
  value: string
}

export interface INpsUserSetting {
  lastActionType: NpsSettingActionType
  nextReminder?: Date
}

export interface IDesktopAppUserSetting {
  openLinksInDesktopApp: boolean
  desktopAppInstalled: boolean
}

export enum NpsSettingActionType {
  submitted = 'submitted',
  dismissed = 'dismissed'
}

export enum Settings {
  quickFilter = 'QuickFilter',
  grouping = 'Grouping',
  sorting = 'Sorting',
  selectedFilter = 'SelectedFilters',
  aggregation = 'Aggregation',
  view = 'View'
}

export class UserSetting implements IUserSetting {
  entityId?: string
  type?: string
  entityName?: string
  key: string
  value: string

  constructor(userSetting: IUserSetting) {
    Object.assign(this, userSetting)
  }
}
