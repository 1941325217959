import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ButtonSize, Size } from '@awork/_shared/types/size'
import { NgClass } from '@angular/common'
import { WithGlobals } from '../../../classes/with-globals'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'

@Component({
  selector: 'aw-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
  standalone: true,
  imports: [NgClass, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseButtonComponent extends WithGlobals {
  @Input() size: ButtonSize = Size.s
  @Input() tooltip: string = q.translations.common.close
}
