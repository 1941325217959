import { NgIf } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { AutoFocusDirective } from '../../../directives/auto-focus/auto-focus.directive'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { Color } from '@awork/_shared/types/color'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AutoGrowDirective } from '../../../directives/auto-grow/auto-grow.directive'

@Component({
  selector: 'aw-multiline-text-field',
  templateUrl: './multiline-text-field.component.html',
  styleUrls: ['../styles.scss', './multiline-text-field.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutoFocusDirective, InfoBoxComponent, NgIf, ReactiveFormsModule, AutoGrowDirective]
})
export class MultilineTextFieldComponent implements OnInit, OnChanges {
  @Input() control = new FormControl('')
  @Input() value: string
  @Input() label: string
  @Input() placeholder: string
  @Input() disabled = false
  @Input() errorText: string
  @Input() maxLength: number
  @Input() autoFocus: boolean
  @Input() minRows = 2 // the rows are calculate into height value (2x 14px padding + 20px per row) for animation
  @Input() maxRows = 6
  @Input() enableAutoGrow: boolean
  @Input() autoGrowMaxHeight: number
  @Input() autoGrowMinHeight: number

  @Output() keyup: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>()
  @Output() keydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>()
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>()
  @Output() change: EventEmitter<Event> = new EventEmitter<Event>()
  @Output() changed: EventEmitter<string> = new EventEmitter<string>()

  @ViewChild('formInput') formInput: ElementRef<HTMLInputElement>

  readonly colors = Color

  focused = false

  private destroyRef = inject(DestroyRef)

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => this.changed.emit(value))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.control.setValue(changes.value.currentValue, { emitEvent: false })
    }
  }

  /**
   * Sets the input in focus
   */
  setFocus(): void {
    if (!this.disabled) {
      this.formInput.nativeElement.focus()
    }
  }

  /**
   * Sets the input text to selected
   */
  setSelect(): void {
    if (!this.disabled) {
      this.formInput.nativeElement.select()
    }
  }
}
