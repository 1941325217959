import { ProjectMemberBase } from '@awork/features/project/models/project-member.model.base'

export interface IProjectBase {
  id: string
  name: string
  members: ProjectMemberBase[]
  hasImage?: boolean
  startDate: string | Date
  dueDate?: string | Date
  timeBudget?: number
  laneOrder?: number
  autoLaneOrder?: number
  createdOn?: Date
  updatedOn?: Date
}

export class ProjectBase implements IProjectBase {
  id: string
  name: string
  members: ProjectMemberBase[] = []
  hasImage?: boolean
  startDate: string | Date
  dueDate?: string | Date
  timeBudget?: number
  laneOrder?: number
  autoLaneOrder?: number
  createdOn?: Date
  updatedOn?: Date

  constructor(project: Partial<IProjectBase>) {
    Object.assign(this, project)
  }

  get startOn(): Date {
    return this.startDate ? new Date(this.startDate) : undefined
  }

  set startOn(value: Date) {
    this.startDate = value
  }

  get endOn(): Date {
    return this.dueDate ? new Date(this.dueDate) : undefined
  }

  set endOn(value: Date) {
    this.dueDate = value
  }
}
