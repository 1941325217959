import { Injectable } from '@angular/core'
import { User } from '@awork/features/user/models/user.model'
import { Absence } from '@awork/features/user/models/absence.model'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'

@Injectable({ providedIn: 'root' })
export class UserStore extends EntitySignalStore<User> {
  constructor() {
    super({
      entityConstructor: user => new User(user, 'UserStore.constructor'),
      name: 'user'
    })
  }

  preUpdateEntity(prevEntity: Readonly<User>, nextEntity: Readonly<User>): User {
    const absences = nextEntity.absences || prevEntity.absences || []
    const capacityPerWeek = nextEntity.capacityPerWeek ?? prevEntity.capacityPerWeek ?? undefined

    return { ...nextEntity, absences, capacityPerWeek } as User
  }

  /**
   * Sets the current user
   * @param {string} id
   */
  setCurrentUser(id: string): void {
    this.setActive(id)
  }

  /**
   * Delete absence
   * @param absence
   */
  deleteAbsence(absence: Absence): void {
    this.update(absence.userId, state => {
      const user = state as User
      user.absences = user.absences?.filter(a => a.id !== absence.id) || []
      return user
    })
  }

  /**
   * Sync user's absence.
   * @param {Absence} absence
   */
  syncAbsence(absence: Absence): void {
    this.update(absence.userId, user => {
      user.absences = user.absences?.filter(a => a.id !== absence.id) || []
      user.absences.push(absence)
      return user
    })
  }
}
