import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  OnChanges,
  output,
  SimpleChanges
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { Color } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'
import { WithGlobals } from '../../../classes/with-globals'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'
import { SwitchButtonDirection, SwitchButtonSize } from './types'

@Component({
  selector: 'aw-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipDirective, InfoBoxComponent, ReactiveFormsModule]
})
export class SwitchButtonComponent extends WithGlobals implements OnChanges {
  size = input<SwitchButtonSize>(Size.m)
  errorText = input<string>()
  control = input<FormControl>(new FormControl(false))
  value = input<boolean>()
  direction = input<SwitchButtonDirection>('auto')
  tooltip = input<{ on: string; off: string }>(this.getDefaultTooltip())
  label = input<string>()
  disabled = input<boolean>(false)
  changed = output<boolean>()

  @HostBinding('class.disabled')
  get disabledClass() {
    return this.disabled()
  }

  sizes = Size
  tooltipValues = computed(() => (this.tooltip() ? this.tooltip() : this.getDefaultTooltip()))

  protected translations = q.translations.SwitchButtonComponent
  protected readonly colors = Color

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.control().setValue(this.value())
    }
  }

  /**
   * Retrieves the default tooltip text for the switch button.
   *
   * This method returns an object containing the default tooltip text for the "on" and "off" states
   * of the switch button, based on the translations available in the application.
   *
   * @returns {{ on: string, off: string }} An object containing the default tooltip text for the "on" and "off" states.
   */
  getDefaultTooltip(): { on: string; off: string } {
    // The getter ensures it’s initialized when accessed and keeps the member sorting consistency
    return { on: q.translations.SwitchButtonComponent.on, off: q.translations.SwitchButtonComponent.off }
  }

  /**
   * Toggles the value and emits the click event
   */
  clicked(event: MouseEvent): void {
    if (!this.disabled()) {
      this.control().setValue(!this.control().value)
      this.changed.emit(this.control().value)
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
