import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core'
import { isResizeObserverAvailable, resizeObservable } from '@awork/_shared/functions/resize-observable'
import { defaultIfEmpty, filter, Subscription, throttleTime } from 'rxjs'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import { LogService } from '@awork/_shared/services/log-service/log.service'

@AutoUnsubscribe()
@Directive({
  selector: '[awAutoGrow]',
  standalone: true
})
export class AutoGrowDirective implements OnDestroy {
  @Input() enableAutoGrow = true
  @Input() autoGrowMaxHeight: number
  @Input() autoGrowMinHeight: number

  subscription: Subscription
  elementWidth: number

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private logService: LogService
  ) {
    this.elementWidth = this.elementRef.nativeElement.offsetWidth

    if (isResizeObserverAvailable()) {
      this.subscription = resizeObservable(this.elementRef.nativeElement)
        .pipe(
          filter(resizeEntry => resizeEntry.contentRect.width !== this.elementWidth),
          throttleTime(100, undefined, { leading: false, trailing: true }),
          defaultIfEmpty(() => {
            this.logService.sendLogDNA(
              'ERROR',
              'Invalid element provided to resizeObservable. Element is either null, undefined, or not an HTMLElement.',
              { element: this.elementRef.nativeElement },
              false,
              true
            )
          })
        )
        .subscribe((resizeEntry: ResizeObserverEntry | (() => void) | null | undefined) => {
          if (!resizeEntry || typeof resizeEntry === 'function') {
            this.logService.sendLogDNA(
              'ERROR',
              'Invalid resize entry received.',
              { element: this.elementRef.nativeElement },
              false,
              true
            )
            return
          }
          this.elementWidth = resizeEntry.contentRect.width
          this.autoGrow()
        })
    }
  }

  ngOnDestroy() {}

  @HostListener('input')
  onInput() {
    this.autoGrow()
  }

  private autoGrow() {
    if (!this.enableAutoGrow) {
      return
    }

    const nativeElement = this.elementRef.nativeElement

    this.renderer.setStyle(nativeElement, 'overflow', 'hidden')
    this.renderer.setStyle(nativeElement, 'height', 'auto')

    let newHeight = nativeElement.scrollHeight

    if (this.autoGrowMinHeight) {
      newHeight = Math.max(newHeight, this.autoGrowMinHeight)
    }

    if (this.autoGrowMaxHeight) {
      newHeight = Math.min(newHeight, this.autoGrowMaxHeight)
    }

    this.renderer.setStyle(nativeElement, 'height', `${newHeight}px`)
  }
}
