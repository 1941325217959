import { InjectionToken } from '@angular/core'
import { Criterion } from '@awork/_shared/functions/criteria/types'
import { isAnyCriterionTrue } from '@awork/_shared/functions/criteria/composition.criteria'
import {
  isDevelopCriterion,
  isDeveloperEnvironmentCriterion,
  isLocalhostCriterion
} from '@awork/_shared/functions/criteria/environment.criteria'
import { isAworkTestEmailCriterion } from '../functions/criteria/user.criteria'
import { isAworkProductionWorkspaceCriterion } from '../functions/criteria/workspace.criteria'

export enum Feature {
  ExampleFeature = 'exampleFeature',
  NewRichTextEditorLiveEditing = 'newRichTextEditorLiveEditing',
  NpsToastScheduling = 'npsToastScheduling',
  SkipAccountServiceUserCheck = 'skipAccountServiceUserCheck',
  TimeReportsListView = 'timeReportsListView',
  ConnectDashboardVideoOnboarding = 'connectDashboardVideoOnboarding',
  ConnectUpdatedOnboardingChecklist = 'connectUpdatedOnboardingChecklist',
  ConnectSubscribeButton = 'connectSubscribeButton'
}

export type FeatureFlags = { [key in Feature]: Criterion[] }
const featureFlags: FeatureFlags = {
  [Feature.ExampleFeature]: [isDevelopCriterion],
  [Feature.NewRichTextEditorLiveEditing]: [() => true], // @Framework team, please remove
  [Feature.SkipAccountServiceUserCheck]: [() => true], // @Framework team, please remove
  [Feature.TimeReportsListView]: [
    isAnyCriterionTrue([isAworkProductionWorkspaceCriterion, isDevelopCriterion, isLocalhostCriterion])
  ],
  [Feature.NpsToastScheduling]: [isAnyCriterionTrue([isDevelopCriterion, isLocalhostCriterion])],
  [Feature.ConnectDashboardVideoOnboarding]: [
    isAnyCriterionTrue([isAworkTestEmailCriterion, isDeveloperEnvironmentCriterion])
  ],
  [Feature.ConnectUpdatedOnboardingChecklist]: [
    isAnyCriterionTrue([isAworkTestEmailCriterion, isDeveloperEnvironmentCriterion])
  ],
  [Feature.ConnectSubscribeButton]: [isAnyCriterionTrue([isAworkTestEmailCriterion, isDeveloperEnvironmentCriterion])]
}

export const FeatureFlagsToken = new InjectionToken<FeatureFlags>('FeatureFlags', {
  providedIn: 'root',
  factory: () => featureFlags
})
