import { computed, Injectable, Signal } from '@angular/core'
import { WorkspaceStore } from '@awork/features/workspace/state/workspace.store'
import { Observable, filter, map } from 'rxjs'
import { Workspace, WorkspaceFeature } from '@awork/features/workspace/models/workspace.model'
import { EntitySignalQuery } from '@awork/core/state/signal-store/entitySignalQuery'
import { Order } from '@awork/core/state/signal-store/types'

@Injectable({ providedIn: 'root' })
export class WorkspaceQuery extends EntitySignalQuery<Workspace> {
  constructor(protected store: WorkspaceStore) {
    super(store)
  }

  /**
   * Selects current workspace
   */
  selectCurrentWorkspace(): Observable<Workspace> {
    return (<Observable<Workspace>>this.selectActive()).pipe(
      filter(workspace => !!workspace),
      map(workspace => this.mapEntity(workspace))
    )
  }

  /**
   * Gets current workspace
   */
  getCurrentWorkspace(): Workspace {
    const currentWorkspace = this.getActive() as Workspace
    return this.mapEntity(currentWorkspace)
  }

  /**
   * Gets current workspace
   * @returns {Signal<Workspace>}
   */
  queryCurrentWorkspace(): Signal<Workspace> {
    return computed(() => {
      const currentWorkspace = this.queryActive()
      return currentWorkspace() ? this.mapEntity(currentWorkspace()) : null
    })
  }

  /**
   * Selects workspace by id
   * @param {string} id
   */
  selectWorkspace(id: string): Observable<Workspace> {
    return this.selectEntity(id).pipe(map(workspace => this.mapEntity(workspace)))
  }

  /**
   * Selects all workspaces
   */
  selectAllWorkspaces(): Observable<Workspace[]> {
    return this.selectAll({
      sortBy: 'lastLogin',
      sortByOrder: Order.DESC
    }).pipe(map(workspaces => this.mapEntities(workspaces)))
  }

  /**
   * Gets all workspaces
   */
  getAllWorkspaces(): Workspace[] {
    const workspaces = this.getAll({
      sortBy: 'lastLogin',
      sortByOrder: Order.DESC
    })

    return this.mapEntities(workspaces)
  }

  /**
   * Checks if a feature is enabled for the workspace
   * @param {WorkspaceFeature} feature
   * @returns {boolean}
   */
  isFeatureEnabled(feature: WorkspaceFeature): boolean {
    return this.getCurrentWorkspace()?.isFeatureEnabled(feature)
  }

  /**
   * Checks if a feature is enabled for the workspace
   * @param {WorkspaceFeature} feature
   * @returns {boolean}
   */
  selectIsFeatureEnabled(feature: WorkspaceFeature): Observable<boolean> {
    return this.selectCurrentWorkspace().pipe(
      map(workspace => {
        return workspace.isFeatureEnabled(feature)
      })
    )
  }
}
