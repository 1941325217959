export enum CustomFieldTypeValue {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Datetime = 'datetime',
  Select = 'select',
  ColoredSelect = 'coloredSelect',
  Link = 'link',
  Boolean = 'boolean',
  User = 'user'
}

interface ICustomFieldType {
  type: CustomFieldTypeValue
  name: string
  icon: string
  isMainType: boolean
}

/**
 * Represents the predefined types of the custom fields.
 * It mandates how the custom field will look and the kind of data it holds.
 */
export class CustomFieldType implements ICustomFieldType {
  type: CustomFieldTypeValue
  name: string
  icon: string
  isMainType: boolean

  constructor(customFieldType: ICustomFieldType) {
    Object.assign(this, customFieldType)
  }

  /**
   * Gets all available custom field types
   */
  static getCustomFieldTypes(): CustomFieldType[] {
    const translations = q.translations.CustomFieldModel.type

    return [
      new CustomFieldType({
        type: CustomFieldTypeValue.Text,
        name: translations.text,
        icon: 'text_fields',
        isMainType: true
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.Number,
        name: translations.number,
        icon: 'tag',
        isMainType: true
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.Date,
        name: translations.date,
        icon: 'today',
        isMainType: true
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.Datetime,
        name: translations.date,
        icon: 'today',
        isMainType: false
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.Select,
        name: translations.select,
        icon: 'list',
        isMainType: true
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.ColoredSelect,
        name: translations.select,
        icon: 'list',
        isMainType: false
      }),
      new CustomFieldType({ type: CustomFieldTypeValue.Link, name: translations.link, icon: 'link', isMainType: true }),
      new CustomFieldType({
        type: CustomFieldTypeValue.Boolean,
        name: translations.boolean,
        icon: 'toggle_on',
        isMainType: true
      }),
      new CustomFieldType({
        type: CustomFieldTypeValue.User,
        name: translations.user,
        icon: 'person',
        isMainType: true
      })
    ]
  }

  /**
   * Gets the custom field type by its value
   * @param {CustomFieldTypeValue} type
   * @returns {CustomFieldType}
   */
  static getCustomFieldType(type: CustomFieldTypeValue): CustomFieldType {
    return this.getCustomFieldTypes().find(customFieldType => customFieldType.type === type)
  }
}
