export enum TrackingEvent {
  absenceCreated = 'Absence Created',
  aiUsed = 'AI used',
  automationCreated = 'Automation Created',
  autopilotChanged = 'Autopilot Changed',
  projectAssigneesModalTabSelected = 'Project Assignees Modal Tab Selected',
  projectAssigneesModalOpened = 'Project Assignees Modal Opened',
  aworkIn15MinutesVideoInteracted = 'awork in 15 Minutes Video Interacted',
  boardViewmodeUsed = 'Board Viewmode Used',
  calendarIntegrated = 'Calendar Integrated',
  calendarIntegrationModalClosed = 'Calendar Integration Times Modal Closed',
  cancellationDealClicked = 'Cancellation Deal Clicked',
  cancellationModalClosed = 'Cancellation Modal Closed',
  cancellationModalOpened = 'Cancellation Modal Opened',
  chatAppIntegrated = 'Chat App Integrated',
  clientCreated = 'Client Created',
  commentCreated = 'Comment Created',
  commentReactionCreated = 'Comment Reaction Created',
  connectBannerArrowClicked = 'Connect Banner Arrow Clicked',
  connectBannerInfoClicked = 'Connect Banner Info Clicked',
  connectBannerVideoInteracted = 'Connect Banner Video Interacted',
  connectBusinessModalClosed = 'Connect Business Modal Closed',
  connectBusinessModalOpened = 'Connect Business Modal Opened',
  connectBusinessStartTrialClicked = 'Connect Business Start Trial Clicked',
  connectLearnMoreClicked = 'Connect Learn More Clicked',
  connectProjectBannerClosed = 'Connect Project Banner Closed',
  connectSignUpPageLoaded = 'Connect Sign Up Page Loaded',
  connectSignUpPageClicked = 'Connect Sign Up Page Clicked',
  connectInvitationDeclined = 'Connect Invitation Declined',
  connectOnboardingVideoClosed = 'Connect Onboarding Video Closed',
  connectOnboardingVideoCtaClicked = 'Connect Onboarding Video Cta Clicked',
  connectOnboardingVideoInteracted = 'Connect Onboarding Video Interacted',
  connectOnboardingDashboardWidgetClosed = 'Connect Onboarding Dashboard Widget Closed',
  connectProjectButtonClicked = 'Connect Project Button Clicked',
  connectProjectCreationClicked = 'Connect Project Creation Clicked',
  connectPendingInvitationCreated = 'Connect Pending Invitation Created',
  connectUserChangeInvitationRole = 'Connect User Change Invitation Role',
  connectUserResendInvitation = 'Connect User Resend Invitation',
  connectUserRevokeInvitation = 'Connect User Revoke Invitation',
  connectSettingsVideoInteracted = 'Connect Settings Video Interacted',
  darkModeSwitched = 'Dark Mode Switched',
  dashboardConfigured = 'Dashboard Configured',
  connectCeoVideoModalOpened = 'Connect CEO Video Modal Opened',
  connectCeoVideoModalButtonClicked = 'Connect CEO Video Modal Button Clicked',
  connectCeoVideoModalClosed = 'Connect CEO Video Modal Closed',
  connectCeoVideoModalInteracted = 'Connect CEO Video Modal Interacted',
  dataImported = 'Data Imported',
  desktopAppUsed = 'Desktop App Used',
  editModalUsed = 'Edit Modal Used',
  featureRestrictionBadgeClicked = 'Feature Restriction Badge Clicked',
  freeLimitAlertSeen = 'Free Limit Alert Seen',
  guestRoleAssigned = 'Guest Role Assigned',
  invitationAccepted = 'Invitation Accepted',
  inviteModalOpened = 'Invite Modal Opened',
  invoiceCreated = 'Invoice Created',
  milestoneCreated = 'Milestone Created',
  mobileAppLogin = 'Mobile App Login',
  myPlannerUsed = 'My Planner Used',
  newPlannerActivated = 'New Planner Activated',
  npsRated = 'NPS Rated',
  onboardingChecklistClicked = 'Onboarding Checklist Clicked',
  onboardingChecklistItemClicked = 'Onboarding Checklist Item Clicked',
  onboardingChecklistCompleted = 'Onboarding Checklist Completed',
  onboardingChecklistItemCompleted = 'Onboarding Checklist Item Completed',
  onboardingChecklistPermanentlyClosed = 'Onboarding Checklist Permanently Closed',
  onboardingCompleted = 'Onboarding Completed',
  onboardingPopupPermanentlyClosed = 'Onboarding Popup Permanently Closed',
  onboardingScreenSeen = 'Onboarding Screen Seen',
  pageViewed = 'Page Viewed',
  personioIntegrated = 'Personio Integrated',
  plannerOnboardingModalClickGetHelp = 'Planner Onboarding Modal Get Help Clicked',
  plannerOnboardingModalOpened = 'Planner Onboarding Modal Opened',
  plannerOnboardingModalClicked = 'Planner Onboarding Modal Clicked',
  plannerOnboardingModalClosed = 'Planner Onboarding Modal Closed',
  plannerUsed = 'Planner Used',
  plannerOnboardingVideoInteracted = 'Planner Onboarding Video Interacted',
  privateEmailError = 'Private Email Error',
  projectCreateButtonClicked = 'Project Create Button Clicked',
  projectConnectionResendInvitationConfirmed = 'Project Connection Resend Invitation Confirmed',
  projectCreated = 'Project Created',
  projectDiscoverBadgeClicked = 'Project Discover Badge Clicked',
  projectFileAttached = 'Project File Attached',
  projectLinkCopied = 'Project Link Copied',
  projectLinkedToChatChannel = 'Project Linked to Chat Channel',
  projectMemberUserRemoved = 'Project Members User Removed',
  projectMemberUserRoleChanged = 'Project Members User Role Changed',
  projectTemplateCreated = 'Project Template Created',
  projectTypeCreated = 'Project Type Created',
  projectTimesOpened = 'Project Times Opened',
  referralModalOpened = 'Referral Modal Opened',
  referralModalClosed = 'Referral Modal Closed',
  referralModalCodeCopied = 'Referral Modal Code Copied',
  referralModalMailSent = 'Referral Modal Mail Sent',
  sameDomainSignupExistingWorkspaceJoined = 'Same Domain Signup Existing Workspace Joined',
  sameDomainSignupNewWorkspaceCreated = 'Same Domain Signup New Workspace Created',
  searchUsed = 'Search Used',
  signedUp = 'Signed Up',
  signUpPageLoaded = 'Signup Page Loaded',
  signUpProviderAccepted = 'Signup Provider Accepted',
  signUpProviderSelected = 'Signup Provider Selected',
  signUpBackClicked = 'Signup Back Clicked',
  signUpLoginRedirected = 'Signup Login Redirected',
  subscriptionChanged = 'Subscription Changed',
  subscriptionBookedSeatsDowngraded = 'Subscription Booked Seats Downgraded',
  subscriptionChangePlanModalClosed = 'Subscription Change Plan Modal Closed',
  subscriptionChangePlanModalOpened = 'Subscription Change Plan Modal Opened',
  subscriptionModalClickAddReferralCode = 'Subscription Modal 2 Add Referral Code Clicked',
  subscriptionModalClickBookNow = 'Subscription Modal 1 Book Now Clicked',
  subscriptionModalClickChangeBillingCycle = 'Subscription Modal 2 Change Billing Cycle Clicked',
  subscriptionModalClickSubscribeNow = 'Subscription Modal 2 Subscribe Now Clicked',
  subscriptionModalClickTeamSize = 'Subscription Modal 2 Team Size Clicked',
  subscriptionModalClosed = 'Subscription Modal 1 Closed',
  subscriptionModalEnterReferralCode = 'Subscription Modal 2 Referral Code Entered',
  subscriptionModalOpened = 'Subscription Modal Opened',
  subscriptionStarted = 'Subscription Started',
  subtaskCreated = 'Sub Task Created',
  taskBoardViewGrouped = 'Task Board View Grouped',
  taskBundleCreated = 'Task Bundle Created',
  taskBundleUsed = 'Task Bundle Used',
  taskClosed = 'Task Closed',
  taskCreated = 'Task Created',
  taskDependencyCreated = 'Task Dependency Created',
  taskFileAttached = 'Task File Attached',
  taskLinkCopied = 'Task Link Copied',
  taskListCreated = 'Task List Created',
  taskScheduleSyncEnabled = 'Task schedule sync enabled',
  taskSetRecurring = 'Task set Recurring',
  taskSmartListCreated = 'Task Smart List Created',
  taskTimelineUsed = 'Task Timeline Used',
  teamCreated = 'Team Created',
  timeBookingCreated = 'Time Booking Created',
  timeCreated = 'Time Created',
  timelineDownload = 'Timeline Image Downloaded',
  timelineTasksExported = 'Timeline Tasks Exported',
  timeReportCreated = 'Time Report Created',
  timeReportPrinted = 'Time Report Printed',
  timeReportSaved = 'Time Report Saved',
  timeReportDuplicated = 'Time Report Duplicated',
  timeReportOpened = 'Time Report Opened',
  timeTrackingStart = 'Time Tracking Start',
  timeTrackingStop = 'Time Tracking Stop',
  timeTrackingPaused = 'Time Tracking Paused',
  timeTrackingPauseDeleted = 'Time Tracking Pause Deleted',
  timeTrackingResumed = 'Time Tracking Resumed',
  timeTrackingAborted = 'Time Tracking Aborted',
  timeTrackingStartEdited = 'Time Tracking Start Edited',
  trialExpiredModalClicked = 'Trial Expired Modal Clicked',
  trialExpiredModalOpened = 'Trial Expired Modal Opened',
  trialStarted = 'Trial Started',
  userInvited = 'User Invited',
  userMentioned = 'User Mentioned',
  editorUsed = 'Editor Used',
  customFieldCreated = 'Custom Field Created',
  guestMigrated = 'Guest Migrated',
  guestMigrationRequested = 'Guest Migration Requested',
  workspaceAbsenceCreated = 'Workspace Absence Created',
  publicHolidayRegionCreated = 'Public Holiday Region Created',
  plannerTimeRangeSelected = 'Planner Time Range Selected',
  helpContentClicked = 'Help Content Clicked',
  contactUsModalOpened = 'Contact Us Modal Opened'
}
