import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { AutocompleteOptionItem } from './autocomplete-option-item.model'

@Component({
  template: '',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteBaseComponent<T> {
  @Input() options: AutocompleteOptionItem<T>[] = []
  @Input() selectedIndex: number

  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>()

  @HostBinding('attr.tabindex') tabIndex = '0'

  constructor(public el: ElementRef) {}
}
