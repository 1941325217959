import { generateGUID } from '@awork/_shared/functions/guid-generator'
import { Task } from '@awork/features/task/models/task.model'

interface IChecklistItem {
  id: string
  name: string
  isDone: boolean
  order: number
  createdOn?: Date
  updatedOn?: Date
  isNew?: boolean
  task?: Task
  taskId?: string
}

export type ChecklistItems = ChecklistItem[]

export class ChecklistItem implements IChecklistItem {
  id: string
  name: string
  isDone: boolean
  order: number
  createdOn?: Date
  updatedOn?: Date
  isNew?: boolean
  task?: Task
  taskId?: string

  constructor(checklistItem: IChecklistItem) {
    Object.assign(this, checklistItem)
  }

  /**
   * Creates an empty checklistItem
   * @param length - Current length of the checklistItems to create the new checklistItem at the end
   */
  static empty(length: number): ChecklistItem {
    return new ChecklistItem({
      id: generateGUID(),
      name: '',
      isDone: false,
      order: length,
      isNew: true
    })
  }
}
