import { Company } from '@awork/features/company/models/company.model'
import { ProjectType } from '@awork/features/project/models/project-type.model'
import { ProjectStatus } from '@awork/features/project/models/project-status.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'
import { User } from '@awork/features/user/models/user.model'
import { ProjectMilestone } from '@awork/features/project/models/project-milestone.model'
import { IntegrationProviders } from '@awork/_shared/models/integration.model'
import { Tag } from '@awork/_shared/models/tag.model'
import { Team } from '@awork/features/team/models/team.model'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { ProjectQuery } from '@awork/features/project/state/project.query'
import { CompanyQuery } from '@awork/features/company/state/company.query'
import { IProjectBase, ProjectBase } from '@awork/features/project/models/project.model.base'
import { Color, DeprecatedColor } from '@awork/_shared/types/color'
import { getMappedColor } from '@awork/_shared/functions/map-deprecated-colors'
import { Workspace } from '@awork/features/workspace/models/workspace.model'
import { CustomFieldDefinitionLink } from '@awork/features/project/models/custom-field-definition-link.model'
import { DynamicDurationPipe } from '@awork/_shared/pipes/dynamic-duration/dynamic-duration.pipe'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { translate } from '../../../_shared/functions/automation-translation-helper'

export interface ProjectConnectionInformation {
  homeWorkspaceId: string
  homeUserFullName: string
  homeProjectName: string
  homeWorkspaceName: string
  homeWorkspacePublicImageToken: string
  homeUserPublicImageToken: string

  // Partial entities with only the needed properties to display avatars
  homeUserAvatarEntity?: User
  homeWorkspaceAvatarEntity?: Workspace
}

export interface ProjectConnectionInvite {
  id: string
  projectId: string
  inviteCode: string
  isAccepted: boolean
  projectRoleId: string
  externalUserEmail: string
  externalWorkspaceId?: string
  externalUserId?: string
  externalUserFullName?: string
}

export interface ProjectConnectionStatus {
  isConnected: boolean
  isReadOnly?: boolean
}

export interface MemberCapacity {
  timeBudget: number
  projectMemberId: string
  projectId: string
}

export enum AutopilotType {
  agile = 'agile',
  waterfall = 'waterfall'
}

export enum AutopilotChannel {
  msTeams = 'ms-teams',
  slack = 'slack',
  mail = 'mail'
}

export interface LinkedChat {
  channelName: string
  channelId: string
  provider: IntegrationProviders
}

export interface Autopilot {
  id: string
  projectId: string
  type: AutopilotType
  alerts: AutopilotAlert[]
  enabled: boolean
}

export interface AutopilotAlert {
  id: string
  autopilotId: string
  alert: string
  enabled: boolean
  channel: AutopilotChannel
}

export enum ProgressMode {
  time = 'progressByTime',
  tasks = 'progressByTasks',
  budget = 'progressByBudget'
}

export const ProjectColors = [
  Color.Red,
  Color.Orange,
  Color.Yellow,
  Color.Green,
  Color.Cyan,
  Color.Blue,
  Color.Indigo,
  Color.Purple
]

export type ProjectColor = (typeof ProjectColors)[number]

interface IProject {
  projectTypeId: string
  projectType: ProjectType
  projectTemplateId?: string
  publicProjectTemplateId?: string
  projectStatusId: string
  projectStatus: ProjectStatus
  companyId: string
  company: Partial<Company>
  salesType?: ProjectType
  updatedByUser: User
  imageLastUpdatedOn?: Date
  createdBy: string
  createdByUser: User
  members: ProjectMember[]
  description?: string
  tags: Tag[]
  teams?: Team[]
  trackedDuration: number
  resourceVersion: number
  tasksLoaded: boolean
  score?: number
  milestones?: ProjectMilestone[]
  pinned: boolean
  linkedChats?: LinkedChat[]
  showAsDisabled?: boolean
  tasksDoneCount?: number
  tasksCount?: number
  plannedDuration?: number
  isBillableByDefault: boolean
  progressMode: ProgressMode
  modifiedOn?: number
  isPrivate?: boolean
  color?: ProjectColor | DeprecatedColor
  isExternal: boolean
  customFieldDefinitionLinks?: CustomFieldDefinitionLink[]
}

/**
 * The project in awork
 */
export class Project extends ProjectBase implements IProject {
  projectTypeId: string
  projectType: ProjectType
  projectTemplateId?: string
  publicProjectTemplateId?: string
  projectStatusId: string
  projectStatus: ProjectStatus
  companyId: string
  company: Company
  salesType?: ProjectType
  updatedByUser: User
  imageLastUpdatedOn?: Date
  createdBy: string
  createdByUser: User
  members: ProjectMember[]
  description?: string
  tags: Tag[]
  teams?: Team[]
  trackedDuration: number
  resourceVersion: number
  tasksLoaded: boolean
  score?: number // optional property to store the search score
  milestones?: ProjectMilestone[]
  pinned: boolean
  linkedChats?: LinkedChat[]
  showAsDisabled?: boolean
  tasksDoneCount?: number
  tasksCount?: number
  plannedDuration?: number
  isBillableByDefault: boolean
  progressMode: ProgressMode
  modifiedOn?: number
  isPrivate?: boolean
  color: ProjectColor
  typeName = 'Project'
  isExternal: boolean
  customFieldDefinitionLinks?: CustomFieldDefinitionLink[]

  constructor(project: Partial<IProject & IProjectBase>) {
    super(project)

    if (!this.imageLastUpdatedOn) {
      this.imageLastUpdatedOn = this.updatedOn ? new Date(this.updatedOn) : new Date()
    } else if (typeof this.imageLastUpdatedOn === 'string') {
      this.imageLastUpdatedOn = new Date(this.imageLastUpdatedOn)
    }

    this.color = project.color ? getMappedColor(project.color) : undefined

    this.setProgressMode()
  }

  static isProject(project: any): project is Project {
    return (project as Project)?.typeName === 'Project'
  }

  get urlName(): string {
    return decodeURIComponent(this.name.replace(/ /g, '-'))
  }

  get nameAndClient(): string {
    if (this.company && this.company.name) {
      return `${this.name} (${this.company.name})`
    }
    return this.name
  }

  get progressBudgetTextColor(): 'red' {
    if (!this.timeBudget) {
      return null
    }

    return this.trackedDuration / this.timeBudget > 1 ? 'red' : null
  }

  /**
   * Get text for progress tooltip
   */
  progressTooltipText(opts: {
    progress: {
      time: number
      tasks: number
      budget: number
    }
  }): string {
    const translation = q.translations.ProjectModel

    let currentProgress = ''

    switch (this.progressMode) {
      case ProgressMode.time:
        currentProgress = `${opts.progress.time}%`
        break
      case ProgressMode.tasks:
        currentProgress = `${opts.progress.tasks}%`
        break
      case ProgressMode.budget:
        currentProgress = `${opts.progress.budget}%`
        break
    }

    const currentProgressString = translate(translation[this.progressMode], {
      percentage: currentProgress
    })

    const nextProgress = this.nextProgressMode ? translation[this.nextProgressMode + 'Next'] : ''

    return nextProgress
      ? `<p class="aw-2-txt--center">${currentProgressString}<br/>${nextProgress}</p>`
      : currentProgressString
  }

  /**
   * Get the progress tooltip secondary text based on user settings
   * @param {SettingsQuery} settingsQuery
   * @returns {string}
   */
  progressTooltipSecondaryText(settingsQuery: SettingsQuery): string {
    const dynamicDurationPipe = new DynamicDurationPipe(settingsQuery)
    const tracked = dynamicDurationPipe.transform(this.trackedDuration || 0)
    const planned = dynamicDurationPipe.transform(this.plannedDuration || 0)
    const budget = dynamicDurationPipe.transform(this.timeBudget || 0)

    switch (this.progressMode) {
      case ProgressMode.time:
        return `<i class="aw-icn aw-icn--s aw-icn--blue">timer</i> ${tracked} / ${planned}`
      case ProgressMode.tasks:
        return `<i class="aw-icn aw-icn--s aw-icn--green">done_all</i> ${this.tasksDoneCount} / ${this.tasksCount}`
      case ProgressMode.budget:
        // eslint-disable-next-line max-len
        return `<i class="aw-icn aw-icn--s aw-icn--blue">timer</i> ${tracked} / <i class="aw-icn aw-icn--s aw-icn--purple">timelapse</i> ${budget}`
    }
  }

  /**
   * Maps the project object and its nested objects
   * @param {Project} project - The project to be mapped
   * @returns {Project}
   */
  static mapProject(project: Project): Project {
    project = new Project(project)

    if (project.projectType) {
      project.projectType = new ProjectType(project.projectType)
    }

    if (project.projectStatus) {
      project.projectStatus = new ProjectStatus(project.projectStatus)
    }

    if (project.company) {
      project.company = new Company(project.company)
    } else if (!project.companyId) {
      project.company = null
      project.companyId = null
    }

    if (project.salesType) {
      project.salesType = new ProjectType(project.salesType)
    }

    if (project.members) {
      project.members = project.members.map(member => new ProjectMember(member))
    }

    if (project.milestones) {
      project.milestones = project.milestones.map(milestone => new ProjectMilestone(milestone))
    }

    // Map array of strings to array of tags: Tag[].
    // To be removed later after the release and make sure all the stores are updated
    if (project.tags && isArrayOfStrings(project.tags)) {
      project.tags = mapTagsArr(project.tags)
    }

    if (project.teams) {
      project.teams = project.teams.map(team => new Team(team))
    }

    if (project.dueDate) {
      project.dueDate = new Date(project.dueDate)
    }

    if (project.customFieldDefinitionLinks) {
      project.customFieldDefinitionLinks = project.customFieldDefinitionLinks.map(
        customFieldDefinitionLink => new CustomFieldDefinitionLink(customFieldDefinitionLink)
      )
    }

    return project
  }

  /**
   * Sets the progress mode according if project has time budget or planned duration
   * @param {boolean} isTimeTrackingDisabled
   */
  setProgressMode(isTimeTrackingDisabled?: boolean): void {
    if (isTimeTrackingDisabled) {
      this.progressMode = ProgressMode.tasks
      return
    }

    this.progressMode = this.isPrivate
      ? ProgressMode.tasks
      : this.timeBudget
        ? ProgressMode.budget
        : this.plannedDuration
          ? ProgressMode.time
          : ProgressMode.tasks
  }

  /**
   * Switches the progress mode between budget, time and tasks
   * Budget > Time > Task
   */
  setNextProgressMode(): void {
    if (this.nextProgressMode) {
      this.progressMode = this.nextProgressMode
    }
  }

  /**
   * Gets the next progress mode
   */
  get nextProgressMode(): ProgressMode {
    if (this.progressMode === ProgressMode.budget && this.plannedDuration) {
      return ProgressMode.time
    }

    if (this.progressMode === ProgressMode.tasks && this.timeBudget) {
      return ProgressMode.budget
    }

    if (this.progressMode === ProgressMode.tasks && this.plannedDuration) {
      return ProgressMode.time
    }

    if (this.progressMode !== ProgressMode.tasks) {
      return ProgressMode.tasks
    }

    return null
  }

  /**
   * Gets the project image according to the project data
   * @param {number} width
   * @param {number} height
   * @param {boolean} addURLWrapper
   * @param {ProjectQuery} projectQuery
   * @param {CompanyQuery} companyQuery
   * @returns {string}
   */
  getImage(
    width?: number,
    height?: number,
    addURLWrapper = true,
    projectQuery?: ProjectQuery,
    companyQuery?: CompanyQuery
  ): string {
    let image = null

    if (!!this.company && !(this.company instanceof Company)) {
      this.company = new Company(this.company)
    }

    if (this.hasImage) {
      image = this.profileImage(width, height, projectQuery)
    } else if (this.company?.hasImage) {
      image = this.company.getImage(width, height, companyQuery)
    }

    return image && addURLWrapper ? `url("${image}")` : image
  }

  /**
   * Returns the workspace image that belongs to the project
   */
  getWorkspaceImage(): string {
    return `${apiEndpoint}/files/images/projects/${this.id}/workspace`
  }

  /**
   * Gets project profile image
   * @param {number} width
   * @param {number} height
   * @param {ProjectQuery} projectQuery
   * @returns {string}
   */
  profileImage(width = 500, height = 500, projectQuery?: ProjectQuery): string {
    let url: string
    // Get the project from the store to use the updatedOn in case this model does not have one
    if (projectQuery) {
      const project = projectQuery.getProject(this.id)

      if (project) {
        this.imageLastUpdatedOn = project.imageLastUpdatedOn
      }
    }

    const pixelRatio = getPixelRatio()
    let queryOptions: any = {
      crop: false,
      width: Math.floor(width * pixelRatio),
      height: Math.floor(height * pixelRatio),
      v: this.imageLastUpdatedOn?.getTime?.()
    }

    // To fetch the images also locally
    if (isLiveMobile && AccountQuery.instance) {
      const accountState = AccountQuery.instance.getAccount()
      if (accountState) {
        const accessToken = accountState.accessToken as string
        queryOptions = {
          ...queryOptions,
          jwt: accessToken,
          'aw-mobile': 'true'
        }
      }
    }

    url = `${apiEndpoint}/files/images/projects/${this.id}?${new URLSearchParams(queryOptions).toString()}`

    return url
  }
}

/* TODO: This is not used anywhere, can be removed */
export function projectNames(): Object {
  return {
    id: 'id',
    plannedDuration: {
      value: 'time budget',
      updated: 'Changed the time budget'
    },
    projectTypeId: 'project type id',
    projectType: 'projec type',
    projectStatusId: 'project status id',
    projectStatus: 'project status',
    companyId: 'company id',
    company: 'company',
    salesType: 'sales type',
    salesTypeId: 'sales type id',
    members: 'project members',
    name: 'name',
    description: 'description',
    startDate: 'start date',
    dueDate: 'due date',
    tags: 'tags',
    trackedDuration: 'total tracked time',
    projectmember: 'project member'
  }
}

export function getProjectPropertyNames(): Object {
  return {
    plannedduration: q.translations.ActivityLogComponent.entityProperties.plannedDuration,
    projecttypeid: q.translations.ActivityLogComponent.entityProperties.projectTypeId,
    projectstatusid: q.translations.ActivityLogComponent.entityProperties.projectStatusId,
    companyid: q.translations.ActivityLogComponent.entityProperties.company,
    name: q.translations.ActivityLogComponent.entityProperties.name,
    description: q.translations.ActivityLogComponent.entityProperties.description,
    startdate: q.translations.ActivityLogComponent.entityProperties.startDate,
    duedate: q.translations.ActivityLogComponent.entityProperties.dueDate,
    tags: q.translations.ActivityLogComponent.entityProperties.tags,
    trackedduration: q.translations.ActivityLogComponent.entityProperties.trackedDuration,
    projectmember: q.translations.ActivityLogComponent.entityProperties.projectMember,
    timebudget: q.translations.ActivityLogComponent.entityProperties.timeBudget
  }
}

export function getProjectPropertyArticles(): Object {
  return {
    project: q.translations.common.articles.das,
    plannedduration: q.translations.common.articles.den,
    projecttypeid: q.translations.common.articles.den,
    projectstatusid: q.translations.common.articles.den,
    companyid: q.translations.common.articles.den,
    name: q.translations.common.articles.den,
    description: q.translations.common.articles.die,
    startdate: q.translations.common.articles.das,
    duedate: q.translations.common.articles.die,
    tags: q.translations.common.articles.den,
    trackedduration: q.translations.common.articles.die,
    projectmember: q.translations.common.articles.das,
    projecttag: q.translations.common.articles.den,
    timebudget: q.translations.common.articles.das
  }
}

/**
 * Is Array of strings
 * @param value
 */
export function isArrayOfStrings(value: any[]): boolean {
  return value?.every?.(item => typeof item === 'string')
}

/**
 * Map tags from Array of strings to Tag[]
 * @param tags: string[]
 */
export function mapTagsArr(tags: any[]): Tag[] {
  return tags?.map(tag => ({ name: tag }))
}
