import { CustomFieldTypeValue } from '@awork/features/project/models/custom-field-type.model'
import { DropdownOption } from '@awork/_shared/models/select-option.model'
import { BadgeColor } from '@awork/_shared/types/color'

export interface ICustomFieldDefinition {
  id: string
  name: string
  type: CustomFieldTypeValue
  selectionOptions?: ICustomFieldSelectionOption[]
  entity: 'task' | 'project'
  linkedProjectIds?: string[]
}

export interface ICustomFieldSelectionOption {
  id: string
  value: string
  color?: BadgeColor
  order: number
}

/**
 * Represents the custom field available in the Workspace.
 * In the case of type Select, it also has the possible selection values.
 */
export class CustomFieldDefinition implements ICustomFieldDefinition {
  id: string
  name: string
  type: CustomFieldTypeValue
  selectionOptions?: ICustomFieldSelectionOption[]
  entity: 'task' | 'project'
  linkedProjectIds?: string[]

  constructor(customFieldDefinition: Partial<ICustomFieldDefinition>) {
    Object.assign(this, customFieldDefinition)
  }

  /**
   * Maps the selection options to DropdownOptions
   */
  get selectOptions(): DropdownOption[] {
    return this.selectionOptions
      ?.sort((a, b) => a.order - b.order)
      .map(value => ({ label: value.value, value: value.id, dotColor: value.color }))
  }
}
