import { Task } from '@awork/features/task/models/task.model'
import { DropdownOption } from '@awork/_shared/models/select-option.model'

export type ValidTaskStatusType = 'todo' | 'progress' | 'review' | 'stuck' | 'done'

export const CIRCLE_WITHOUT_COLOR = 'circle_without_color'

/**
 * The task status in awork
 */
export interface ITaskStatusType {
  key: string
  value: string
  order?: number
  color: string
  icon: string
}

export class TaskStatus {
  id: string
  name: string
  type: ValidTaskStatusType
  order?: number
  projectId?: string
  projectTemplateId?: string
  tasks?: Task[]
  updatedOn?: Date
  colId?: string // random guid which is used for the column drag and drop in the kanban board
  icon?: string

  constructor(taskStatus: any) {
    Object.assign(this, taskStatus)
  }

  get color(): string {
    if (this.isWithoutColor) {
      return 'fog'
    }

    const statusType = TaskStatus.types.find(type => type.key === this.type)

    return statusType ? statusType.color : 'steel'
  }

  get taskCount(): number {
    if (this.tasks) {
      return this.tasks.length
    }
    return 0
  }

  get iconName(): string {
    if (this.icon && this.icon.trim() !== '') {
      return this.icon
    } else {
      const defaultStatus = TaskStatus.types.find(sts => sts.key === this.type)
      if (defaultStatus) {
        return defaultStatus.icon
      }
    }
    return ''
  }

  get isWithoutColor(): boolean {
    return this.iconName === CIRCLE_WITHOUT_COLOR
  }

  static get types(): ITaskStatusType[] {
    return [
      {
        key: 'todo',
        icon: CIRCLE_WITHOUT_COLOR,
        value: q.translations.TaskStatusModel.todo,
        order: 1,
        color: 'blue'
      },
      {
        key: 'progress',
        icon: 'arrow_forward',
        value: q.translations.TaskStatusModel.progress,
        order: 2,
        color: 'yellow'
      },
      {
        key: 'review',
        icon: 'review',
        value: q.translations.TaskStatusModel.review,
        order: 3,
        color: 'purple'
      },
      {
        key: 'stuck',
        icon: 'remove_circle_outline',
        value: q.translations.TaskStatusModel.stuck,
        order: 4,
        color: 'red'
      },
      {
        key: 'done',
        icon: 'done',
        value: q.translations.TaskStatusModel.done,
        order: 5,
        color: 'green'
      }
    ]
  }

  static get typeOptions(): DropdownOption[] {
    return TaskStatus.types.map(
      type =>
        ({
          value: type.key,
          dotColor: type.color,
          label: type.value
        }) as DropdownOption
    )
  }

  static getTaskStatusOptions(taskStatuses: TaskStatus[], filter?: TaskStatus): { value: string; label: string }[] {
    let options = taskStatuses.map(status => {
      return {
        value: status.id,
        label: status.name
      }
    })

    if (filter) {
      options = options.filter(status => status.value !== filter.id)
    }

    return options
  }

  static sortByOrder(taskStatuses: TaskStatus[]): TaskStatus[] {
    return taskStatuses.sort((prevStatus, status) => {
      const aOrder = prevStatus.order
      const bOrder = status.order

      if ((aOrder || aOrder === 0) && (bOrder || bOrder === 0)) {
        return aOrder - bOrder
      } else {
        return 999
      }
    })
  }

  colorClass(): string {
    const color = this.color ? this.color : 'fog'
    return `aw-bg--${color} aw-b--${color}`
  }

  /**
   * Gets the order used for progress
   */
  get progressOrder(): number {
    switch (this.type) {
      case 'done':
        return 1
      case 'stuck':
        return 2
      case 'review':
        return 3
      case 'progress':
        return 4
      case 'todo':
        return 5
    }
  }

  /**
   * Gets the translated type name
   * @returns {string}
   */
  get typeName(): string {
    return TaskStatus.types.find(type => type.key === this.type).value
  }
}

export class TaskStatusColumn extends TaskStatus {
  hideOldDoneTasks?: boolean
}
