<div class="user-avatar-circle">

  <!-- no user -->
  <ng-container *ngIf="users.length === 0">
    <aw-user-avatar [showText]="false" />
  </ng-container>

  <!-- 1-n users -->
  <div *ngIf="users.length > 0" class="user-avatar-circle__users" [ngClass]="getPositionClass()">
    <ng-container *ngFor="let user of users; let i = index">
      <aw-user-avatar *ngIf="i <= 3" [user]="user" [showText]="false" />
    </ng-container>

    <div *ngIf="users.length > 4" class="user-avatar-circle__more">{{ '+' + (users.length - 3) }}</div>
  </div>

  <!-- event icon -->
  <div class="user-avatar-circle__event" [ngClass]="'aw-2-bg--' + notification?.color">
    <i class="aw-2-icn aw-2-icn--xs aw-2-pos--center-all" [ngClass]="'aw-2-txt--' + notification?.color">{{ notification?.icon }}</i>
  </div>
</div>
