import { Criterion } from './types'
import { inject } from '@angular/core'
import { Team, TeamMember, TeamMembers } from '@awork/_shared/feature-flags/feature-flag.teams'
import { UserQuery } from '@awork/features/user/state/user.query'
import { isAllowedAworkTestEmail, isAworkTestEmail } from '../is-awork-test-email'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'

/**
 * Returns true if the current user is the creator of the current workspace
 * @returns {boolean}
 */
export const isWorkspaceCreatorCriterion: Criterion = () => {
  const userQuery = inject(UserQuery)
  const workspaceQuery = inject(WorkspaceQuery)

  const currentUser = userQuery.getCurrentUser()
  const currentWorkspace = workspaceQuery.getCurrentWorkspace()

  return currentWorkspace.createdBy === currentUser.id
}

/**
 * Returns true if the current user's email is included in the list of allowed emails
 * @param {string[]} allowedEmails
 * @returns {boolean}
 */
export const isEmailAllowedCriterion =
  (allowedEmails: string[]): Criterion =>
  () => {
    const userQuery = inject(UserQuery)
    const currentUser = userQuery.getCurrentUser()

    return allowedEmails.includes(currentUser.email)
  }

/**
 * Returns true if the current user's email is an awork test email
 * @returns {boolean}
 */
export const isAworkTestEmailCriterion: Criterion = () => {
  const userQuery = inject(UserQuery)
  const currentUser = userQuery.getCurrentUser()

  const currentUserEmail = currentUser.email
  return isAworkTestEmail(currentUserEmail)
}

/**
 * Returns true if the current user's email is included in the list of allowed team members
 * @param {TeamMember[]} allowedTeamMembers
 * @returns {boolean}
 */
export const isAworkTestEmailAllowedCriterion =
  (allowedTeamMembers: TeamMember[]): Criterion =>
  () => {
    const userQuery = inject(UserQuery)
    const currentUser = userQuery.getCurrentUser()

    const currentUserEmail = currentUser.email
    return isAllowedAworkTestEmail(currentUserEmail, allowedTeamMembers)
  }

export const isTeamAllowedCriterion =
  (allowedTeams: Team[]): Criterion =>
  () => {
    const allowedNames = allowedTeams.flatMap(team => TeamMembers[team])
    return isAworkTestEmailAllowedCriterion(allowedNames)()
  }
