import { getType } from './utils/typeUtils';
/**
 * Iterate over source and affect its sub values into destination, recursively.
 * If the source and destination can't be merged, return source.
 */
export function mergeInto(destination, source, circularReferenceChecker) {
  if (circularReferenceChecker === void 0) {
    circularReferenceChecker = createCircularReferenceChecker();
  }
  // ignore the source if it is undefined
  if (source === undefined) {
    return destination;
  }
  if (typeof source !== 'object' || source === null) {
    // primitive values - just return source
    return source;
  } else if (source instanceof Date) {
    return new Date(source.getTime());
  } else if (source instanceof RegExp) {
    var flags = source.flags ||
    // old browsers compatibility
    [source.global ? 'g' : '', source.ignoreCase ? 'i' : '', source.multiline ? 'm' : '', source.sticky ? 'y' : '', source.unicode ? 'u' : ''].join('');
    return new RegExp(source.source, flags);
  }
  if (circularReferenceChecker.hasAlreadyBeenSeen(source)) {
    // remove circular references
    return undefined;
  } else if (Array.isArray(source)) {
    var merged_1 = Array.isArray(destination) ? destination : [];
    for (var i = 0; i < source.length; ++i) {
      merged_1[i] = mergeInto(merged_1[i], source[i], circularReferenceChecker);
    }
    return merged_1;
  }
  var merged = getType(destination) === 'object' ? destination : {};
  for (var key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      merged[key] = mergeInto(merged[key], source[key], circularReferenceChecker);
    }
  }
  return merged;
}
/**
 * A simplistic implementation of a deep clone algorithm.
 * Caveats:
 * - It doesn't maintain prototype chains - don't use with instances of custom classes.
 * - It doesn't handle Map and Set
 */
export function deepClone(value) {
  return mergeInto(undefined, value);
}
export function combine() {
  var sources = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    sources[_i] = arguments[_i];
  }
  var destination;
  for (var _a = 0, sources_1 = sources; _a < sources_1.length; _a++) {
    var source = sources_1[_a];
    // Ignore any undefined or null sources.
    if (source === undefined || source === null) {
      continue;
    }
    destination = mergeInto(destination, source);
  }
  return destination;
}
function createCircularReferenceChecker() {
  if (typeof WeakSet !== 'undefined') {
    var set_1 = new WeakSet();
    return {
      hasAlreadyBeenSeen: function (value) {
        var has = set_1.has(value);
        if (!has) {
          set_1.add(value);
        }
        return has;
      }
    };
  }
  var array = [];
  return {
    hasAlreadyBeenSeen: function (value) {
      var has = array.indexOf(value) >= 0;
      if (!has) {
        array.push(value);
      }
      return has;
    }
  };
}
