import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, output } from '@angular/core'
import { WithGlobals } from '../../../classes/with-globals'
import { MainSize, Size } from '@awork/_shared/types/size'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { NgClass, NgIf } from '@angular/common'
import { BadgeColor, Color, DeprecatedColor } from '@awork/_shared/types/color'

export enum BadgeType {
  ghost = 'ghost',
  more = 'more',
  color = 'color'
}

@Component({
  selector: 'aw-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TooltipDirective, NgIf, NgClass]
})
export class BadgeComponent extends WithGlobals {
  // DI dependencies
  public elementRef = inject(ElementRef)

  // Inputs
  size = input<MainSize>(Size.m)
  label = input<string | number>()
  icon = input<string>()
  iconRight = input<boolean>(false)
  clickable = input<boolean>(false)
  propagateClick = input<boolean>(false)
  color = input<BadgeColor | DeprecatedColor>(Color.Steel)
  type = input<BadgeType>(BadgeType.color)
  maxWidth = input<string>('auto')
  removable = input<boolean>(false)
  removeTooltip = input<string>()
  editable = input<boolean>(false)
  renderAsHtml = input<boolean>(false)
  disabled = input<boolean>(false)
  monospace = input<boolean>(false)
  textSelectable = input<boolean>(false)

  // Computed
  badgeClasses = computed(() => {
    const color = this.color() || Color.Steel
    return [`dd-badge--${color}`, `dd-badge--${this.size()}`, `dd-badge--${this.type()}`]
  })
  iconSizeClass = computed(() => (this.size() === Size.s ? 'aw-2-icn--xs' : 'aw-2-icn--s'))

  // Outputs
  onClick = output<MouseEvent>()
  onRemove = output<MouseEvent>()
  onEdit = output<MouseEvent>()

  protected readonly commonTranslations = q.translations.common

  /**
   * Handles the click event of the badge item itself
   * @param {MouseEvent} event
   */
  onBadgeClick(event: MouseEvent): void {
    if (!this.propagateClick()) {
      event.stopPropagation()
    }
    if (this.clickable()) {
      this.onClick.emit(event)
    }
  }

  /**
   * Handles the click event of the remove icon
   * @param {MouseEvent} event
   */
  onRemoveClick(event: MouseEvent): void {
    event.stopPropagation()
    if (this.removable()) {
      this.onRemove.emit(event)
    }
  }

  /**
   * Handles the click event of the edit icon
   * @param {MouseEvent} event
   */
  onEditClick(event: MouseEvent): void {
    event.stopPropagation()
    if (this.editable()) {
      this.onEdit.emit(event)
    }
  }
}
