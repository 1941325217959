import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { Color } from '@awork/_shared/types/color'
import { InfoBoxColor, InfoBoxWidth } from './types'

@Component({
  selector: 'aw-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBoxComponent {
  @Input() title: string
  @Input() text: string
  @Input() color: InfoBoxColor = Color.Blue
  @Input() centerText: boolean
  @Input() htmlText: boolean
  @Input() inlineText = false

  @HostBinding('style.width')
  @Input()
  width: InfoBoxWidth = InfoBoxWidth.Auto
}
