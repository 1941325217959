import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { EntityProps, GenericEntity, Paging, SyncOptions } from '@awork/core/state/signal-store/types'

/**
 * Synchronize the store with the data fetched from the API
 * Add new entities, update existing entities, remove non-existing entities
 * @param {Entity[]} entitiesToSync - Entities coming from the API
 * @param {Entity[]} storedEntities - Query to get the entities to determine which entities to remove
 * @param {EntitySignalStore<Entity} store - Store to be synced
 * @param { SyncOptions} options
 */
export function syncStore<Entity extends GenericEntity>(
  entitiesToSync: Entity[],
  storedEntities: Entity[],
  store: EntitySignalStore<Entity>,
  options: SyncOptions<Entity> = { compareProp: 'resourceVersion' }
): void {
  removeDeletedEntities(entitiesToSync, storedEntities, store, options.paging)

  addNewEntities(entitiesToSync, storedEntities, store)

  updateChangedEntities(
    entitiesToSync,
    storedEntities,
    store,
    options.compareProp,
    options.keepProp,
    options.nullableProps
  )

  options.resetFunction?.()

  store.setLoading(false)
}

/**
 * Compares entities that come from the api with stored entities and removes the deleted entities from the store
 * @param {SyncedEntity[]} apiEntities
 * @param {SyncedEntity[]} storedEntities
 * @param {EntitySignalStore<Entity>} store
 * @param {Paging} paging - Fetched page options
 */
function removeDeletedEntities<Entity extends GenericEntity>(
  apiEntities: Entity[],
  storedEntities: Entity[],
  store: EntitySignalStore<Entity>,
  paging?: Paging
): void {
  let offsetStart: number, offsetEnd: number

  if (paging) {
    offsetStart = (paging.page - 1) * paging.pageSize
    offsetEnd = paging.page * paging.pageSize
  }

  const apiEntitiesMap = new Map<string, Entity>()
  apiEntities.forEach(entity => apiEntitiesMap.set(entity.id, entity))

  const deletedEntityIds: string[] = storedEntities
    .slice(offsetStart, offsetEnd)
    .reduce((deletedIds: string[], storedEntity) => {
      if (!apiEntitiesMap.has(storedEntity.id)) {
        deletedIds.push(storedEntity.id)
      }
      return deletedIds
    }, [])

  if (deletedEntityIds.length) {
    store.remove(deletedEntityIds, store.getStoreOptions().name + ': syncStore - removeDeletedEntities')
  }
}

/**
 * Compares entities that come from the api with stored entities and adds the new entities from the store
 * @param {Entity[]} apiEntities
 * @param {Entity[]} storedEntities
 * @param {EntitySignalStore<Entity>} store
 */
function addNewEntities<Entity extends GenericEntity>(
  apiEntities: Entity[],
  storedEntities: Entity[],
  store: EntitySignalStore<Entity>
): void {
  const storedEntitiesMap = new Map<string, Entity>()
  storedEntities.forEach(entity => storedEntitiesMap.set(entity.id, entity))

  const addedEntities = apiEntities.filter(entityToSync => !storedEntitiesMap.has(entityToSync.id))

  if (addedEntities.length) {
    store.upsertMany(addedEntities, store.getStoreOptions().name + ': syncStore - addNewEntities')
  }
}

/**
 * Compares entities that come from the api with stored entities and updates the changed entities from the store
 * @param {SyncedEntity[]} apiEntities
 * @param {SyncedEntity[]} storedEntities
 * @param {EntitySignalStore<Entity>} store
 * @param {EntityProps} compareProp
 * @param {EntityProps} keepProp
 * @param {EntityProps[]} nullableProps
 */
function updateChangedEntities<Entity extends GenericEntity>(
  apiEntities: Entity[],
  storedEntities: Entity[],
  store: EntitySignalStore<Entity>,
  compareProp: EntityProps<Entity> = 'resourceVersion',
  keepProp?: EntityProps<Entity>,
  nullableProps?: EntityProps<Entity>[]
): void {
  const storedEntitiesMap = new Map<string, Entity>()

  storedEntities.forEach(entity => storedEntitiesMap.set(entity.id, entity))

  apiEntities.forEach(entityToSync => {
    const storedEntity = storedEntitiesMap.get(entityToSync.id)
    if (storedEntity && isStoredEntityOutdated(storedEntity, entityToSync, compareProp)) {
      let newEntityToSync = entityToSync

      if (keepProp) {
        const keepValue = storedEntity?.[keepProp]
        newEntityToSync = { ...entityToSync, [keepProp]: keepValue }
      }

      nullableProps?.forEach(prop => {
        if (newEntityToSync[prop] === undefined) {
          newEntityToSync[prop] = null
        }
      })

      store.update(
        entityToSync.id,
        newEntityToSync,
        store.getStoreOptions().name + ': syncStore - updateChangedEntities'
      )
    }
  })
}

/**
 * Returns true when the api entity is newer than the stored entity
 * @param {Entity} storedEntity
 * @param {Entity} apiEntity
 * @param {EntityProps<Entity>} compareProp
 * @returns {boolean}
 */
export function isStoredEntityOutdated<Entity extends GenericEntity>(
  storedEntity: Entity,
  apiEntity: Entity,
  compareProp: EntityProps<Entity> = 'resourceVersion'
): boolean {
  if (!storedEntity) {
    return true
  }

  let fallbackCompareProp: EntityProps<Entity>
  let storedProp = storedEntity[compareProp]
  let apiProp = apiEntity[compareProp]

  // if resourceVersion is not supported in that entity, use updatedOn
  if (compareProp === 'resourceVersion' && !apiProp && !storedProp) {
    fallbackCompareProp = 'updatedOn' as EntityProps<Entity>
  }

  if (compareProp === 'updatedOn' || fallbackCompareProp === 'updatedOn') {
    const storedUpdatedOn = storedEntity['updatedOn']
    const apiUpdatedOn = apiEntity['updatedOn']

    storedProp =
      typeof storedUpdatedOn === 'string' ? (new Date(storedUpdatedOn) as Entity[EntityProps<Entity>]) : storedUpdatedOn

    apiProp = typeof apiUpdatedOn === 'string' ? (new Date(apiUpdatedOn) as Entity[EntityProps<Entity>]) : apiUpdatedOn
  }

  return apiProp > (storedProp || 0)
}
