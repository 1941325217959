import { TeamMember } from '@awork/_shared/feature-flags/feature-flag.teams'

const aworkTestEmailRegex = /^([a-zA-Z0-9]+)(\+[^@]+)?@awork\.(com|io)$/

export const isAworkTestEmail = (email: string): boolean => {
  return aworkTestEmailRegex.test(email)
}

export const isAllowedAworkTestEmail = (email: string, allowedTeamMembers: TeamMember[]): boolean => {
  const match = email.match(aworkTestEmailRegex)

  if (!match) {
    return false
  }

  // Check if the extracted name is in the list of allowed names
  const name = match[1]
  return allowedTeamMembers.includes(name as TeamMember)
}
