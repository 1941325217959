import { PermissionsState, PermissionsStore } from '@awork/features/workspace/state/permissions.store'
import { computed, Injectable, Signal } from '@angular/core'
import { Observable, map, filter, combineLatest } from 'rxjs'
import { UserPermissions } from '@awork/features/workspace/models/permissions.model'
import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'

@Injectable({ providedIn: 'root' })
export class PermissionsQuery extends SignalQuery<PermissionsState> {
  constructor(protected store: PermissionsStore) {
    super(store)
  }

  /**
   * Selects the User permissions
   * It emits only after the permissions have been fetched
   */
  selectUserPermissions(): Observable<UserPermissions> {
    return combineLatest([this.selectLoading(), this.select()]).pipe(
      filter(([loading]) => !loading),
      map(([_, userPermissions]) => new UserPermissions(userPermissions))
    )
  }

  /**
   * Gets the User permissions
   */
  getUserPermissions(): UserPermissions {
    const state = this.getValue()
    return state ? new UserPermissions(state) : null
  }

  /**
   * Gets the User permissions
   * @returns {Signal<UserPermissions>}
   */
  queryUserPermissions(): Signal<UserPermissions> {
    return computed(() => {
      const permissions = this.query()
      return new UserPermissions(permissions())
    })
  }
}
