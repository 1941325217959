import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core'
import { DynamicRefService } from '../../../../services/dynamic-ref-service/dynamic-ref.service'
import { AutocompleteOptionItem } from '../models/autocomplete-option-item.model'
import { TextFieldAutocompleteComponent } from '../components/text-field-autocomplete/text-field-autocomplete.component'
import { Subscription } from 'rxjs'
import { AutocompleteBaseComponent } from '../models/autocomplete-base.component'

@Directive({
  standalone: true,
  providers: [TextFieldAutocompleteComponent]
})
export class TextFieldAutocompleteDirective<T> implements OnDestroy, OnChanges {
  @Input({
    // Needed because the directive is used as HostDirective and the input with generic type shows a type error
    transform: (options: AutocompleteOptionItem<unknown>[]): AutocompleteOptionItem<T>[] =>
      options as AutocompleteOptionItem<T>[]
  })
  autocompleteOptions: AutocompleteOptionItem<T>[] = []
  @Input() preShowAutocompleteOptions: boolean
  @Input() maxShowOptions: number
  @Input() autocompletePopupType: typeof AutocompleteBaseComponent<T> = TextFieldAutocompleteComponent<T>

  @Output() onAutocompleteSelect: EventEmitter<string> = new EventEmitter<string>()

  private popupRef: ComponentRef<AutocompleteBaseComponent<T>>
  private popup: AutocompleteBaseComponent<T>
  private popupSubscription: Subscription

  constructor(
    private el: ElementRef,
    private dynRefSvc: DynamicRefService,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.autocompleteOptions) {
      return
    }

    if (!changes.autocompleteOptions.currentValue?.length) {
      this.destroyPopup()
      return
    }

    if (!this.popupRef) {
      this.initializePopup()
    }
  }

  ngOnDestroy(): void {
    this.destroyPopup()
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.handleKeyUp(event)
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.handleKeyDown(event)
  }

  @HostListener('focus', ['$event'])
  onFocus(): void {
    if (!this.popup?.options?.length && !this.preShowAutocompleteOptions && !this.autocompleteOptions.length) {
      return
    }

    this.initializePopup()

    if (this.preShowAutocompleteOptions && this.autocompleteOptions?.length) {
      this.popupRef.setInput(
        'options',
        this.autocompleteOptions.slice(0, this.maxShowOptions || this.autocompleteOptions.length)
      )
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent): void {
    const targetIsPartOfTextField = this.el.nativeElement.contains(event.relatedTarget)

    if (targetIsPartOfTextField) {
      return
    }

    this.destroyPopup()
  }

  /**
   * Gets the input element from the host element
   */
  private get inputElement(): HTMLInputElement {
    return this.el.nativeElement.querySelector('input')
  }

  /**
   * Initializes the popup component
   */
  private initializePopup(): void {
    if (this.popupRef) {
      return
    }

    ;[this.popupRef, this.popup] = this.dynRefSvc.create(this.autocompletePopupType)

    const hostElement = this.el.nativeElement
    const inputElement = hostElement.querySelector('input')

    if (!this.popup.el) {
      return
    }

    this.renderer.insertBefore(hostElement, this.popup.el.nativeElement, inputElement.nextSibling)

    this.popupSubscription = this.popup.onSelect.subscribe(value => {
      this.onAutocompleteSelect.emit(value)
      this.destroyPopup()
    })
  }

  /**
   * Destroys the popup component
   */
  private destroyPopup(): void {
    this.popupRef?.destroy?.()
    this.popupSubscription?.unsubscribe()
    this.popupRef = null
    this.popup = null
  }

  /**
   * Handles the KeyDown event
   * @param {KeyboardEvent} event
   */
  private handleKeyDown(event: KeyboardEvent): void {
    if (!this.popup?.options?.length) {
      return
    }

    switch (event.code) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault()
        this.popup.el.nativeElement.focus()
        break
      case 'Enter':
        const selectedIndex = this.popup.selectedIndex

        if (selectedIndex === undefined) {
          return
        }

        this.onAutocompleteSelect.emit(this.popup.options[selectedIndex]?.value)
        this.destroyPopup()

        event.preventDefault()
        event.stopPropagation()
        break
      default:
        this.inputElement.focus()
        break
    }
  }

  /**
   * Handles the KeyUp event
   * @param {KeyboardEvent} event
   */
  private handleKeyUp(event: KeyboardEvent): void {
    const selectedIndex = this.popup?.selectedIndex
    const options = this.popup?.options
    let newIndex: number

    switch (event.code) {
      case 'ArrowDown':
        if (!this.popup?.options?.length) {
          return
        }

        newIndex = selectedIndex !== undefined ? selectedIndex + 1 : 0
        this.popupRef.setInput('selectedIndex', newIndex % options.length)
        event.preventDefault()
        event.stopPropagation()
        break
      case 'ArrowUp':
        if (!this.popup?.options?.length) {
          return
        }

        newIndex = selectedIndex ? selectedIndex - 1 : options.length - 1
        this.popupRef.setInput('selectedIndex', newIndex % options.length)
        event.preventDefault()
        event.stopPropagation()
        break
      case 'Enter':
        break
      default:
        if (!this.popupRef) {
          return
        }

        this.popupRef.setInput('selectedIndex', undefined)

        const inputValue = (event.target as HTMLInputElement).value?.trim()

        if (!inputValue) {
          this.popupRef.setInput('options', [])
          return
        }

        const filteredOptions = this.filterOptions(inputValue)

        this.popupRef.setInput('options', filteredOptions.slice(0, this.maxShowOptions || filteredOptions.length))

        break
    }
  }

  /**
   * Filters the autocomplete options.
   * This method compares the input against all the compare options of the
   * autocomplete item. If any of the compare matches, the item is included.
   * If the input is exact to the option value, then the option is excluded.
   * @param {string} input
   * @returns {AutocompleteOptionItem<T>[]}
   */
  private filterOptions(input: string): AutocompleteOptionItem<T>[] {
    return this.autocompleteOptions.filter(option => {
      const compare = option.compare || [option.value]

      const isIncluded = compare.some(c => c.toLowerCase().includes(input.toLowerCase()))
      const isNotExactValueMatch = option.value.toLowerCase() !== input.toLowerCase()

      return isIncluded && isNotExactValueMatch
    })
  }
}
