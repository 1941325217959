const SemVer = require('../classes/semver');
const inc = (version, release, options, identifier, identifierBase) => {
  if (typeof options === 'string') {
    identifierBase = identifier;
    identifier = options;
    options = undefined;
  }
  try {
    return new SemVer(version instanceof SemVer ? version.version : version, options).inc(release, identifier, identifierBase).version;
  } catch (er) {
    return null;
  }
};
module.exports = inc;