import { IUser, User } from '@awork/features/user/models/user.model'
import { IUserBase } from '@awork/features/user/models/user.model.base'

export class TaskAssignee extends User {
  plannedEffort?: number
  isDistributedPlannedEffort?: boolean

  constructor(user: IUser & IUserBase, caller: string) {
    super(user, caller)

    if (!user.hasOwnProperty('isDistributedPlannedEffort')) {
      this.isDistributedPlannedEffort = true
    }
  }
}
