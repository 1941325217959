import { ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class DynamicRefService {
  private _appViewRef: ViewContainerRef
  static instance: DynamicRefService

  set appViewRef(viewRef: ViewContainerRef) {
    this._appViewRef = viewRef
  }

  get appViewRef(): ViewContainerRef {
    return this._appViewRef
  }

  constructor() {
    DynamicRefService.instance = this
  }

  /**
   * Dynamically creates a new ComponentRef and Component for the provided Type.
   * @param {Type} component - the generic type of the Component to be created
   * @param {Object} options An object that contains extra parameters:
   *  * injector: the injector to use as the parent for the new component.
   * @returns {[ComponentRef<T>, T]} - the ComponentRef and Component that were created dynamically
   * @template T
   */
  create<T>(
    component: Type<T>,
    options?: {
      injector?: Injector
    }
  ): [ComponentRef<T>, T] {
    if (!this._appViewRef) {
      throw new Error('Cannot create ComponentRef without a ViewContainerRef')
    }

    const ref: ComponentRef<T> = this._appViewRef.createComponent<T>(component, options)

    if (!ref) {
      throw new Error(`Cannot create dynamically '${component.name}`)
    }

    return [ref, ref.instance]
  }
}
