import { Absence } from '@awork/features/user/models/absence.model'

export interface IUserBase {
  id: string
  firstName?: string
  lastName?: string
  hasImage?: boolean
  absences?: Absence[]
  createdOn?: Date
  updatedOn?: Date
}

export class UserBase implements IUserBase {
  id: string
  firstName?: string
  lastName?: string
  hasImage?: boolean
  absences?: Absence[]
  createdOn?: Date
  updatedOn?: Date

  constructor(user: IUserBase) {
    Object.assign(this, user)

    if (this.absences) {
      this.absences = this.absences.map(absence => Absence.mapAbsence(absence))
    }
  }
}
