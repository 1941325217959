import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { Injectable } from '@angular/core'
import { Account, AccountState } from '@awork/_shared/models/account.model'

function createInitialState(): AccountState {
  return {
    email: null,
    refreshToken: null,
    accessToken: null,
    refreshAt: null,
    passwordIsAutoGenerated: null,
    externalAccounts: []
  }
}

@Injectable({ providedIn: 'root' })
export class AccountStore extends SignalStore<AccountState> {
  constructor() {
    super({ name: 'account', initialState: createInitialState(), persistDelay: 1000 })
  }

  updateAccount(accountState: Partial<AccountState>): void {
    if (accountState.email) {
      const impersonatedAccount = new Account(accountState.email)
      accountState.isImpersonated = impersonatedAccount.isImpersonated
    }

    this.update(accountState)
  }
}
