import { ProjectStatus } from '@awork/features/project/models/project-status.model'
import { SelectOption } from '@awork/_shared/models/select-option.model'

/**
 * The project type in awork
 */
export class ProjectType {
  id: string
  name: string
  description: string
  icon: string
  isArchived: boolean
  projectStatuses?: ProjectStatus[]
  createdOn: string
  createdBy: string
  updatedOn: string
  updatedBy: string
  typeName = 'ProjectType'

  constructor(type: any) {
    Object.assign(this, type)
  }

  static isProjectType(projectType: any): projectType is ProjectType {
    return (projectType as ProjectType)?.typeName === 'ProjectType'
  }

  get urlName(): string {
    return decodeURIComponent(this.name.replace(/ /g, '-'))
  }

  static getProjectTypeOptions(projectTypes: ProjectType[], filter?: ProjectType): SelectOption[] {
    let options = projectTypes.map(types => ({
      value: types.id,
      label: types.name,
      icon: types.icon
    }))

    if (filter) {
      options = options.filter(type => type.value !== filter.id)
    }

    return options
  }
}
