import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core'
import { AutocompleteBaseComponent } from '../../models/autocomplete-base.component'

@Component({
  selector: 'aw-text-field-autocomplete',
  templateUrl: './text-field-autocomplete.component.html',
  styleUrls: ['./text-field-autocomplete.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgIf]
})
export class TextFieldAutocompleteComponent<T> extends AutocompleteBaseComponent<T> {
  translations = q.translations.TextFieldAutocompleteComponent

  constructor(public el: ElementRef) {
    super(el)
  }
}
