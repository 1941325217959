import { TaskList } from '@awork/features/task/models/task-list.model'
import { ChecklistItem, ChecklistItems } from '@awork/features/task/models/checklist-item'
import { AutomationRelativeDate } from '@awork/features/project/models/automation-action.model'
import { User } from '@awork/features/user/models/user.model'
import { UserQuery } from '@awork/features/user/state/user.query'

export interface IAutomationTask {
  id?: string
  name: string
  typeOfWorkId: string
  taskStatusId: string
  assigneeIds: string[]
  projectId: string
  description: string
  plannedDuration: number
  startDate: AutomationRelativeDate
  dueDate: AutomationRelativeDate
  userId: string
  tags: string[]
  lists: TaskList[]
  checklistItems: ChecklistItems
}

export class AutomationTask implements IAutomationTask {
  id: string
  name = ''
  typeOfWorkId = ''
  taskStatusId = ''
  assigneeIds = []
  projectId = ''
  description = ''
  plannedDuration = 0
  startDate = null
  dueDate = null
  userId = ''
  tags = []
  lists = []
  checklistItems = []

  constructor(task: IAutomationTask) {
    if (task) {
      this.name = task.name
      this.typeOfWorkId = task.typeOfWorkId
      this.taskStatusId = task.taskStatusId
      this.projectId = task.projectId
      this.description = task.description
      this.plannedDuration = task.plannedDuration
      this.startDate = task.startDate
      this.dueDate = task.dueDate
      this.userId = task.userId
      this.tags = task.tags
      this.lists = task.lists?.map(list => new TaskList(list))
      this.checklistItems = task.checklistItems?.map(checklistItem => new ChecklistItem(checklistItem))
      this.assigneeIds = task.assigneeIds
    } else {
      this.assigneeIds = []
    }
  }

  get assignees(): User[] {
    return UserQuery?.instance?.getUsersByIds(this.assigneeIds) || []
  }

  set assignees(users: User[]) {
    this.assigneeIds = users.length ? users.map(({ id }) => id) : []
  }

  get isPrivate(): boolean {
    return !this.projectId
  }

  /**
   * Gets whether or not task has assignees
   * @returns {boolean}
   */
  get hasAssignees(): boolean {
    return !!this.assigneeIds?.length
  }
}
