<article
  *ngIf="options.length"
  class="text-field-autocomplete"
  (mouseover)="selectedIndex = undefined"
>
  <header class="title">{{ translations.title }}</header>

  <ul>
    <li
      *ngFor="let option of options; let i = index"
      class="option"
      [class.selected]="i === selectedIndex"
      (click)="onSelect.emit(option.value)"
    >
      {{ option.label }}
    </li>
  </ul>
</article>
