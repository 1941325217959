import { Injectable } from '@angular/core'
import { Team } from '@awork/features/team/models/team.model'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'

@Injectable({ providedIn: 'root' })
export class TeamStore extends EntitySignalStore<Team> {
  constructor() {
    super({ name: 'team', entityConstructor: team => new Team(team) })
  }

  preAddEntity(team: Team): Team {
    delete team.users

    return team
  }

  preUpdateEntity(storedTeam: Readonly<Team>, updatedTeam: Team): Team {
    const teamToStore = { ...updatedTeam }
    delete teamToStore.users

    return teamToStore as Team
  }
}
