<div class="switch-btn" [class.switch-btn--s]="size() === sizes.s">
  <div
    class="switch-btn__container aw-2-flx aw-2-flx--row aw-2-flx--center"
    [class]="'switch-btn__container--' + direction()"
  >
    <div
      class="aw-2-pos--relative aw-2-d--inline-block"
      [awTooltip]="value() ? tooltipValues()?.on : tooltipValues()?.off"
      [actionTooltip]="true"
      (click)="clicked($event)"
    >
      <input type="checkbox" [formControl]="control()" />
      <label class="switch-btn__label"> </label>
    </div>
    @if (label()) {
      <span class="switch-btn__text" [innerHtml]="label()"></span>
    }
  </div>

  @if (errorText()) {
    <div class="aw-2-mt--s">
      <aw-info-box class="aw-2-d--block" [text]="errorText()" [color]="colors.Red" />
    </div>
  }
</div>
