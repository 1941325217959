/**
 * inspired by https://mathiasbynens.be/notes/globalthis
 */
export function getGlobalObject() {
  if (typeof globalThis === 'object') {
    return globalThis;
  }
  Object.defineProperty(Object.prototype, '_dd_temp_', {
    get: function () {
      return this;
    },
    configurable: true
  });
  // @ts-ignore _dd_temp is defined using defineProperty
  var globalObject = _dd_temp_;
  // @ts-ignore _dd_temp is defined using defineProperty
  delete Object.prototype._dd_temp_;
  if (typeof globalObject !== 'object') {
    // on safari _dd_temp_ is available on window but not globally
    // fallback on other browser globals check
    if (typeof self === 'object') {
      globalObject = self;
    } else if (typeof window === 'object') {
      globalObject = window;
    } else {
      globalObject = {};
    }
  }
  return globalObject;
}
