import { differenceInMinutes, isPast } from '@awork/_shared/functions/date-fns-wrappers'

export enum Plan {
  // ------------- Pricing plans: Version 1 --------------- //
  Internal = 'internalfree',
  Free = 'free',
  Standard = 'standard',
  Enterprise = 'enterprise',
  // ------------- Pricing plans: Version 2 --------------- //
  Team = 'team-2023',
  Business = 'business-2023',
  EnterpriseV2 = 'enterprise-2023',
  Connect = 'connect-2024'
}

export enum PlanTier {
  Free = 'free',
  Team = 'team',
  Business = 'business',
  Enterprise = 'enterprise',
  Connect = 'connect'
}

export enum PlanPeriod {
  Monthly = 1,
  Yearly = 12,
  BiYearly = 24
}

// PlanId options
export const FREE = 'q-free-new-1'
export const FREE_LEGACY = 'q-free-1'
export const STANDARD = 'q-standard-1'
export const CURRENT = 'awork-standard-1'
export const BUSINESS = 'business-2023-1'
export const CONNECT = 'connect-2024-1'

// Status options
export const IN_TRIAL = 'InTrial'
export const CANCELLED = 'Cancelled'
export const NON_RENEWING = 'NonRenewing'
export const ACTIVE = 'Active'

// Feature Restrictions
export enum FeatureRestrictions {
  Autopilot = 'autopilot',
  PrivateProjects = 'privateprojects',
  Teams = 'teams',
  Personio = 'personio',
  PlannerWorkload = 'plannerWorkload',
  TimeTracking = 'timeTracking',
  TimeTrackingDisabled = 'timeTrackingDisabled',
  AdvancedPermissions = 'advancedPermissions',
  TimeTrackingSettings = 'timeTrackingSettings',
  CustomSubdomain = 'customSubdomain',
  EnterpriseIntegrations = 'enterpriseIntegrations',
  DataImport = 'dataImport',
  ConnectSetting = 'connectSetting',
  CustomFields = 'customFields'
}

export const teamFeatureRestrictions = [
  FeatureRestrictions.Autopilot,
  FeatureRestrictions.PrivateProjects,
  FeatureRestrictions.Teams,
  FeatureRestrictions.Personio,
  FeatureRestrictions.PlannerWorkload,
  FeatureRestrictions.TimeTracking,
  FeatureRestrictions.AdvancedPermissions,
  FeatureRestrictions.TimeTrackingSettings,
  FeatureRestrictions.CustomFields
]

export const businessFeatureRestrictions = [
  FeatureRestrictions.CustomSubdomain,
  FeatureRestrictions.EnterpriseIntegrations,
  FeatureRestrictions.TimeTrackingDisabled
]

export const connectFeatureRestrictions = [
  ...businessFeatureRestrictions,
  FeatureRestrictions.ConnectSetting,
  FeatureRestrictions.DataImport
]

// Object model for the workspaces plan subscription
export interface IQSubscription {
  workspaceId: string
  planId: string
  contractEnd: Date
  status: string
  previousStatus: string
  remainingUsers: number
  remainingGuests: number
  bookedSeats: number
  usedUserSeats: number
  usedGuestSeats: number
  totalProjects: number
  trialEnd: Date
  usedPaidTrial: boolean
  referralCode?: string
  initialPlanId?: string
}

export class QSubscription {
  workspaceId: string
  planId: string
  contractEnd: Date
  status: string
  previousStatus: string
  remainingUsers: number
  remainingGuests: number
  bookedSeats: number
  usedUserSeats: number
  usedGuestSeats: number
  totalProjects: number
  trialEnd: Date
  usedPaidTrial: boolean
  referralCode?: string
  initialPlanId?: string

  constructor(subscription: IQSubscription) {
    Object.assign(this, subscription)

    if (this.contractEnd) {
      this.contractEnd = new Date(this.contractEnd)
    }

    if (this.trialEnd) {
      this.trialEnd = new Date(this.trialEnd)
    }
  }

  get isActive(): boolean {
    return this.status === ACTIVE
  }

  get isInTrialStatus(): boolean {
    return this.status === IN_TRIAL
  }

  /**
   * There are a couple of plan ids that are special cases and will always be in trial.
   * We need to do it this way, so no invoice is ever created for those internal free workspaces.
   */
  get isFixedTrialPlan(): boolean {
    return this.planId === 'q-internalfree-1'
  }

  get isCancelled(): boolean {
    if (this.isInternalPlan) {
      return false
    }
    return this.status && this.status === CANCELLED
  }

  get hasValidContract(): boolean {
    const isCancelled = this.isCancelled && (!this.previousStatus || this.previousStatus !== IN_TRIAL)
    const contractEnded = this.contractEnd && isPast(this.contractEnd)

    return !isCancelled && !contractEnded
  }

  get isFreePlan(): boolean {
    return this.planId && this.planId === FREE
  }

  get isFreeLegacyPlan(): boolean {
    return this.planId && this.planId === FREE_LEGACY
  }

  get isInternalPlan(): boolean {
    return this.planId?.includes(Plan.Internal)
  }

  get isPaidPlan(): boolean {
    return !this.isFreePlan && !this.isFreeLegacyPlan && !this.isBasicConnectPlan
  }

  get isEnterprisePlan(): boolean {
    return this.planId?.includes(Plan.Enterprise)
  }

  get isEnterpriseV2Plan(): boolean {
    return this.planId?.includes(Plan.EnterpriseV2)
  }

  get isBusinessPlan(): boolean {
    return this.planId?.includes(Plan.Business)
  }

  get isStandardPlan(): boolean {
    return this.planId?.includes(Plan.Standard)
  }

  get isTeamPlan(): boolean {
    return this.planId?.includes(Plan.Team)
  }

  get isBasicConnectPlan(): boolean {
    return this.planId === CONNECT
  }

  /**
   * Returns true if the first subscription plan was awork connect
   * @returns {boolean}
   */
  get isConnectInitialPlanId(): boolean {
    return this.initialPlanId === CONNECT
  }

  get isAnyFreePlan(): boolean {
    return this.isFreePlan || this.isFreeLegacyPlan
  }

  get isAnyEnterprisePlan(): boolean {
    return this.isEnterprisePlan || this.isEnterpriseV2Plan
  }

  get isBasicPlan(): boolean {
    return this.isTeamPlan || this.isAnyFreePlan || this.isBasicConnectPlan
  }

  get isPricingPlanV2(): boolean {
    return this.planId && (this.isBasicConnectPlan || this.isTeamPlan || this.isBusinessPlan || this.isEnterpriseV2Plan)
  }

  isFeatureEnabled(feature: FeatureRestrictions): boolean {
    if (this.isAnyEnterprisePlan || !this.isPricingPlanV2 || this.status === IN_TRIAL) {
      return true
    }

    if (this.isBusinessPlan) {
      return !businessFeatureRestrictions.includes(feature)
    }

    return !teamFeatureRestrictions.includes(feature)
  }

  get period(): number {
    if (this.planId && this.planId.split('-').length > 2) {
      const planIdParts = this.planId.split('-')

      return Number(planIdParts[planIdParts.length - 1])
    } else {
      return 1
    }
  }

  /**
   * Gets the trial remaining days if the trial is used and the current plan is paid
   */
  get trialEndDays(): number {
    if (this.usedPaidTrial && !this.isFreePlan && !this.isFreeLegacyPlan && this.trialEnd) {
      const minutesLeft = differenceInMinutes(this.trialEnd, new Date())
      return minutesLeft > 0 ? Math.ceil(minutesLeft / 60 / 24) : 0
    } else {
      return null
    }
  }

  /**
   * Get whether the workspace is in trial
   */
  get isInTrial(): boolean | null {
    if (!this.status) {
      return null
    }

    return this.isPaidPlan && this.trialEndDays > 0 && this.status === IN_TRIAL
  }

  /**
   * Determines if the the workspace can use the premium trial
   */
  get isTrialEligible(): boolean {
    return (this.isFreePlan || this.isBasicConnectPlan) && !this.trialEnd
  }

  /**
   * Determines if the workspace can use a referral code
   **/
  get isReferralEligible(): boolean {
    const hasReferralCode = !!this.referralCode
    return (
      this.isFreePlan || this.status === IN_TRIAL || this.isBasicConnectPlan || (this.period === 1 && hasReferralCode)
    )
  }

  /**
   * Gets the number of maximum projects available
   * Returns null if the plan has unlimited projects
   * @returns {number}
   **/
  get projectsLimit(): number {
    if (!this.isBasicConnectPlan && !this.usedBasicConnectTrial) {
      return null
    }

    return this.usedBasicConnectTrial ? 2 : 1
  }

  /**
   * Determines if the basic connect free trial was used
   * @returns {boolean}
   */
  get usedBasicConnectTrial(): boolean {
    return (this.isBasicConnectPlan && this.usedPaidTrial) || this.isBasicConnectInTrial
  }

  /**
   * Determines if the basic connect is in trial
   * @returns {boolean}
   */
  get isBasicConnectInTrial(): boolean {
    return this.isBusinessPlan && this.isInTrialStatus && this.initialPlanId === CONNECT
  }

  /**
   * Determines if the basic connect trial (Business Trial) is expired
   * @returns {boolean}
   */
  get isBasicConnectTrialExpired(): boolean {
    return (
      this.isBusinessPlan &&
      this.status === CANCELLED &&
      this.previousStatus === IN_TRIAL &&
      this.initialPlanId === CONNECT
    )
  }

  /**
   * Determines if the plan has a volume discount
   */
  get isVolumeDiscountPlan(): boolean {
    return this.planId?.includes('-volume-')
  }

  /**
   * Gets a plan id based on a plan and a period
   * @param {Plan} plan
   * @param {number} period
   * @returns {string}
   */
  getPlanIdByPeriod(plan: Plan, period: number): string {
    const planPrefix = this.planId.includes('awork-') ? 'awork' : 'q'

    if (plan === Plan.Free) {
      return `${planPrefix}-${plan}-1`
    }

    if (this.isPricingPlanV2) {
      return `${plan}-${period}`
    }

    return `${planPrefix}-${plan}-${period}`
  }

  /**
   * Gets the current plan based on the plan from the plan Id
   * @returns {Plan}
   */
  getCurrentPlan(): Plan {
    const planIdParts = this.planId.split('-')

    return (this.isPricingPlanV2 ? `${planIdParts[0]}-${planIdParts[1]}` : planIdParts[1]) as Plan
  }

  /**
   * Gets the plan ID based on an alias
   * @param {string} alias
   * @returns {string}
   */
  static getPlanIdByAlias(alias: string): string {
    switch (alias) {
      case 'standard':
      case 'premium':
        return STANDARD
      case 'free':
      default:
        return FREE
    }
  }

  /**
   * Gets the plan name based on a plan Id.
   *
   * For new plans, the structure is {planName}-{version}-{period} (e.g. team-2023-1).
   * For the old plans, the structure is {prefix}-{planName}-{period} (e.g. awork-standard-1).
   * @param {string} planId
   * @returns {string}
   */
  static getPlanName(planId: string): string {
    if (!planId.includes('-')) {
      return planId
    }

    const planIdParts = planId.split('-')
    const prefixes = ['awork', 'q']

    const hasPrefix = prefixes.includes(planIdParts[0])

    return hasPrefix ? planIdParts[1] : `${planIdParts[0]}-${planIdParts[1]}`
  }

  /**
   * Gets the plan period based on a plan Id.
   *
   * For new plans, the structure is {planName}-{version}-{period} (e.g. team-2023-1).
   * For the old plans, the structure is {prefix}-{planName}-{period} (e.g. awork-standard-1).
   * @param {string} planId
   * @returns {number}
   */
  static getPlanPeriod(planId: string): number {
    if (!planId.includes('-')) {
      return 0
    }

    return parseInt(planId.split('-').pop())
  }

  /**
   * Gets the plan tier based on a plan Id.
   * @param {string} planId
   */
  static getPlanTier(planId: string): PlanTier {
    if (planId.includes('free')) {
      return PlanTier.Free
    }

    if (planId.includes('team') || planId === Plan.Connect) {
      return PlanTier.Team
    }

    if (planId.includes('business') || planId.includes('standard') || planId.includes('buhl')) {
      return PlanTier.Business
    }

    if (planId.includes('enterprise')) {
      return PlanTier.Enterprise
    }

    if (planId.includes('connect')) {
      return PlanTier.Connect
    }

    return PlanTier.Team
  }

  /**
   * Determines the required plan tier of a feature
   * @param {FeatureRestrictions} feature
   */
  static getFeatureRequiredPlanTier(feature: FeatureRestrictions): PlanTier {
    if (teamFeatureRestrictions.includes(feature)) {
      return PlanTier.Business
    }

    if (businessFeatureRestrictions.includes(feature)) {
      return PlanTier.Enterprise
    }

    if (connectFeatureRestrictions.includes(feature)) {
      return PlanTier.Business
    }

    return null
  }
}

export interface QSubscriptionDetails {
  workspaceName: string
  nextBilling: Date
  upcomingChanges: QSubscriptionUpcomingChanges
  workspaceId: string
  planId: string
  contractEnd: Date
  status: string
  bookedSeats: number
  mrr?: number
  discount?: number
}

export interface QSubscriptionUpcomingChanges {
  planId: string
  bookedSeats: number
}
