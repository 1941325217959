import { trigger, state, style, transition, animate } from '@angular/animations'

export const slideOpen = trigger('slideOpen', [
  state('true', style({ height: '*' })),
  state('false', style({ height: 0, overflow: 'hidden' })),
  transition('true <=> false', animate('0.2s ease-in-out'))
])

export const slideOpenNgIf = trigger('slideOpenNgIf', [
  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    animate('.2s ease-out', style({ height: '0', overflow: 'hidden' }))
  ]),
  transition(':enter', [style({ height: '0', overflow: 'hidden' }), animate('.2s ease-out', style({ height: '*' }))])
])

export const slideQuickOpenNgIf = trigger('slideQuickOpenNgIf', [
  transition(':leave', [
    style({ height: '*', overflow: 'hidden' }),
    animate('.01s ease-out', style({ height: '0', overflow: 'hidden' }))
  ]),
  transition(
    ':enter',
    [style({ height: '0', overflow: 'hidden' }), animate('{{ timing }}s ease-out', style({ height: '*' }))],
    { params: { timing: '.1' } }
  )
])

export const slideOpenVerticalNgIf = trigger('slideOpenVerticalNgIf', [
  transition(':leave', [
    style({ width: '*', overflow: 'hidden' }),
    animate('.2s ease-out', style({ width: '0', overflow: 'hidden' }))
  ]),
  transition(':enter', [style({ width: '0', overflow: 'hidden' }), animate('.2s ease-out', style({ width: '*' }))])
])
