import { Directive, ElementRef, Input, AfterViewInit, Inject, OnChanges, SimpleChanges } from '@angular/core'

@Directive({
  selector: '[awAutoFocus]',
  standalone: true
})
export class AutoFocusDirective implements AfterViewInit, OnChanges {
  @Input() autoFocus: boolean
  @Input() forceFocus: boolean // Focus the element even if it has a value

  constructor(@Inject(ElementRef) private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.setFocus()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoFocus?.firstChange) {
      return
    }

    if (!changes.autoFocus?.previousValue && changes.autoFocus?.currentValue) {
      this.setFocus()
    }
  }

  /**
   * Sets the focus on the element
   */
  private setFocus(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement
      if (element && (!element.value || this.forceFocus)) {
        this.elementRef.nativeElement.focus()
      }
    }, 100)
  }
}
