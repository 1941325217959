export function translationsTestProxy(): QTranslations {
  const target = {
    toString: () => 'test',
    replace: () => 'test',
    [Symbol.toPrimitive]: () => 'test',
    toLowerCase: () => 'test'
  }

  const handler = {
    get: (obj, prop) => {
      return prop in obj ? obj[prop] : translationsTestProxy()
    }
  }

  return new Proxy(target, handler) as any
}

export function replaceTestNoOp(msg: string, _: { [x: string]: string | number }): string {
  return msg
}
