/* eslint-disable max-len */

/**
 * Global regex validator for email addresses (HTML5 standard)
 */
export const emailRegex: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/

/**
 * Global regex validator for URLs
 */
export const urlRegex: RegExp = /^(\w+):\/\/(.*)/

/**
 * Global regex validator for URLs within text
 */
export const textUrlRegex: RegExp =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi

/**
 * Global regex validator for Server Message Block URLs within text
 */
export const smbUrlRegex: RegExp =
  /(?:(?:smb):\/\/.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi

/**
 * Global regex for URLs within text including any protocol
 */
export const textUrlRegexExtended: RegExp =
  /(?:(?:[a-z]*):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:;,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[A-Z0-9+&@#\/%=~_|;$])/gi

/**
 * Global regex for GUID
 */
export const guidRegex: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
export const guidRegexString: string = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

/**
 * Guid regex for mentions: Fetching any id
 */
export const guidRegexForMentions: RegExp =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

/**
 * Global regex to validate float numbers
 */
export const floatRegex: RegExp = /^[0-9]+((.|,)[0-9]{1,2})?$/

/**
 * Global regex for mention elements
 */
export const mentionElementRegex: RegExp = /<span class="mention" [^>]*>[^>]*<\/span>/g

/**
 * Global regex for File Tags
 */
export const fileTagRegex: RegExp = /(?:\~\[fileId\:)([\s\S]*?)(?=\])\]/g

/**
 * Global regex for mentionedUser
 */
export const userMentionRegex: RegExp = /(?:\~\[userId\:)([\s\S]*?)(?=\])\]/g

/**
 * Global regex for mentionedTeam
 */
export const teamMentionRegex: RegExp = /(?:\~\[teamId\:)([\s\S]*?)(?=\])\]/g

/**
 * File icon Component Regex
 */
export const fileIconComponentRegex: RegExp = /<aw-file-icon[^>]*[^>]*>[^~]*?<\/aw-file-icon>/g

/**
 * Emoji regex validations
 */
export const emojiRegex: RegExp =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

/**
 * Device category regex
 */
export const mobileRegex: RegExp =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/gim
export const tabletRegex: RegExp =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/gim

/**
 * Opening HTML tags regex (<p>, <a>, <h1>, ecc...)
 * It do not match closing and self-closing tags
 */
export const openingHtmlTagsRegex = /<([a-z]+)(?![^>]*\/>)[^>]*>/

export const fileTagElRegex: RegExp = /<span class="file-tag[^>]*>[\s\S]*<\/span>/g
