export interface ITaskListBase {
  id: string
  name: string
  order: number
  orderOfTask?: number
  collapsed: boolean
  shown: boolean
  projectId?: string
  userId?: string
  updatedOn?: Date
  isSaving?: boolean
  isArchived?: boolean
  updatedBy: string
  createdOn?: Date
  createdBy?: Date
}

export class TaskListBase implements ITaskListBase {
  id: string
  name: string
  order: number
  orderOfTask?: number
  collapsed = false
  shown = true
  projectId?: string
  userId?: string
  updatedOn?: Date
  isSaving?: boolean
  isArchived?: boolean
  updatedBy: string
  createdOn?: Date
  createdBy?: Date
  typeName = 'TaskList'

  constructor(taskList: Partial<ITaskListBase>) {
    Object.assign(this, taskList)

    if (this.updatedOn) {
      this.updatedOn = new Date(this.updatedOn)
    }
  }
}
