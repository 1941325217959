import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { Injectable } from '@angular/core'
import { apiEndpoint } from '@awork/environments/environment'
import { map, Observable } from 'rxjs'
import { EventStore } from '@awork/_shared/state/event.store'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { EntityType, IUserActivity, IUserTimeTrackingBehavior } from '@awork/_shared/services/q-event-service/types'

@Injectable({ providedIn: 'root' })
export class QEventService {
  private readonly statsUrl

  constructor(
    private apiClient: ApiClient,
    private eventStore: EventStore
  ) {
    this.statsUrl = `${apiEndpoint}/me/stats`
  }

  /**
   * Makes an API call to register an entity opened event
   * @param {EntityType} entityType
   * @param {string} entityId
   * @returns {Observable<void>}
   */
  trackEntityOpenedEvent(entityType: EntityType, entityId: string): Observable<void> {
    return this.apiClient.post<void>(this.statsUrl, {
      entityId,
      entityType,
      accessedOn: new Date(),
      event: 'Entity Opened'
    })
  }

  /**
   * Makes an API call to register an app accessed event
   * @returns {Observable<void>}
   */
  trackAppAccessedEvent(): Observable<void> {
    return this.apiClient.post<void>(this.statsUrl, {
      accessedOn: new Date(),
      event: 'App Accessed'
    })
  }

  /**
   * Returns the first tracked user activity for a given date
   */
  getFirstSeenTimestamp(date: Date): Observable<IUserActivity> {
    return this.apiClient.get<IUserActivity>(`${apiEndpoint}/me/stats/workhours?date=${date.toISOString()}`).pipe(
      map(res => {
        this.eventStore.updateEvent('firstLogin', res.start)
        return res
      })
    )
  }

  /**
   * Makes an API call to track the specified event
   * @param {TrackingEvent} event
   */
  trackEvent(event: TrackingEvent): void {
    this.apiClient
      .post<void>(this.statsUrl, {
        accessedOn: new Date(),
        event
      })
      .subscribe()
  }

  /**
   * Makes an API call to get the time tracking behavior of the user
   * @returns {Observable<boolean>} - True if the user uses start stop
   */
  fetchUserTimeTrackingBehavior(): Observable<boolean> {
    return this.apiClient.get<IUserTimeTrackingBehavior>(`${this.statsUrl}/timetracking`).pipe(
      map(timeTrackingBehavior => {
        this.eventStore.updateEvent('usesStartStop', timeTrackingBehavior.usesStartStop)
        return timeTrackingBehavior.usesStartStop
      })
    )
  }
}
