import { SelectOption } from '@awork/_shared/models/select-option.model'

/**
 * The type of work model
 */

interface IWorkType {
  id: string
  name: string
  icon: string
  description: string
  isArchived: boolean
  externalProjectIds?: string[]
}

export class WorkType {
  id: string
  name: string
  icon: string
  description: string
  isArchived: boolean
  updatedOn?: Date
  typeName = 'WorkType'
  externalProjectIds?: string[] // Only for work types that are linked to external projects

  constructor(type: IWorkType) {
    Object.assign(this, type)
  }

  static isWorkType(workType: any): workType is WorkType {
    return (workType as WorkType)?.typeName === 'WorkType'
  }

  /**
   * Create an empty type of work with default values
   * @returns {WorkType}
   */
  static createEmpty() {
    return new WorkType({
      isArchived: false
    } as IWorkType)
  }

  static getWorkTypeOptions(workTypes: WorkType[], filter?: WorkType): SelectOption[] {
    let options = workTypes.map(types => ({
      value: types.id,
      label: types.name,
      icon: types.icon
    }))

    if (filter) {
      options = options.filter(type => type.value !== filter.id)
    }

    return options
  }
}
