import replace from '@awork/_shared/functions/replace'
import isTest from '@awork/_shared/functions/is-test'
import { ids } from '../../e2e'
import { getAssetImage } from '@awork/_shared/functions/get-asset-image'
import { assetsUrl } from '@awork/environments/environment'
import { replaceTestNoOp, translationsTestProxy } from '@awork/_shared/functions/translation'

/**
 * This class can be used to make global variables accessible to the
 * template of an extended class.
 */
export class WithGlobals {
  q: QGlobals = {
    translations: isTest() ? translationsTestProxy() : q.translations,
    assetsUrl
  }

  ids = ids

  translate = isTest() ? replaceTestNoOp : replace

  getAssetImage = getAssetImage
}
