import { debounceTime, filter, Observable, of, Subject, switchMap, takeWhile } from 'rxjs'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { ToastComponent } from '../../components/ui-help/toast/toast.component'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DynamicRefService } from '../dynamic-ref-service/dynamic-ref.service'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { ElectronService } from '@awork/_shared/services/electron-service/electron.service'
import { stripHTML } from '@awork/_shared/functions/string-operations'
import { INpsUserSetting, NpsSettingActionType } from '@awork/_shared/models/user-setting.model'
import { NpsToastComponent } from '../../../framework/components/nps-toast/nps-toast.component'
import { OpenDesktopLinksToastComponent } from '../../../framework/components/open-desktop-links-toast/open-desktop-links-toast.component'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { ToastAction } from '../../components/ui-help/toast/types'
import { TOAST_CLOSE_DELAY, ToastItem, ToastOptions } from './types'

@Injectable({ providedIn: 'root' })
export class ToastService {
  stackedToastlist: ToastItem[] = []
  actionToastRemoved = new Subject<void>()

  constructor(
    private dynRefSvc: DynamicRefService,
    private browserService: BrowserService,
    private electronService: ElectronService,
    private router: Router,
    private settingsQuery: SettingsQuery
  ) {}

  /**
   * Shows a new notification to the user (and hides the previous notification)
   * @param {string} message
   * @param {ToastOptions} options
   * @returns {Observable<ToastAction>}
   */
  show(
    message: string,
    options: ToastOptions = { type: 'info', persist: false, showRepeated: false }
  ): Observable<ToastAction> {
    // Prevent toasts with same messages
    const showToast =
      options.showRepeated || !this.stackedToastlist.some(toastList => toastList.toast.message === message)

    if ((message && message.trim().length > 0 && showToast) || !message) {
      if (this.electronService.isElectron && !this.electronService.isAppActive && options.type !== 'action') {
        return this.showNativeNotification(message, options)
      }

      // create toast which will automatically show up
      const [toastRef, toast] = this.dynRefSvc.create(ToastComponent)
      const id = new Date().getTime()
      toast.message = message
      // assign options to the toast
      toast.options = options

      document.body.appendChild(toast.el.nativeElement)

      // push toast to list
      this.stackedToastlist.push({ toastRef, toast, id })

      // subscribe to toast so actions can be performed
      toast.actionTriggered.subscribe(x => {
        if (x.action === 'action' && options.action) {
          options.context ? options.action(options.context) : options.action()
        }

        if (x.action === 'close' || !toast.isNotificationGroup()) {
          this.hide(id, true)
        }
      })

      // set time out when the toast should be hidden automatically
      if (!options.persist) {
        setTimeout(() => this.hide(id), TOAST_CLOSE_DELAY)
      }

      // recalculate positions of the stack
      this.recalculatePositions()

      return toast.actionTriggered
    }

    return of(null)
  }

  /**
   * Shows a native notification
   * @param {string} message
   * @param {ToastOptions} options
   * @returns {Observable<ToastAction>}
   */
  private showNativeNotification(message: string, options: ToastOptions): Observable<ToastAction> {
    const { title, subtitle } = this.getNotificationText(options, true)

    return this.electronService.showNotification(title, message || subtitle).pipe(
      switchMap(() => {
        if (options?.action) {
          options.context ? options.action(options.context) : options.action()
        } else if (
          options?.notification?.navigation &&
          (options.notification.groupingType === 'no-group' || options.notification.type !== 'reminder')
        ) {
          this.router.navigate(options.notification.navigation)
        }

        return of({ action: 'action' })
      })
    )
  }

  /**
   * Shows the nps rating toast
   * @param {INpsUserSetting} npsSetting
   * @returns {Observable<ToastAction>}
   */
  showNpsToast(npsSetting?: INpsUserSetting): Observable<ToastAction> {
    const [toastRef, toast] = this.dynRefSvc.create(NpsToastComponent)
    const id = new Date().getTime()

    toast.options = { type: 'info' }
    toast.npsRatingSetting = npsSetting || { lastActionType: NpsSettingActionType.dismissed }

    document.body.appendChild(toast.el.nativeElement)

    // close all other toasts
    this.stackedToastlist.forEach((toastItem, index) => {
      this.hide(toastItem.id, true)
    })
    // push toast to list
    this.stackedToastlist.unshift({ toastRef, toast, id })

    // subscribe to toast so actions can be performed
    toast.actionTriggered.subscribe(event => {
      if (event.action === 'close') {
        this.hide(id, true)
      }
    })

    this.recalculatePositions()

    return toast.actionTriggered
  }

  /**
   * Shows a toast message to open the desktop app for the first time
   * @returns {Observable<ToastAction>}
   */
  showOpenInDesktopAppToast(): Observable<ToastAction> {
    const [toastRef, toast] = this.dynRefSvc.create(OpenDesktopLinksToastComponent)
    const id = new Date().getTime()

    toast.options = { type: 'info' }
    toast.closeDelay = TOAST_CLOSE_DELAY

    document.body.appendChild(toast.el.nativeElement)

    // push toast to list
    this.stackedToastlist.unshift({ toastRef, toast, id })

    // subscribe to toast so actions can be performed
    toast.actionTriggered.subscribe(event => {
      if (event.action === 'close') {
        this.hide(id, true)
      }
    })

    this.recalculatePositions()

    return toast.actionTriggered
  }

  /**
   * Shows a new file upload notification
   * @param {FileUpload} fileUpload
   * @returns {Observable<ToastAction>}
   */
  showFileUploadProgress(fileUpload: FileUpload): Observable<ToastAction> {
    let fileUploadToast = this.stackedToastlist.find(toastList => toastList.toast.options.type === 'fileUpload')

    // Create the file upload toast if there is no other of this type
    if (!fileUploadToast) {
      const [toastRef, toast] = this.dynRefSvc.create(ToastComponent)
      const id = new Date().getTime()

      toast.options = {
        type: 'fileUpload'
      }

      // push toast to list
      fileUploadToast = { toastRef, toast, id }
      this.stackedToastlist.push(fileUploadToast)

      // subscribe to toast so actions can be performed
      toast.actionTriggered.subscribe(() => {
        this.hide(id, true)
      })
    }

    fileUploadToast.toast.addFileUpload(fileUpload)

    // recalculate positions of the stack
    this.recalculatePositions()

    return fileUploadToast.toast.actionTriggered
  }

  /**
   * Hides the current notification
   * @param {number} id - Id of the toast to be removed
   * @param {boolean} forceClose - true to remove the toast immediately
   */
  hide(id: number, forceClose = false): void {
    if (id) {
      // get item from list
      const itemToRemove = this.stackedToastlist.find(x => x.id === id)
      if (itemToRemove) {
        if (!forceClose) {
          // Wait until the toast is not hovered
          itemToRemove.toastRef.instance.hoverState
            .pipe(
              debounceTime(3000),
              filter(isHover => !isHover),
              takeWhile(isHover => !isHover)
            )
            .subscribe(() => {
              // Hide it if is not hover, otherwise call this function again to re-subscribe
              if (!itemToRemove.toastRef.instance.isHover) {
                this.removeToast(itemToRemove)
              } else {
                this.hide(id)
              }
            })
        } else {
          this.removeToast(itemToRemove)
        }
      }
    }
  }

  /**
   * Removes the toast from the stack and destroy the component
   * @param {ToastItem} itemToRemove
   */
  private removeToast(itemToRemove: ToastItem): void {
    const index = this.stackedToastlist.indexOf(itemToRemove)
    if (index > -1) {
      // remove component from list
      this.stackedToastlist.splice(index, 1)

      // recalculate positions of the stack
      this.recalculatePositions()

      // destroy component
      itemToRemove.toastRef.destroy()

      if (itemToRemove.toast.options.type === 'action') {
        this.actionToastRemoved.next()
      }
    }
  }

  recalculatePositions() {
    // get current scroll position
    const doc = this.browserService.getDocument()
    const win = this.browserService.getWindow()
    let scrollDistance = 0
    let browserHeight = 800
    if (doc) {
      scrollDistance = doc.documentElement.scrollTop
    }
    if (win) {
      browserHeight = win.innerHeight
    }

    let lastBottomPos = 10
    const zIndex = 10000
    this.stackedToastlist.forEach((item, index) => {
      // Wait for the animation of each toast to calculate the position properly
      setTimeout(
        () => {
          item.toast.top = lastBottomPos
          lastBottomPos += item.toast.el.nativeElement.firstChild.clientHeight
          lastBottomPos += 10 // spacing

          // set z-index
          item.toast.zIndex = zIndex + index

          // check if max height hit; if so remove oldest notification
          if (lastBottomPos > browserHeight - 60 && this.stackedToastlist.length > 1) {
            this.hide(this.stackedToastlist[0].id)
          }

          item.toast.detectChanges()
        },
        this.stackedToastlist.filter(stackedToast => stackedToast.toast.isAnimating).length > 1 ? 200 : 0
      )
    })
  }

  /**
   * Gets the displayed text in case of events and reminders
   * @param {ToastOptions} options
   * @param {boolean} shouldStripHTML
   * @returns {{title: string; subtitle: string}}
   */
  private getNotificationText(options: ToastOptions, shouldStripHTML = false): { title: string; subtitle: string } {
    if ((options.type !== 'event' && options.type !== 'reminder') || !options.notification) {
      return { title: '', subtitle: '' }
    }

    const title =
      options.type === 'reminder' && options.notification?.notifications.length
        ? options.notification.groupDescription
        : options.notification.description

    const subtitle =
      options.notification.text && options.notification.detail
        ? `${options.notification.text} ${options.notification.detail}`
        : options.notification.text || options.notification.detail

    return {
      title: shouldStripHTML ? stripHTML(title) : title,
      subtitle: shouldStripHTML ? stripHTML(subtitle) : subtitle
    }
  }
}
