import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fileSize',
  standalone: true
})

/**
 * Transform a number to multiples of bytes to express file size
 */
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    let factor = 1

    while (size >= 1024 && factor < 5) {
      size /= 1024
      factor++
    }

    return `${this.precisionRound(size)} ${this.factorToUnit(factor)}`
  }

  private factorToUnit(factor: number): string {
    switch (factor) {
      case 1:
        return 'Bytes'
      case 2:
        return 'KB'
      case 3:
        return 'MB'
      case 4:
        return 'GB'
      case 5:
        return 'TB'
      default:
        return '-'
    }
  }

  private precisionRound(number, precision = 2) {
    const factor = Math.pow(10, precision)
    return Math.round((number * factor) / factor)
  }
}
