import { Injectable } from '@angular/core'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'

import { ErrorItem } from '@awork/_shared/services/log-service/types'

export interface LogsState {
  errors: ErrorItem[]
  httpErrors: ErrorItem[]
  debug: ErrorItem[]
}

export function createInitialLogState(): LogsState {
  return {
    errors: [],
    httpErrors: [],
    debug: []
  }
}

@Injectable({ providedIn: 'root' })
export class LogsStore extends SignalStore<LogsState> {
  constructor() {
    super({ name: 'logs', initialState: createInitialLogState() })
  }
}
