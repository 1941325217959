import { app } from '@awork/environments/environment'

export interface SelectOptions<Entity> {
  filterBy?: FilterBy<Entity>
  limitTo?: number
  sortBy?: SortBy<Entity>
  sortByOrder?: Order
}

type FilterBy<Entity> =
  | ((entity: Entity, index?: number) => boolean)
  | ((entity: Entity, index?: number) => boolean)[]
  | undefined

type SortBy<Entity, State = any> = ((a: Entity, b: Entity, state?: State) => number) | keyof Entity

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export interface GenericEntity {
  id: string
  resourceVersion?: number
}

export type EntityProps<Entity> = Entity & GenericEntity extends Entity ? keyof Entity : never

export interface Paging {
  page: number
  pageSize: number
}

export interface SyncOptions<Entity> {
  compareProp?: EntityProps<Entity> // Property used to do the update comparison
  keepProp?: EntityProps<Entity> // Property to keep in store when updating the entity
  resetFunction?: Function // Function to reset certain entity properties in the store
  paging?: Paging // Paging information used when fetching certain page from the API
  nullableProps?: EntityProps<Entity>[] // Needed to remove the value from the store when the API doesn't return it
}

export const DEFAULT_PERSIST_DELAY = app === 'web' ? 5000 : 2000
