import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'
import { Router } from '@angular/router'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { PrintService } from '../../../_shared/services/print-service/print.service'
import { AppStore } from '@awork/core/state/app.store'
import { ElectronService } from '@awork/_shared/services/electron-service/electron.service'
import { ToastComponent } from '../../../_shared/components/ui-help/toast/toast.component'
import { CloseButtonComponent } from '../../../_shared/components/icon-buttons/close-button/close-button.component'
import { SwitchButtonComponent } from '../../../_shared/components/buttons/switch-button/switch-button.component'
import { ButtonComponent } from '../../../_shared/components/buttons/button/button.component'
import { NgClass, NgIf } from '@angular/common'

@Component({
  selector: 'aw-open-desktop-links-toast',
  templateUrl: './open-desktop-links-toast.component.html',
  styleUrls: ['./open-desktop-links-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CloseButtonComponent, SwitchButtonComponent, ButtonComponent, NgClass, NgIf],
  animations: [
    trigger('slideOpen', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: '0' }),
        animate(
          '.2s ease-out',
          keyframes([
            style({ transform: 'translateY(-25%)', opacity: '0', offset: 0.75 }),
            style({ transform: 'translateY(0%)', opacity: '1', offset: 1 })
          ])
        )
      ])
    ]),
    trigger('expand', [
      transition(':enter', [style({ height: '0px' }), animate('.2s ease-out', style({ height: '210px' }))])
    ])
  ]
})
export class OpenDesktopLinksToastComponent extends ToastComponent implements OnInit {
  @Input() closeDelay: number

  protected openLinksInDesktopSetting: boolean

  protected translations = q.translations.OpenDesktopLinksToastComponent

  constructor(
    public el: ElementRef,
    protected router: Router,
    protected browserService: BrowserService,
    public printService: PrintService,
    private appStore: AppStore,
    private electronService: ElectronService
  ) {
    super(el, router, browserService, printService)
  }

  ngOnInit(): void {}

  /**
   * Closes the toast
   */
  onClose(): void {
    this.appStore.setOpenLinksInDesktopApp(this.openLinksInDesktopSetting || false)
    this.close()
  }

  /**
   * Toggles the setting to open links in the desktop app
   */
  toggleOpenLinksInDesktop(): void {
    const newValue = !this.openLinksInDesktopSetting

    this.appStore.setOpenLinksInDesktopApp(newValue)

    this.openLinksInDesktopSetting = newValue
  }

  /**
   * Opens the deep link in the electron app.
   * If the toggle is set to true to always open links in the desktop app, the setting will be saved.
   * Else the toggle reappears on the next link opening.
   */
  openDeepLink(): void {
    if (!!this.openLinksInDesktopSetting) {
      this.appStore.setOpenLinksInDesktopApp(true)
    }

    this.electronService.openDeepLink(window.location.href)
    this.close()
  }
}
