import { Apps, Environment } from '@awork/environments/types'

export const environment: Environment = 'develop',
  apiEndpoint = '/api/v1',
  apiHostname = 'awork.com',
  apiProtocol = 'https://',
  assetsUrl = 'https://cdn.awork.com/app/dev/assets/',
  clientSecret = 'LUoJXFBCgLDKXmp0BcXawA7osttZAGhZfkIYAyKafqZ3bfSkMtxk',
  mobileAppClientCredentials = '',
  releaseName = 'Custom Ice',
  buildName = 'Limon',
  version = '3.0.0.23685',
  app: Apps = 'web',
  isLiveMobile = false
