import { environment } from '@awork/environments/environment'
import { Criterion } from './types'

/**
 * Returns true if the current environment is develop
 * @returns {boolean}
 */
export const isDevelopCriterion: Criterion = () => environment === 'develop'

/**
 * Returns true if the current environment is production
 * @returns {boolean}
 */
export const isProductionCriterion: Criterion = () => environment === 'production'

/**
 * Returns true if the current environment is localhost
 * @returns {boolean}
 */
export const isLocalhostCriterion: Criterion = () => environment === 'local'

/**
 * Returns true if the current environment is localhost or develop
 * @returns {boolean}
 */
export const isDeveloperEnvironmentCriterion: Criterion = () => isLocalhostCriterion() || isDevelopCriterion()
