<button
  class="btn-icon"
  type="button"
  [disabled]="disabled"
  [ngClass]="removeButtonClasses"
  [awTooltip]="tooltip"
  [actionTooltip]="true"
  (click)="onClickHandler($event)"
>
  <i class="aw-2-icn">close</i>
</button>
