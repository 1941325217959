import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Size } from '@awork/_shared/types/size'
import { User } from '@awork/features/user/models/user.model'
import { AvatarInitialsComponent } from '../../../../_shared/components/data-display/avatar-initials/avatar-initials.component'
import { AvatarPixelSizes, AvatarSize, SubAvatarPixelSizes } from '../../../../_shared/types/avatar-sizes'
import { Router } from '@angular/router'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { WorkspaceAvatarComponent } from '../../../workspace/components/workspace-avatar/workspace-avatar.component'
import { Color } from '@awork/_shared/types/color'

function mapUser(user: User) {
  if (user && !(user instanceof User)) {
    return new User(user, 'user avatar')
  }

  return user
}

@Component({
  selector: 'aw-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, NgStyle, AvatarInitialsComponent, NgOptimizedImage, NgStyle, WorkspaceAvatarComponent]
})
export class UserAvatarComponent implements OnChanges {
  @Input({ transform: mapUser }) user: User
  @Input() showText: boolean = true
  @Input() tertiaryText = ''
  @Input() size: AvatarSize = Size.m
  @Input() link: boolean = false
  @Input() multiline = false
  @Input() lines = 2
  @Input() textColor: Color.Steel | Color.Night = Color.Night

  protected readonly avatarPixelSizes = AvatarPixelSizes
  protected readonly subAvatarPixelSizes = SubAvatarPixelSizes
  protected readonly sizes = Size
  protected readonly Color = Color

  initials: string
  imageUrl: string
  imgSize: number
  showSecondaryText = true
  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private logService: LogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentUser = changes.user?.currentValue as User
    const previousUser = changes.user?.previousValue as User

    if (
      changes.user &&
      currentUser &&
      (currentUser?.id !== previousUser?.id ||
        this.getTimeIfDate(currentUser?.updatedOn) !== this.getTimeIfDate(previousUser?.updatedOn) ||
        currentUser?.hasImage !== previousUser?.hasImage)
    ) {
      this.setAvatarInfo()
    }

    if (this.user && changes.size) {
      this.setSecondaryText()
      this.setAvatarInfo()
    }
  }

  /**
   * Sets the necessary info to display the avatar
   */
  setAvatarInfo(): void {
    if (this.user.hasImage) {
      this.imgSize = AvatarPixelSizes[this.size]

      this.imageUrl = this.user.profileImage(this.imgSize, this.imgSize)
    } else {
      this.imageUrl = null
      this.initials = this.user.initials
    }

    if (this.user.isExternal && this.link) {
      this.link = false
    }
  }

  /**
   * Sets visibility of secondary text
   */
  setSecondaryText(): void {
    this.showSecondaryText = [Size.m, Size.l, Size.xl].includes(this.size)
  }

  /**
   * Navigates to user details
   */
  linkClick(event: MouseEvent | TouchEvent) {
    if (this.link) {
      event.stopPropagation()
      this.router.navigate(['/users', this.user.id])
    }
  }

  /**
   * Handles image loading error
   */
  onError(): void {
    this.imageUrl = null
    this.initials = this.user.initials
  }

  /**
   * Returns the time value in milliseconds if the value is a Date
   * @param {unknown} value
   * @returns {false | number}
   */
  private getTimeIfDate(value: unknown): false | number {
    return value instanceof Date && value.getTime()
  }
}
