import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common'
import { Size } from '@awork/_shared/types/size'
import { AvatarPixelSizes, AvatarSize } from '../../../../_shared/types/avatar-sizes'
import { Workspace } from '@awork/features/workspace/models/workspace.model'
import { WithGlobals } from '../../../../_shared/classes/with-globals'

@Component({
  selector: 'aw-workspace-avatar',
  templateUrl: './workspace-avatar.component.html',
  styleUrls: ['./workspace-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, NgOptimizedImage]
})
export class WorkspaceAvatarComponent extends WithGlobals implements OnChanges {
  @Input({ required: true }) workspace: Workspace
  @Input() size: AvatarSize = Size.m
  @Input() showText = true
  @Input() multiline = false
  @Input() lines = 2
  @Input() showSecondaryText: boolean

  protected readonly avatarPixelSizes = AvatarPixelSizes
  protected readonly translations = q.translations.WorkspaceAvatarComponent

  imageUrl: string

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workspace || changes.size) {
      this.setImage()
    }
  }

  /**
   * Sets the image url for the workspace avatar
   */
  private setImage(): void {
    const imageSize = AvatarPixelSizes[this.size]
    this.imageUrl = this.workspace.getImage(imageSize, imageSize)
  }
}
