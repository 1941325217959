import { Time } from '@angular/common'
import { addDays, addSeconds } from '@awork/_shared/functions/date-fns-wrappers'

export const HOURS_IN_WORKING_DAY = 8
export const HOURS_IN_DAY = 24
export const SECONDS_IN_MINUTE = 60
export const MINUTES_IN_HOUR = 60
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR
export const SECONDS_IN_WORKING_DAY = SECONDS_IN_HOUR * HOURS_IN_WORKING_DAY
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY

export interface ExtendedTime extends Time {
  seconds: number
}

/**
 * Converts a valid string (HH:MM) to Time
 * @param {string} time
 * @param withSeconds - If true, it returns an ExtendedTime that includes seconds
 * @return {Time | ExtendedTime}
 */
export function stringToTime(time: string, withSeconds = false): Time | ExtendedTime {
  if (time && typeof time === 'string') {
    try {
      time = time.trim()
      let splittedTime: string[] = []

      // Split times like 14:00
      if (time.includes(':')) {
        splittedTime = time.split(':')
      } else {
        // Split time like 1400
        splittedTime = [time.slice(0, 2), time.slice(2)]
      }

      if (splittedTime.length > 0) {
        let hours = Number.parseInt(splittedTime[0])

        if (time.toLowerCase().replace('.', '').endsWith('pm') && hours < 12) {
          hours = (hours + 12) % 24
        } else if (time.toLowerCase().replace('.', '').endsWith('am') && hours === 12) {
          hours = 0
        }

        const minutes =
          splittedTime[1] && !isNaN(Number.parseInt(splittedTime[1])) ? Number.parseInt(splittedTime[1]) : 0
        const seconds =
          splittedTime[2] && !isNaN(Number.parseInt(splittedTime[2])) ? Number.parseInt(splittedTime[2]) : 0

        const generatedTime = !withSeconds ? { hours, minutes } : { hours, minutes, seconds }

        // Check if generated time is valid
        const testDate = new Date()
        testDate.setHours(generatedTime.hours)
        testDate.setMinutes(generatedTime.minutes)

        if (withSeconds) {
          testDate.setSeconds(generatedTime.seconds)
        }

        if (!isNaN(testDate.getTime())) {
          return generatedTime
        }
      }
    } catch (error) {
      return null
    }
  }

  return null
}

/**
 * Converts Time to string
 * @param {Time | ExtendedTime} time
 * @param showSeconds
 * @return {string}
 */
export function timeToString(time: Time | ExtendedTime, showSeconds = true): string {
  if (time) {
    let t: ExtendedTime
    if (!(<ExtendedTime>time).seconds && showSeconds) {
      t = {
        hours: time.hours,
        minutes: time.minutes,
        seconds: 0
      }
    } else {
      t = time as ExtendedTime
    }
    // correct the time, if it exceeds 1 day
    if (t.hours >= 24) {
      t.hours = t.hours - floor(t.hours / 24) * 24
    }

    return `${t.hours < 10 ? '0' + t.hours : t.hours}:${t.minutes < 10 ? '0' + t.minutes : t.minutes}${
      showSeconds ? `:${t.seconds < 10 ? '0' + t.seconds : t.seconds}` : ''
    }`
  }
  return null
}

/**
 * Returns a date given the time (Today + time by default)
 * @param {Time} time
 * @param {number} offset - An offset in days
 * @return {Date}
 */
export function timeToDate(time: Time | ExtendedTime | string, offset?: number): Date {
  let date = new Date()

  if (typeof time === 'string') {
    time = stringToTime(time as string, true)
  }

  if (time) {
    let t: ExtendedTime
    if (!(<ExtendedTime>time).seconds) {
      t = {
        hours: time.hours,
        minutes: time.minutes,
        seconds: 0
      }
    } else {
      t = time as ExtendedTime
    }

    if (offset) {
      date = addDays(date, offset)
    }

    date.setHours(t.hours)
    date.setMinutes(t.minutes)
    date.setSeconds(t.seconds)
    date.setMilliseconds(0)

    return date
  } else {
    return null
  }
}

/**
 * Converts Date to Time
 * @param {Date} date
 * @param {boolean} string
 * @return {Time | string} - True to get a valid time string (HH:MM:SS)
 */
export function dateToTime(date: Date | string, string = false): Time | string {
  if (date && date instanceof Date) {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return string
      ? `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
          seconds < 10 ? '0' + seconds : seconds
        }`
      : { hours, minutes }
  } else if (typeof date === 'string') {
    return string ? date : stringToTime(date)
  }

  return null
}

/**
 * Converts a value in hours to Time or string (HH:MM)
 * @param {number} value
 * @param {boolean} string
 * @return {Time | string}
 */
export function numberToTime(value: number, string = false): Time | string {
  const hours = floor(value)
  const minutes = floor(Number(((value - hours) * SECONDS_IN_MINUTE).toFixed(2)))

  const time: Time = {
    hours,
    minutes
  }

  return string ? timeToString(time) : time
}

/**
 * Strips the milliseconds from the time
 * @param {string} time
 * @return {string}
 */
export function stripMilliseconds(time: string): string {
  const t = stringToTime(time, true)
  return timeToString(t)
}

/**
 * Applies the difference in duration to a time
 * @param {string | Time} time
 * @param {number} duration
 * @param {boolean} string
 * @returns {string | Time}
 */
export function applyDifferenceToTime(time: string | Time, duration: number, string?: true): string
export function applyDifferenceToTime(time: string | Time, duration: number, string?: false): Time
export function applyDifferenceToTime(time: string | Time, duration: number, string = true): string | Time {
  if (time) {
    if (typeof time === 'string') {
      time = stringToTime(time, true)
    }

    const date = addSeconds(timeToDate(time), duration)

    return dateToTime(date, string)
  }
  return null
}

/**
 * Returns a Time Object from a seconds value
 * @param {number} secs
 * @returns {Time}
 */
export function secondsToTimeObject(secs: number): Time {
  secs = round(secs)

  const hours = floor(secs / SECONDS_IN_HOUR)

  const divisorForMinutes = secs % SECONDS_IN_HOUR
  const minutes = divisorForMinutes / SECONDS_IN_MINUTE

  return {
    hours,
    minutes
  }
}

/**
 * Makes a readable string from a seconds value
 * @param {number} secs
 * @param {boolean} parseSeconds
 */
export function secondsToTime(secs: number, parseSeconds: boolean = false, showDays = true): string {
  secs = round(secs)
  const days = floor(secs / SECONDS_IN_WORKING_DAY)

  const divisorForHours = secs % SECONDS_IN_WORKING_DAY
  let hours = floor(divisorForHours / SECONDS_IN_HOUR)
  if (!showDays) {
    hours = hours + days * HOURS_IN_WORKING_DAY
  }

  const divisorForMinutes = secs % SECONDS_IN_HOUR
  const minutes = floor(divisorForMinutes / SECONDS_IN_MINUTE)

  const divisorForSeconds = secs % SECONDS_IN_MINUTE
  const seconds = floor(divisorForSeconds)

  const stringArray = []

  if (days > 0 && showDays) {
    stringArray.push(`${days}d`)
  }
  if (hours > 0) {
    stringArray.push(`${hours}h`)
  }
  if (minutes > 0) {
    stringArray.push(`${minutes}m`)
  }
  if (seconds > 0 && parseSeconds) {
    stringArray.push(`${seconds}s`)
  }

  return stringArray.join(' ')
}

/**
 * Determines if the input is a valid Time
 * @param x
 * @returns {x is Time}
 */
export function isTime(x: any): x is Time {
  return x !== undefined && x !== null && typeof x.hours === 'number' && typeof x.minutes === 'number'
}

/**
 * Calculates a given duration from a string.
 * E.g. "12days 10hours 12minute", "1d 5m", "6h", "1:30"
 * @param {string} str
 */
export function durationToSeconds(str: string): number {
  let totalSeconds = 0

  // support for format like 1:30
  if (str.includes(':')) {
    const splittedTime = str.split(':')
    const onlyNumberRegx = /\d+/g
    const splittedHour = splittedTime[0].match(onlyNumberRegx)?.[0]
    const splittedMin = splittedTime[1].match(onlyNumberRegx)?.[0]

    return Number(splittedHour) * 3600 + Number(splittedMin) * 60
  }

  const days = str.match(/(\d+([\,\.]\d{1,2})?)\s*d/)
  const hours = str.match(/(\d+([\,\.]\d{1,2})?)\s*h/)
  const minutes = str.match(/(\d+([\,\.]\d{1,2})?)\s*m/)
  const seconds = str.match(/(\d+([\,\.]\d{1,2})?)\s*s/)
  if (days) {
    totalSeconds += Number(days[1].replace(',', '.')) * 28800
  } // not 24h! it's 8 currently
  if (hours) {
    totalSeconds += Number(hours[1].replace(',', '.')) * 3600
  }
  if (minutes) {
    totalSeconds += Number(minutes[1].replace(',', '.')) * 60
  }
  if (seconds) {
    totalSeconds += Number(seconds[1].replace(',', '.'))
  }
  // if nothing matches, assume hours
  if (!days && !hours && !minutes && !seconds) {
    totalSeconds += Number(str.replace(',', '.')) * 3600
  }

  return round(totalSeconds)
}

/**
 * Converts a date to a time with seconds
 * @param {Date} date
 * @returns {ExtendedTime}
 */
export function dateToExtendedTime(date: Date): ExtendedTime {
  return stringToTime(dateToTime(date, true) as string, true) as ExtendedTime
}

/**
 * Determines if a time is today in the future
 * @param {Time} time
 */
export function isFutureTime(time: Time): boolean {
  const now = new Date()
  const dateWithTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), time.hours, time.minutes)

  return dateWithTime > now
}

/**
 * Round function that handles negative numbers
 * @param {number} number
 * @returns {number}
 */
function round(number: number): number {
  return Math.sign(number) * Math.round(Math.abs(number))
}

/**
 * Floor function that handles negative numbers
 * @param {number} number
 * @returns {number}
 */
function floor(number: number): number {
  return Math.sign(number) * Math.floor(Math.abs(number))
}

export const HOURS_LIST: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
]
