<label *ngIf="label" class="form-label">{{label}}</label>

<textarea
  #formInput
  awAutoFocus
  awAutoGrow
  type="text"
  class="form-input"
  [style.height.px]="28 + (focused ? maxRows : minRows) * 20"
  [attr.placeholder]="placeholder || null"
  [attr.readonly]="disabled ? '' : null"
  [attr.maxlength]="maxLength || null"
  [autoFocus]="autoFocus"
  [formControl]="control"
  [enableAutoGrow]="enableAutoGrow"
  [autoGrowMaxHeight]="autoGrowMaxHeight"
  [autoGrowMinHeight]="autoGrowMinHeight"
  (keyup)="keyup.emit($event)"
  (keydown)="keydown.emit($event)"
  (blur)="focused = false; blur.emit($event)"
  (focus)="focused = true; focus.emit($event)"
  (change)="change.emit($event)"
></textarea>

<aw-info-box *ngIf="errorText" [text]="errorText" [color]="colors.Red" />
