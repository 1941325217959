export enum Size {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl'
}

export type SmallSize = Extract<Size, 'xxs' | 'xs' | 's' | 'm'>
export type MainSize = Size.s | Size.m | Size.l
export type ButtonSize = Size.xs | Size.s | Size.m

export const POPUP_SIZES = {
  [Size.s]: 260,
  [Size.m]: 380,
  [Size.l]: 460
}
