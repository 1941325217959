import { SelectOption } from '@awork/_shared/models/select-option.model'

export enum ContactTypes {
  address = 'address',
  phone = 'phone',
  email = 'email',
  messenger = 'messenger',
  social = 'social',
  url = 'url',
  custom = 'custom'
}

/**
 * The contact information in the awork
 */
export class Contact {
  id: string
  value: string
  type: string
  subType: string
  label: string
  addressLine1: string
  addressLine2: string
  zipCode: string
  city: string
  state: string
  country: string
  isAddress: boolean
  deleted: boolean

  constructor(contact: any) {
    Object.assign(this, contact)

    if (this.type) {
      this.type = this.type.toLowerCase()
    }

    if (this.subType) {
      this.subType = this.subType.toLowerCase()
    }
  }

  get valueWithAddress(): string {
    if (this.type === ContactTypes.address.toString()) {
      let address = this.addressLine1 ? `${this.addressLine1}<br/>` : ''
      address += this.addressLine2 ? `${this.addressLine2}<br/>` : ''
      address += this.zipCode ? `${this.zipCode} ` : ''
      address += this.city ? `${this.city}<br/>` : this.zipCode ? '<br/>' : ''
      address += this.state ? `${this.state}<br/>` : ''
      address += this.countryName ? `${this.countryName}<br/>` : ''
      return address
    } else {
      return this.value
    }
  }

  static getSubtypes(contactType: ContactTypes, ownerType = 'user'): SelectOption[] {
    const trans = q.translations.ContactTypes
    switch (ownerType) {
      case 'user':
        switch (contactType) {
          case ContactTypes.phone:
            return [
              { value: 'work', label: trans.work },
              { value: 'mobile', label: trans.mobile },
              { value: 'home', label: trans.home },
              { value: 'work-fax', label: trans.workFax },
              { value: 'fax', label: trans.fax },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.email:
            return [
              { value: 'work', label: trans.work },
              { value: 'private', label: trans.private },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.address:
            return [
              { value: 'work', label: trans.work },
              { value: 'home', label: trans.home },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.messenger:
            return [
              { value: 'skype', label: 'Skype' },
              { value: 'whatsapp', label: 'Whatsapp' },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.social:
            return [
              { value: 'facebook', label: 'Facebook' },
              { value: 'twitter', label: 'Twitter' },
              { value: 'xing', label: 'Xing' },
              { value: 'linkedin', label: 'LinkedIn' },
              { value: 'instagram', label: 'Instagram' },
              { value: 'pinterest', label: 'Pinterest' },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.url:
            return [
              { value: 'work', label: trans.work },
              { value: 'private', label: trans.private },
              { value: 'github', label: 'GitHub' },
              { value: 'other', label: trans.other }
            ]
          default:
            return null
        }
      case 'company':
        switch (contactType) {
          case ContactTypes.phone:
            return [
              { value: 'central', label: trans.central },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.email:
            return [
              { value: 'central', label: trans.central },
              { value: 'invoice', label: trans.invoice },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.address:
            return [
              { value: 'central', label: trans.central },
              { value: 'invoice', label: trans.invoice },
              { value: 'other', label: trans.other }
            ]
          case ContactTypes.url:
            return [
              { value: 'primary', label: trans.primary },
              { value: 'other', label: trans.other }
            ]
          default:
            return null
        }
      default:
        return null
    }
  }

  get subTypeLabel(): string {
    let subtype = ''

    if (this.subType) {
      subtype = this.subType.replace('-', ' ')
      subtype = q.translations.ContactTypes[subtype]
    }

    return subtype
  }

  get countryName(): string {
    if (this.country) {
      const el = Object.entries(q.translations.countries).find(
        ([code, _]) => code === this.country || code.toLowerCase() === this.country.toLowerCase()
      )
      if (el) {
        return el[1] as string
      }
    }
    return ''
  }

  getContactType(): ContactTypes {
    switch (this.type) {
      case 'address':
        return ContactTypes.address
      case 'phone':
        return ContactTypes.phone
      case 'email':
        return ContactTypes.email
      case 'messenger':
        return ContactTypes.messenger
      case 'social':
        return ContactTypes.social
      case 'url':
      case 'urls':
        return ContactTypes.url
      case 'other':
        return ContactTypes.custom
      default:
        return null
    }
  }

  /**
   * Validates the contact information
   * @returns {boolean}
   */
  validate(ownerType: 'company' | 'user'): boolean {
    if (this.type !== null && this.type !== undefined && this.subType !== null && this.subType !== undefined) {
      // Validate the subtype
      const validSubtypes = Contact.getSubtypes(this.getContactType(), ownerType)

      if (!validSubtypes || !validSubtypes.some(subtype => subtype.value === this.subType)) {
        return false
      }

      if (this.type !== ContactTypes.address) {
        return this.value !== null && this.value !== undefined
      } else {
        return !!this.city && !!this.countryName
      }
    } else {
      return false
    }
  }
}
