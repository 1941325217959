import { computed, Injectable, Signal, signal } from '@angular/core'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { PersistState } from '@awork/core/state/signal-store/persistState'
import { BehaviorSubject, filter, map, Observable, take } from 'rxjs'
import { GenericEntity } from '@awork/core/state/signal-store/types'

interface ResetOptions {
  exclude: string[]
}

type StoreType<State> = SignalStore<State & object> | EntitySignalStore<State & GenericEntity>

/**
 * Service that holds all registered signal stores
 */
@Injectable({ providedIn: 'root' })
export class SignalStoreService<State = {}> {
  private entityStores = new Map<string, StoreType<State>>()
  private storage: PersistState

  private restoredStores$ = new BehaviorSubject<string[]>([])
  private restoredStores = signal<string[]>([])

  constructor() {
    this.initPersistState()
  }

  /**
   * Initialize the persist state storage
   */
  private initPersistState(): void {
    this.storage = PersistState.init()

    this.setRestoredStores()
  }

  /**
   * Subscribes to the storage onRestored observable to set the restored stores
   */
  private setRestoredStores(): void {
    this.storage.onRestored().subscribe(key => {
      this.restoredStores$.next([...this.restoredStores$.value, key])
      this.restoredStores.set([...this.restoredStores(), key])
    })
  }

  /**
   * Register a store to the service
   * @param {string} storeName
   * @param {EntitySignalStore} store
   */
  registerStore(storeName: string, store: StoreType<State>): void {
    this.entityStores.set(storeName, store)
  }

  /**
   * Get all registered stores
   * @returns {Map<string, EntitySignalStore<Entity>>}
   */
  getRegisteredStores(): Map<string, StoreType<State>> {
    return this.entityStores
  }

  /**
   * Resets all resettable stores
   * @param {Partial<ResetOptions>} options
   */
  resetStores(options?: Partial<ResetOptions>): void {
    this.entityStores.forEach(store => {
      const storeOptions = store.getStoreOptions()

      if (storeOptions.resettable && !options?.exclude?.includes(storeOptions.name)) {
        store.reset()
      }
    })
  }

  /**
   * Emits when the storage is initialized
   * @returns {Observable<void>}
   */
  selectOnStorageInit(): Observable<void> {
    return this.storage.onInit().pipe(take(1))
  }

  /**
   * Determines if the storage is initialized
   */
  isStorageInit(): boolean {
    return !!this.storage
  }

  /**
   * Emits when a store is restored from the storage
   * @param {string} storeName
   * @returns {Observable<void>}
   */
  selectOnStoreRestored(storeName: string): Observable<void> {
    return this.restoredStores$.pipe(
      filter(restoredStores => restoredStores.includes(storeName)),
      take(1),
      map(() => void 0)
    )
  }

  /**
   * Gets a signal that determines if a store is restored from the storage
   * @param {string} storeName
   * @returns {Signal<boolean>}
   */
  queryOnStoreRestored(storeName: string): Signal<boolean> {
    return computed(() => {
      const restoredStores = this.restoredStores()
      return restoredStores.includes(storeName)
    })
  }

  selectOnStorePersisted(storeName: string): Observable<string> {
    return this.storage.onPersisted(storeName)
  }
}
