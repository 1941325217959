import type { Subdomain } from '@awork/features/workspace/models/workspace.model'

export interface AuthResponse {
  resource: string
  token_type: string
  expires_in: number
  access_token?: string
  refresh_token?: string
  subdomain: Subdomain
  userId?: string
}

export enum SocialConnectErrors {
  NoUserLinked = 'no-user-linked',
  SocialLoginNotLinked = 'social-login-not-linked',
  EmailDomainNotAllowed = 'email-domain-not-allowed',
  NeverLoggedIn = 'never-logged-in'
}

export class LoginData {
  email: string
  name: string
  initials: string
  image?: string
}
