import { differenceInCalendarDays } from '@awork/_shared/functions/date-fns-wrappers'

export interface IWorkspaceAbsence {
  id: string
  startOn: Date
  endOn: Date
  description: string
  isReadOnly: boolean
  isHalfDayOnStart: boolean
  isHalfDayOnEnd: boolean
  regionId: string
  region: string
  createdOn: Date
}

export class WorkspaceAbsence implements IWorkspaceAbsence {
  id: string
  startOn: Date
  endOn: Date
  description: string
  isReadOnly: boolean
  isHalfDayOnStart: boolean
  isHalfDayOnEnd: boolean
  regionId: string
  region: string
  createdOn: Date

  constructor(data: Partial<IWorkspaceAbsence> = null) {
    if (data) {
      Object.assign(this, data)
    }
  }

  get duration(): number {
    // If the absence have half start and end days, only count the full days in between, extract 1 day
    if (this.isHalfDayOnStart && this.isHalfDayOnEnd) {
      return differenceInCalendarDays(this.endOn, this.startOn) - 1
    }

    // If the absence have 1 half day, we need to extract 0.5 day to the duration
    if (this.isHalfDayOnEnd || this.isHalfDayOnStart) {
      return differenceInCalendarDays(this.endOn, this.startOn) - 0.5
    }

    return differenceInCalendarDays(this.endOn, this.startOn)
  }
}
