import {
  CustomFieldDefinition,
  ICustomFieldDefinition
} from '@awork/features/project/models/custom-field-definition.model'

interface ICustomFieldDefinitionLink extends ICustomFieldDefinition {
  order: number
  projectId?: string
  projectTemplateId?: string
}

export type CustomFieldDefinitionLinkEntity = 'projects' | 'projectTemplates'

/**
 * Represents the custom field associated to a Project or Project Template.
 * It includes the order in which it should be displayed.
 */
export class CustomFieldDefinitionLink extends CustomFieldDefinition implements ICustomFieldDefinitionLink {
  order: number
  projectId?: string
  projectTemplateId?: string

  constructor(customFieldDefinition: ICustomFieldDefinitionLink) {
    super(customFieldDefinition)

    Object.assign(this, customFieldDefinition)
  }
}
