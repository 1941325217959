import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'middleEllipsisPipe',
  standalone: true
})
export class MiddleEllipsisPipe implements PipeTransform {
  transform(fullString: string, targetLength: number, separator = '...'): string {
    if (fullString.length < targetLength) {
      return fullString
    }

    const sepLen = separator.length,
      charsToShow = targetLength - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2)

    return fullString.substr(0, frontChars) + separator + fullString.substr(fullString.length - backChars)
  }
}
