/**
 * Creates a unique guid
 */
export function generateGUID(): string {
  if (typeof crypto?.randomUUID === 'function') {
    return crypto.randomUUID()
  }

  // If crypto.randomUUID is not available, we use the old strategy as a fallback
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}
