/**
 * Returns an integer hash digest for an input string.
 * @param s The string
 */
export function hash(s: string): number {
  let hash1 = 0

  if (s.length === 0) {
    return hash1
  }

  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i)
    hash1 = (hash1 << 5) - hash1 + char
    hash1 = hash1 & hash1 // Convert to 32bit integer
  }
  return Math.abs(hash1)
}
