/**
 * The permissions in awork
 */
import { Project } from '@awork/features/project/models/project.model'

export enum Features {
  ProjectMaster = 1,
  ProjectPlanning = 2,
  ProjectManage = 3,
  UserMaster = 4,
  UserManage = 5,
  FilesMaster = 6,
  WorkspaceMaster = 7,
  WorkspaceManager = 8,
  CompanyMaster = 9,
  CompanyManage = 10,
  ProjectTimetracking = 11,
  UserTimetracking = 12,
  TaskManage = 13,
  ProjectCreate = 14,
  ProjectOwnTeam = 15,
  UserOwnTeam = 16,
  UserPlanning = 18
}

export enum AccessLevels {
  Manage = 0,
  Read = 1,
  None = 2
}

export class Permissions {
  id: string
  feature: string
  accessLevels: string[]

  constructor(permission: any) {
    this.id = permission.id
    this.feature = permission.feature
    this.accessLevels = permission.accessLevels
  }

  hasAnyAccessLevel(accessLevels: string[]): boolean {
    return accessLevels.some(a => this.accessLevels.includes(a))
  }
}

export class ProjectRolePermissions {
  projectRoleId: string
  permissions: Permissions[]

  constructor(projectRolePermissions: any) {
    this.projectRoleId = projectRolePermissions.projectRoleId
    this.permissions = projectRolePermissions.permissions
  }
}

export interface PermissionCheck {
  features: Features | Features[]
  accessLevel: AccessLevels
  project?: Project
}

export const defaultAccessLevelProjectRole = 'manage'

export class UserPermissions {
  userId: string
  roleId: string
  isAdmin: boolean
  isGuest: boolean
  isBlocked: boolean
  permissions: Permissions[]
  projectRolesPermissions: ProjectRolePermissions[]
  deactivatedMenuItems: string[]
  signature: string
  userPermission?: any

  constructor(userPermissions: any) {
    this.userId = userPermissions.userId
    this.roleId = userPermissions.roleId
    this.isAdmin = userPermissions.isAdmin
    this.isGuest = userPermissions.isGuest
    this.isBlocked = userPermissions.isBlocked
    this.permissions = userPermissions.permissions
    this.projectRolesPermissions = userPermissions.projectRolesPermissions
    this.deactivatedMenuItems = userPermissions.deactivatedMenuItems
    this.signature = userPermissions.signature
    this.userPermission = userPermissions.userPermission
  }

  /**
   * Returns if the menuitem should be visible or not
   * @param {string} menuitemId
   */
  isAllowedToSeeMenuItem(menuitemId: string): boolean {
    return !this.deactivatedMenuItems.includes(menuitemId)
  }
}

export class Feature {
  id: number
  value: string
  name: string
  description: string

  constructor(feature: any) {
    this.id = feature.id
    this.value = feature.value
    this.name = feature.name
    this.description = feature.description
  }

  static get features(): Feature[] {
    return [
      {
        id: 1,
        value: 'project-master-data',
        name: 'Project Details',
        description: 'Manage projects'
      },
      {
        id: 2,
        value: 'project-planning-data',
        name: 'Project Tasks',
        description: 'Manage the project tasks'
      },
      {
        id: 3,
        value: 'project-manage-config',
        name: 'Project Config',
        description: 'Configure and manage the project types'
      },
      {
        id: 4,
        value: 'user-master-data',
        name: 'User Details',
        description: 'Manage the user information'
      },
      {
        id: 5,
        value: 'user-mange-config',
        name: 'User Config',
        description: '(currently not available)'
      },
      {
        id: 6,
        value: 'files-master-data',
        name: 'Files Details',
        description: '(currently not available)'
      },
      {
        id: 7,
        value: 'workspace-master-data',
        name: 'Workspace Details',
        description: '(currently not available)'
      },
      {
        id: 8,
        value: 'workspace-manage-config',
        name: 'Workspace Config',
        description: 'Manage the workspace settings'
      },
      {
        id: 9,
        value: 'company-master-data',
        name: 'Company Details',
        description: 'Manage companies'
      },
      {
        id: 10,
        value: 'company-manage-config',
        name: 'Company Config',
        description: 'Manage company types and the number schema'
      },
      {
        id: 11,
        value: 'project-timetracking',
        name: 'Project Time Tracking',
        description: 'Manage time Trackings of projects'
      },
      {
        id: 12,
        value: 'user-timetracking',
        name: 'Users Timetrackings',
        description: 'Manage the timetrackings of other users'
      },
      {
        id: 13,
        value: 'task-manage-config',
        name: 'Task Config',
        description: 'Configure bundles and types'
      },
      {
        id: 14,
        value: 'project-create-permission',
        name: 'Create Project',
        description: 'Create projects'
      },
      {
        id: 15,
        value: 'project-teams-data',
        name: 'Own team projects only',
        description: 'Acess to own team projects only'
      },
      {
        id: 16,
        value: 'user-teams-data',
        name: 'Own team users only',
        description: 'Acess to own team users only'
      },
      {
        id: 17,
        value: 'team-planner',
        name: 'Team planner',
        description: 'Manages team planner'
      },
      {
        id: 18,
        value: 'user-planning-data',
        name: 'Planning',
        description: 'Manages user planning data'
      }
    ].map(feature => new Feature(feature))
  }

  static get accessLevels(): string[] {
    return ['manage', 'read', 'none']
  }

  static get defaultAccessLevel(): string {
    return 'none'
  }

  static get projectFeatures(): Feature[] {
    return Feature.features.filter(
      feature =>
        feature.value === 'project-master-data' ||
        feature.value === 'project-planning-data' ||
        feature.value === 'project-timetracking'
    )
  }

  /**
   * Returns the needed API string for the Feature enum
   * @param featureEnum
   */
  static getFeatureStringByEnum(featureEnum: Features): string {
    const feature = this.features.find(feat => feat.id === Number(featureEnum))
    if (feature) {
      return feature.value
    } else {
      return ''
    }
  }

  /**
   * Returns the needed API string for the AccessLevel enum
   * @param accessLevel
   */
  static getPermissionStringByEnum(accessLevel: AccessLevels): string {
    return this.accessLevels[accessLevel]
  }

  /**
   * Returns the needed API string for the AccessLevel enum
   * @param accessLevel
   */
  static getAccessLevelEnumByString(accessLevel: string): AccessLevels {
    return this.accessLevels.indexOf(accessLevel)
  }
}
