import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { getState } from '@ngrx/signals'
import { generateGUID } from '@awork/_shared/functions/guid-generator'

/**
 * SignalStoreHistory is a helper class for SignalStore that allows to undo store operations
 */
export class SignalStoreHistory<State extends object = {}> {
  private operationMap: Map<string, State> = new Map<string, State>()

  constructor(private store: SignalStore<State>) {}

  /**
   * Starts the current operation by saving the current state
   * @returns {string} operationId
   */
  startOperation(): string {
    const operationId = generateGUID()
    const currentState = getState(this.store.getState())

    this.operationMap.set(operationId, currentState)

    return operationId
  }

  /**
   * Undo the last store operation
   * @param {string} operationId
   * @returns {boolean} true if the operation was undone
   */
  undo(operationId: string): boolean {
    if (!this.operationMap.has(operationId)) {
      throw new Error('No operation to undo')
    }

    const state = this.operationMap.get(operationId)

    this.store.update(state, 'undo')

    return this.endOperation(operationId)
  }

  /**
   * Ends the current operation by deleting it from the operation map
   * @param {string} operationId
   * @returns {boolean} true if the operation was ended
   */
  endOperation(operationId: string): boolean {
    return this.operationMap.delete(operationId)
  }
}
