import { AutomationEntityType, AutomationValueType, TextObject } from '@awork/features/project/models/automation.model'
import {
  AutomationActionValue,
  AutomationActionValueType,
  AutomationRelativeDate,
  IAutomationAction
} from '@awork/features/project/models/automation-action.model'
import {
  AutomationTriggerValue,
  AutomationTriggerValueType,
  IAutomationTrigger
} from '@awork/features/project/models/automation-trigger.model'
import isTest from '@awork/_shared/functions/is-test'
import replace from '@awork/_shared/functions/replace'
import { AutomationTask } from '@awork/features/project/models/automation-task.model'
import { ChecklistItem } from '@awork/features/task/models/checklist-item'
import { replaceTestNoOp, translationsTestProxy } from '@awork/_shared/functions/translation'
import { AutomationRelatedUser } from '@awork/features/project/models/automation-related-user.model'

export const translate = isTest() ? replaceTestNoOp : replace

/**
 * Gets the action translations and the trigger translations
 * @returns {{ actionTranslations; triggerTranslations }}
 */
function getTranslations(): { actionTranslations; triggerTranslations } {
  const { AutomationActionModel: actionTranslations, AutomationTriggerModel: triggerTranslations } = isTest()
    ? translationsTestProxy()
    : q.translations

  return { actionTranslations, triggerTranslations }
}

/**
 * Gets the action's or triggers text based on its type (placeholder text used for the selection list)
 * @returns {string}
 */
export function getSelectionText(actionKey: string, type: 'action' | 'trigger'): string {
  const { actionTranslations, triggerTranslations } = getTranslations()
  const translationText = type === 'action' ? actionTranslations[actionKey] : triggerTranslations[actionKey]
  const placeholders = findPlaceholders(translationText)

  if (placeholders.length > 0) {
    const translationValues = {}
    placeholders.forEach(p => {
      translationValues[p] = getValueText(
        {
          name: p as AutomationValueType,
          value: undefined,
          id: undefined,
          actionId: undefined
        },
        true,
        false,
        false
      )
    })
    return type === 'trigger'
      ? translate(translationText, translationValues) + '...'
      : '...' + translate(translationText, translationValues)
  } else {
    return type === 'trigger' ? translationText + '...' : '...' + translationText
  }
}

/**
 * Finds all placeholders in a translation as an array
 * @param text
 */
export function findPlaceholders(text: string): string[] {
  const placeholders = []

  // TODO dont know exactly why, but the test was failing here
  if (text && typeof text.substring === 'function') {
    let processedText = text.substring(0)
    do {
      const startPlaceholder = processedText.search('{{')
      const endPlaceholder = processedText.substring(startPlaceholder).search('}}') + startPlaceholder
      if (startPlaceholder !== -1 && endPlaceholder !== -1) {
        placeholders.push(processedText.substring(startPlaceholder + 2, endPlaceholder))
        processedText = processedText.substring(endPlaceholder + 2)
      }
    } while (processedText.search('{{') !== -1)
  }

  return placeholders
}

/**
 * Returns display value for action or trigger value
 * @param value
 * @param alternateCase
 */
// eslint-disable-next-line complexity
export function getValue(value: AutomationActionValue | AutomationTriggerValue, alternateCase: boolean): string {
  const { actionTranslations } = getTranslations()
  if (!value.value) {
    return getPlaceholderValue(value)
  }

  switch (value.name) {
    case AutomationValueType.name:
    case AutomationValueType.hours:
    case AutomationValueType.tag:
    case AutomationValueType.text:
      return value.value as string
    case AutomationValueType.unit:
      const unitValue = value.value as string
      return actionTranslations.AutomationRelativeDate[unitValue] || unitValue
    case AutomationValueType.status:
    case AutomationValueType.statusFrom:
    case AutomationValueType.statusTo:
    case AutomationValueType.list:
    case AutomationValueType.user:
    case AutomationValueType.project:
    case AutomationValueType.type:
    case AutomationValueType.taskBundle:
      const key = (value.value as any).key
      if (!!key) {
        const relatedUser = new AutomationRelatedUser(key)
        if (alternateCase) {
          return relatedUser.getNameForThenCase()
        } else {
          return relatedUser.getName()
        }
      } else {
        return (value.value as AutomationEntityType).name
      }
    case AutomationValueType.projectRole:
      return q.translations.AutomationActionModel.withRole + (value.value as AutomationEntityType).name
    case AutomationValueType.amount:
    case AutomationValueType.days:
      return (value.value as number).toString()
    case AutomationValueType.date:
      const relativeDate = value.value as AutomationRelativeDate
      return (
        q.translations.DateSelectorComponent[relativeDate.date] +
        ' + ' +
        relativeDate.offset +
        ' ' +
        actionTranslations.AutomationRelativeDate[relativeDate.unit]
      )
    case AutomationValueType.checklistItems:
      const checklistItems = value.value as ChecklistItem[]
      const count = checklistItems.length ? `${checklistItems.length} ` : ''
      return (
        count +
        (checklistItems.length === 1 ? actionTranslations.checklistItem : actionTranslations.placeholder.checklistItems)
      )
    default:
      return getPlaceholderValue(value)
  }
}

/**
 * Returns translated placeholder for action or trigger value
 * @param value
 */
export function getPlaceholderValue(value: AutomationActionValue | AutomationTriggerValue): string {
  return q.translations.AutomationActionModel.placeholder[value.name]
}

/**
 * Returns html for an action or trigger value
 * @param actionValue
 * @param usePlaceholderValue Use placeholders instead the values
 * @param useSpan use span as placeholder, otherwise use <b>
 * @param alternateCase use alternate case for the value
 */
export function getValueText(
  actionValue: AutomationActionValue | AutomationTriggerValue,
  usePlaceholderValue: boolean,
  useSpan: boolean,
  alternateCase: boolean
): string {
  const value = usePlaceholderValue ? getPlaceholderValue(actionValue) : getValue(actionValue, alternateCase)
  if (useSpan) {
    return '<span data-placeholder="' + actionValue.name + '">' + value + '</span>'
  } else {
    let placeholder = ''
    if (
      [
        AutomationValueType.tag,
        AutomationValueType.list,
        AutomationValueType.type,
        AutomationValueType.name,
        AutomationValueType.text
      ].includes(actionValue.name)
    ) {
      placeholder = getPlaceholderValue(actionValue) + ' '
    }
    return usePlaceholderValue ? '<b>' + value + '</b>' : '<b>' + placeholder + value + '</b>'
  }
}

/**
 * Creates and array of objects based on the trigger's or action's translation to be used in a template to render
 * text and AutomationInlineValueComponents inline
 */
export function getTextObject(item: IAutomationTrigger | IAutomationAction, type: 'trigger' | 'action'): TextObject[] {
  if (!item) {
    return []
  }

  const { actionTranslations, triggerTranslations } = getTranslations()
  const translationText = type === 'trigger' ? triggerTranslations[item.type] : actionTranslations[item.type]

  if (translationText && typeof translationText === 'string') {
    const translationArray = getTextAndPlaceholders(translationText)
    const itemValues: (AutomationTriggerValue | AutomationActionValue)[] = item.values
    const itemPlaceholderNames = itemValues?.map(value => value.name) || []

    return translationArray.map(text => {
      const isPlaceholder = itemPlaceholderNames.includes(text as AutomationValueType)

      if (isPlaceholder) {
        return createAutomationValue(text, itemValues, type)
      }

      return { type: 'text', value: text }
    })
  }

  return []
}

/**
 * Creates an object representing an automation's items placeholder
 * @param {string} translationText
 * @param {(AutomationTriggerValue | AutomationActionValue)[]} itemValues
 * @returns {TextObject}
 */
function createAutomationValue(
  translationText: string,
  itemValues: (AutomationTriggerValue | AutomationActionValue)[],
  type: 'trigger' | 'action'
): TextObject {
  const itemValue: AutomationTriggerValue | AutomationActionValue = itemValues.find(val => val.name === translationText)
  const { value, entityName } = getItemValue(itemValue.value, itemValue.name, type === 'action')

  return {
    type: 'placeholder',
    name: itemValue.name,
    value,
    entityName
  }
}

/**
 * Splits texts and placeholders from the string
 * @param translationText
 */
function getTextAndPlaceholders(translationText: string): string[] {
  return translationText.split(/[{{}}]/).filter(text => !!text)
}

/**
 * Gets the item's value and entityName (text value)
 * @param itemValue
 * @param itemName
 */
function getItemValue(
  itemValue: AutomationTriggerValueType | AutomationActionValueType,
  itemName: AutomationValueType,
  alternateCase: boolean
): { value: AutomationTriggerValueType | AutomationActionValueType; entityName: string } {
  const { actionTranslations } = getTranslations()

  if (itemValue == null) {
    return { value: undefined, entityName: undefined }
  }

  // Consume type guards to extract values and names
  if (typeof itemValue === 'string' || typeof itemValue === 'number') {
    return {
      value: itemValue,
      entityName: getItemValueName(itemName, itemValue)
    }
  }

  if (isAutomationEntityType(itemValue)) {
    return {
      value: itemValue.id,
      entityName: itemValue.name
    }
  }

  if (isAutomationTask(itemValue)) {
    return {
      value: itemValue,
      entityName: itemValue.name
    }
  }

  if (isAutomationRelatedUser(itemValue)) {
    const relatedUser = new AutomationRelatedUser(itemValue.key)
    return {
      value: relatedUser,
      entityName: alternateCase ? relatedUser.getNameForThenCase() : relatedUser.getName()
    }
  }

  if (isAutomationRelativeDate(itemValue)) {
    return {
      value: itemValue,
      entityName: `${q.translations.DateSelectorComponent[itemValue.date]} +${itemValue.offset} ${
        actionTranslations.AutomationRelativeDate[itemValue.unit]
      }`
    }
  }

  if (areChecklistItems(itemValue)) {
    const count = itemValue.length ? `${itemValue.length} ` : ''

    return {
      value: itemValue,
      entityName:
        count +
        (itemValue.length === 1 ? actionTranslations.checklistItem : actionTranslations.placeholder.checklistItems)
    }
  }

  return { value: undefined, entityName: undefined }
}

/**
 * Gets the item value's name for displaying purposes
 * @param itemName
 * @param itemValue
 */
function getItemValueName(itemName: AutomationValueType, itemValue: string | number): string {
  let name: string
  switch (itemName) {
    case AutomationValueType.message:
      name = q.translations.AutomationActionModel.placeholder[itemName]
      break
    case AutomationValueType.unit:
      name = q.translations.AutomationActionModel.AutomationRelativeDate[itemValue]
  }

  return name
}

// Type guards
export function isAutomationEntityType(value: any): value is AutomationEntityType {
  return value?.name && !value?.key
}

function isAutomationTask(value: any): value is AutomationTask {
  return value instanceof AutomationTask
}

function isAutomationRelatedUser(value: any): value is AutomationRelatedUser {
  return value.key
}

function isAutomationRelativeDate(value: any): value is AutomationRelativeDate {
  return value.date && value.offset && value.unit
}

function areChecklistItems(value: any): value is ChecklistItem[] {
  return Array.isArray(value) && (value.length === 0 || value[0] instanceof ChecklistItem)
}
