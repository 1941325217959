<figure
  class="avt-initials aw-2-pos--relative"
  [ngClass]="classes"
  [style.height.px]="avatarPixelSizes[size]"
  [style.width.px]="avatarPixelSizes[size]"
>
  <figcaption class="avt-initials__text aw-2-pos--absolute">
    {{ initials | uppercase }}
  </figcaption>
</figure>
