import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { Injectable } from '@angular/core'
import { ISOLanguage } from '@awork/_shared/models/account.model'
import { HttpErrorResponse } from '@angular/common/http'

interface RequestErrorInfo {
  message: string
  error: string | HttpErrorResponse
  traceId: string
  date: Date
}

export interface AppState {
  traceIds: string[]
  traceIdsError: string[]
  versionCheck: Date
  checkingVersion: boolean
  language: ISOLanguage
  isLoggedIn: boolean
  referralCode?: string
  lastSyncDate?: Date
  isSwitchingWorkspace: boolean
  pushNotificationRegistrationId: string
  isMSTeams: boolean
  debug: boolean
  cacheWiped?: Date
  lastRoute: string
  adBlockerErrorOccurred: boolean
  openLinksInDesktopApp?: boolean
  logoutReason?: Partial<RequestErrorInfo>
  refreshSessionError?: Partial<RequestErrorInfo>
}

export function createInitialState(): AppState {
  return {
    traceIds: [],
    traceIdsError: [],
    versionCheck: null,
    checkingVersion: false,
    language: 'en-GB',
    isLoggedIn: false,
    referralCode: null,
    lastSyncDate: null,
    isSwitchingWorkspace: false,
    pushNotificationRegistrationId: null,
    isMSTeams: false,
    debug: false,
    cacheWiped: null,
    lastRoute: null,
    adBlockerErrorOccurred: false,
    openLinksInDesktopApp: null
  }
}

@Injectable({ providedIn: 'root' })
export class AppStore extends SignalStore<AppState> {
  constructor() {
    super({
      name: 'app',
      initialState: createInitialState(),
      persistDelay: 2000
    })

    this.update({ isSwitchingWorkspace: false })
  }

  /**
   * Sets localStorage key 'awLogin' used to show the proper App's loader
   * @param {Readonly<AppState>} prevState
   * @param {Readonly<AppState>} nextState
   * @returns {AppState}
   */
  preUpdateState(prevState: Readonly<AppState>, nextState: Readonly<AppState>): Partial<AppState> {
    localStorage.setItem('awLogin', nextState.isLoggedIn ? 'true' : 'false')
    return super.preUpdateState(prevState, nextState)
  }

  logTrace(traceId: string, error: boolean): void {
    const traceLimit = 100

    this.update(appState => {
      if (error) {
        const traceIds = appState.traceIdsError

        if (traceIds.length === traceLimit) {
          traceIds.shift()
        }

        return { traceIdsError: [...traceIds, traceId] }
      } else {
        const traceIds = appState.traceIds

        if (traceIds.length === traceLimit) {
          traceIds.shift()
        }

        return { traceIds: [...traceIds, traceId] }
      }
    })
  }

  /**
   * Set the last sync date
   * @param date
   */
  setLastSyncDate(date: Date): void {
    this.update({ lastSyncDate: date })
  }

  /**
   * Set the registration id for push notifications
   * @param registrationId
   */
  setPushNotificationRegistrationId(registrationId: string): void {
    this.update({ pushNotificationRegistrationId: registrationId })
  }

  /**
   * Sets the isMSTeams flag
   * @param flag
   */
  setIsMSTeams(flag: boolean): void {
    this.update({ isMSTeams: flag })
  }

  /**
   * Sets the debug flag
   * @param {boolean} debug
   */
  setDebug(debug: boolean): void {
    this.update({ debug })
  }

  /**
   * Sets the last time the cache were wiped
   * @param date
   */
  setCacheWiped(date: Date): void {
    this.update({ cacheWiped: date })
  }

  /**
   * Sets the last rendered route
   * @param route
   */
  setLastRoute(route: string): void {
    this.update({ lastRoute: route })
  }

  /**
   * Sets the flag used to show to the user the API error caused by AdBlockers
   * @param errorOccurred
   */
  setAdBlockerErrorOccurred(errorOccurred: boolean): void {
    this.update({ adBlockerErrorOccurred: errorOccurred })
  }

  /**
   * Sets the flag used to determine if the app is checking the version
   * @param checking
   */
  setCheckingVersion(checking: boolean): void {
    this.update({ checkingVersion: checking })
  }

  /**
   * Sets the flag used to determine if the app should open links in the desktop app
   * @param {boolean} openLinksInDesktopApp
   */
  setOpenLinksInDesktopApp(openLinksInDesktopApp: boolean): void {
    this.update({ openLinksInDesktopApp })
  }

  /**
   * Sets the reason why the user was logged out
   * @param {string} errorInfo
   */
  setLogoutReason(errorInfo: Partial<RequestErrorInfo>): void {
    this.update({ logoutReason: errorInfo })
  }

  /**
   * Sets the refresh session error
   * @param {string} errorInfo
   */
  setRefreshSessionError(errorInfo: Partial<RequestErrorInfo>): void {
    this.update({ refreshSessionError: errorInfo })
  }
}
