export const Workspaces = {
  Production: {
    Awork: 'b870a84e-c7fa-e711-80c2-00155d3163ca',
    Support: '3f72c3eb-4ae4-e811-9f2b-00155d314c5a',
    fischerAppelt: 'da185842-0d07-ec11-b563-dc984023d47e'
  },
  Develop: {
    Awork: '78360e11-67b9-e711-80c2-0017fa10039b'
  }
}
