import { Injectable } from '@angular/core'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'
import { arrayAdd, arrayRemove, arrayUpdate } from '@awork/core/state/signal-store/helpers'
import { LinkedChat, Project } from '@awork/features/project/models/project.model'
import { SignalService } from '@awork/_shared/services/signal-service/signal.service'
import { deleteExpiredEntities } from '@awork/_shared/functions/delete-expired-entities'

import { SyncOptions } from '@awork/core/state/signal-store/types'

export type ProjectView = 'details' | 'tasks' | 'times' | 'settings' | 'docs'

@Injectable({ providedIn: 'root' })
export class ProjectStore extends EntitySignalStore<Project> {
  constructor(private signalService: SignalService) {
    super({ name: 'project', entityConstructor: project => Project.mapProject(project), persistDelay: 30000 })

    deleteExpiredEntities(this)

    // In every initialization and reconnection, reset the tasks loaded flag of all projects
    this.resetTasksLoaded()
    this.signalService.connected.subscribe(() => this.resetTasksLoaded())
  }

  preUpdateEntity(storedProject: Readonly<Project>, project: Readonly<Project>): Project {
    const modifiedOn = new Date().getTime()
    const tasksLoaded = project.tasksLoaded || storedProject.tasksLoaded
    const milestones = project.milestones === undefined ? storedProject.milestones : project.milestones
    const color = project.color === undefined ? null : project.color
    const imageLastUpdatedOn =
      project.imageLastUpdatedOn === undefined ||
      (project.imageLastUpdatedOn && project.imageLastUpdatedOn < storedProject.imageLastUpdatedOn)
        ? storedProject.imageLastUpdatedOn
        : project.imageLastUpdatedOn

    return { ...project, modifiedOn, tasksLoaded, milestones, color, imageLastUpdatedOn }
  }

  preAddEntity(newEntity: Readonly<Project>): Project {
    const modifiedOn = new Date().getTime()
    return { ...newEntity, modifiedOn }
  }

  /**
   * Synchronize the store with the data fetched from the API
   * Required only when fixed filters are involved (Ex.: fetching closed projects)
   * Add new projects, update existing project, remove non-existing projects
   * @param {Project[]} projects
   * @param {Project[]} storedProjects
   * @param {SyncOptions<Project>} options
   */
  sync(projects: Project[], storedProjects: Project[], options?: SyncOptions<Project>): void {
    const defaultOptions: SyncOptions<Project> = {
      paging: { page: 1, pageSize: 50 },
      keepProp: 'tasksLoaded',
      nullableProps: ['startDate', 'dueDate', 'color']
    }

    super.sync(projects, storedProjects, { ...defaultOptions, ...options })
  }

  /**
   * Synchronize a single project with the data fetched from the API
   * @param {Project} project
   */
  syncEntity(project: Project): void {
    this.upsert(project.id, storedProject => {
      if (
        !storedProject ||
        !storedProject.name ||
        project.resourceVersion > (storedProject.resourceVersion || 0) ||
        (project.score && storedProject.score !== project.score)
      ) {
        return project.tasksLoaded === undefined ? { ...project, tasksLoaded: storedProject?.tasksLoaded } : project
      } else {
        return storedProject
      }
    })
  }

  /**
   * Resets the tasksLoaded flag of all projects
   */
  private resetTasksLoaded(): void {
    this.update(project => project.tasksLoaded, { tasksLoaded: false })
  }

  /**
   * Updates the project's company updateOn to force reload the logo
   * @param companyId
   */
  updateCompanyLogo(companyId: string): void {
    this.update(
      project => project.companyId === companyId,
      project => {
        const company = project.company
        company.updatedOn = new Date()

        return { ...project, company }
      }
    )
  }

  /**
   * Updates the project's updateOn to force reload the logo
   * @param projectId
   */
  updateProjectLogo(projectId: string): void {
    this.update(project => project.id === projectId, {
      imageLastUpdatedOn: new Date()
    })
  }

  /**
   * Adds a linked chat to the store
   * @param {string} projectId
   * @param {LinkedChat} newValue
   */
  addLinkedChat(projectId: string, newValue: LinkedChat): void {
    if (newValue.provider && newValue.channelId) {
      this.update(projectId, ({ linkedChats }) => ({
        linkedChats: arrayAdd(linkedChats, newValue)
      }))
    }
  }

  /**
   * Removes a linked chat from the store based on channelId and provider
   * @param {string} projectId
   * @param {string} channelId
   * @param {string} provider
   */
  removeLinkedChat(projectId: string, channelId: string, provider: string): void {
    const predicate = (linkedChat: LinkedChat) => linkedChat.channelId === channelId && linkedChat.provider === provider

    this.update(projectId, ({ linkedChats }) => ({
      linkedChats: arrayRemove(linkedChats, predicate)
    }))
  }

  /**
   * Updates a linked chat to the store based on channelId and provider
   * @param {string} projectId
   * @param {string} channelId
   * @param {string} provider
   * @param {LinkedChat} updatedValue
   */
  updateLinkedChat(projectId: string, channelId: string, provider: string, updatedValue: LinkedChat): void {
    const predicate = (linkedChat: LinkedChat) => linkedChat.channelId === channelId && linkedChat.provider === provider
    this.update(projectId, ({ linkedChats }) => ({
      linkedChats: arrayUpdate(linkedChats, predicate, updatedValue)
    }))
  }
}
