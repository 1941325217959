import { apiHostname } from '@awork/environments/environment'

/**
 * Returns the current hostname, or the hostname of the given URL.
 * If the hostname is 'localhost' or an IP address, returns environment variables' apiHostname.
 */
export default function (url?: string): string {
  const hostname = url?.split('/')[0] || window.location.hostname

  // Check if the hostname is 'localhost' or an IP address
  if (hostname === 'localhost' || /^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
    return apiHostname
  }

  const hostnameParts = hostname.split('.')

  // Check if the hostname has multi-level subdomains (i.e., awork runner)
  if (hostnameParts.length >= 3) {
    hostnameParts.shift()
    return hostnameParts.join('.')
  }

  // Check if the hostname has at least two parts (i.e., a top-level domain)
  if (hostnameParts.length >= 2) {
    return hostnameParts[hostnameParts.length - 2] + '.' + hostnameParts[hostnameParts.length - 1]
  }

  // If the hostname does not have a top-level domain, return an empty string
  return apiHostname
}
