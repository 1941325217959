import { generateGUID } from './guid-generator'

const anonymousIdKey = 'awAnonymousId'

/**
 * Gets the anonymous id from the local storage
 * @returns {string}
 */
export function getAnonymousIdFromLocalStorage(): string {
  return localStorage.getItem(anonymousIdKey)
}

/**
 * Creates an anonymous id ans stores it in the local storage
 * @returns {string}
 */
export function createAnonymousIdInLocalStorage(): string {
  const newAnonymousId = generateGUID()
  localStorage.setItem(anonymousIdKey, newAnonymousId)

  return newAnonymousId
}

/**
 * Gets the anonymous id from the local storage, and creates it if there's none
 * @returns {string}
 */
export function getOrCreateAnonymousId(): string {
  const storedAnonymousId = getAnonymousIdFromLocalStorage()

  if (storedAnonymousId) {
    return storedAnonymousId
  }

  return createAnonymousIdInLocalStorage()
}
