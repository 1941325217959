import { isDevelopCriterion } from './criteria/environment.criteria'
import { isAworkTestEmail } from './is-awork-test-email'

type EnvironmentContextualDate = { production: Date; develop: Date }

/**
 * Returns a Date based on the current environment
 * @param {EnvironmentContextualDate} dates
 * @returns Date
 */
export function getContextualDate({ develop, production }: EnvironmentContextualDate): Date {
  return isDevelopCriterion() ? develop : production
}

export function getAworkContextualDate({
  email,
  aworkDate,
  customerDate
}: {
  email: string
  aworkDate: Date
  customerDate: Date
}): Date {
  return isAworkTestEmail(email) ? aworkDate : customerDate
}
