import { Injectable } from '@angular/core'
import { Company } from '@awork/features/company/models/company.model'
import { EntitySignalStore } from '@awork/core/state/signal-store/entitySignalStore'

@Injectable({ providedIn: 'root' })
export class CompanyStore extends EntitySignalStore<Company> {
  constructor() {
    super({ name: 'company', entityConstructor: company => new Company(company) })
  }
}
