<aw-file-icon [size]="Size.l" [extension]="fileUpload?.fileExtension"/>

<div class="file-details aw-2-ml--s aw-2-mr--m">
  <p class="file-details__name aw-2-txt--truncate">{{ fileUpload?.filename }}</p>

  <span
    class="file-details__size aw-2-txt--steel aw-2-mr--xs"
  >
    {{ fileUpload?.file?.size | fileSize }}
  </span>

  <ng-container [ngSwitch]="fileUpload?.status">

    <aw-info-box *ngSwitchCase="fileUploadStatuses.Canceled" [color]="Color.Red" [text]="q.translations.common.canceled" />
    <aw-info-box *ngSwitchCase="fileUploadStatuses.Error" [color]="Color.Red" [text]="q.translations.errors.uploadError" />

    <aw-progress-bar *ngSwitchDefault class="file-details__progress aw-2-mt--s" [progress]="fileUpload?.progress"/>
  </ng-container>
</div>

<ng-container [ngSwitch]="fileUpload?.status">
  <aw-remove-button
    *ngSwitchCase="fileUploadStatuses.Uploading"
    [clear]="false"
    [tooltip]="q.translations.common.cancel"
    (onClick)="cancelUpload($event)"/>

  <aw-icon-button
    *ngSwitchCase="fileUploadStatuses.Error"
    class="retry-button"
    icon="refresh"
    [tooltip]="q.translations.common.retry"
    (click)="retryUpload($event)"
  />

  <aw-badge *ngSwitchCase="fileUploadStatuses.Complete" [color]="Color.Green" [icon]="'check'" />
  <aw-badge *ngSwitchCase="fileUploadStatuses.Canceled" [color]="Color.Red" [icon]="'close'" />
</ng-container>
