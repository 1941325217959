/**
 * Checks the pixel radius of the screen the browser is running it and returns the number.
 * Used for images.
 */
let storedPixelFactor: number
export function getPixelRatio(win: Window = window): number {
  if (!storedPixelFactor) {
    storedPixelFactor = 1
    if (win) {
      storedPixelFactor = win.devicePixelRatio ? win.devicePixelRatio : 1
    }
  }
  return storedPixelFactor
}
