<article
  class="toast aw-2-sh--l aw-2-p--l"
  [@toastAnimation]
  [style.top.px]="top"
  [style.zIndex]="zIndex"
  [ngClass]="colorClass"
>
  <span *ngIf="isHover || isTouchDevice" class="toast__hide-button">
    <aw-close-button
      [size]="'s'"
      (click)="onClose()"
    />
  </span>

  <section class="toast__content aw-2-p--s aw-2-pos--relative">
    <p class="aw-2-txt--bold aw-2-txt--center aw-2-txt--night ">{{ npsToastTitle }}</p>

    <!-- NPS Score -->
    <div class="toast__content__nps-score">
      <div class="toast__content__nps-score--input aw-2-mt--l aw-2-mb--l">
        <div
          *ngFor="let score of scores"
          class="toast__content__nps-score__item"
          [class.selected]="selectedScore === score.value"
          (click)="onScoreChange(score.value)"
        >
          <span>
            {{ score.value.toString() }}
          </span>
        </div>
      </div>

      <small class="aw-2-txt--body-tiny aw-2-txt--bold">{{ translations.score.label.negative }}</small>
      <small class="aw-2-txt--body-tiny aw-2-txt--bold aw-2-pos--float-right">{{ translations.score.label.positive }}</small>
    </div>

    <!-- Feedback -->
    <div *ngIf="selectedScore >= 0" [style.width.%]="100" [@expand]>

      @if (surveyMode === 'textbox') {

        <p class="aw-2-txt--bold aw-2-txt--center aw-2-txt--night aw-2-mb--xl aw-2-mt--xl">{{ scoreFeedbackTitle }}</p>
        <aw-multiline-text-field
          [minRows]="3"
          [maxRows]="3"
          [placeholder]="scoreFeedbackPlaceholder"
          (change)="onFeedbackInput($event.target.value)"
        />

        <div class="aw-2-txt--right aw-2-mt--l">
          <aw-button
            [size]="'m'"
            [type]="'tertiary'"
            [text]="q.translations.common.close"
            (click)="onClose()"
          />
          <aw-button
            class="aw-2-ml--s"
            [size]="'m'"
            [type]="'primary'"
            [text]="surveyMode === 'textbox' ? 'Send' : 'Umfrage teilnehmen'"
            (click)="onSendFeedbackClick()"
          />
        </div>

      } @else {

        <div class="aw-2-flx aw-2-flx--column aw-2-flx--center aw-2-flx--gap-xs aw-2-mt--xl">
          <span class="aw-2-txt--night">{{ translations.thankYou }} </span>
          <span class="aw-2-txt--s">{{ translations.takePartInSurvey }}</span>
          <img class="aw-2-pt--m" width="298" [src]="q.assetsUrl + 'images/visuals/nps-survey.png'" />
        </div>

        <div class="aw-2-txt--right aw-2-mt--l">
          <aw-button
            [size]="'m'"
            [type]="'tertiary'"
            [text]="q.translations.common.close"
            (click)="onClose()"
          />
          <aw-button
            class="aw-2-ml--s"
            [size]="'m'"
            [type]="'primary'"
            [text]="translations.startSurvey"
            (click)="onOpenSurvey()"
          />
        </div>
      }


    </div>
  </section>
</article>
