export interface Hashes {
  acceptTerms: '#acceptTerms'
  createTeam: '#createTeam'
  email: '#email'
  password: '#password'
  savePassword: '#savePassword'
  letsGo: '#letsGo'
  userName: '#userName'
  thatsMe: '#thatsMe'
  teamName: '#teamName'
  thatsMyTeam: '#thatsMyTeam'
  createProject: '#createProject'
  skipCreatingProject: '#skipCreatingProject'
  projectName: '#projectName'
  projectBillability: '#projectBillability'
  goToProject: '#goToProject'
  menu: '#menu'
  globalAdd: '#globalAdd'
  addNewProject: '#addNewProject'
  addNewTimeTracking: '#addNewTimeTracking'
  addNewTask: '#addNewTask'
  addNewUser: '#addNewUser'
  addNewCompany: '#addNewCompany'
  selectProjectType: '#selectProjectType'
  saveProject: '#saveProject'
  projectContextMenu: '#projectContextMenu'
  companyContextMenu: '#companyContextMenu'
  editProject: '#editProject'
  deleteProject: '#deleteProject'
  logout: '#logout'
  login: '#login'
  signupTitle: '#signupTitle'
  signupSubtitle: '#signupSubtitle'
  loginTitle: '#loginTitle'
  loginSubtitle: '#loginSubtitle'
  // Create Task
  taskName: '#taskName'
  taskDescription: '#taskDescription'
  startDate: '#startDate'
  endDate: '#endDate'
  dueDate: '#dueDate'
  timeBudget: '#timeBudget'
  setDate: '#setDate'
  plannedDuration: '#plannedDuration'
  attachment: '#attachment'
  saveTask: '#saveTask'
  // Task Detail page
  taskNameInline: '#taskNameInline'
  inlineCancel: '#inlineCancel'
  inlineConfirm: '#inlineConfirm'
  // Project Modal
  clearCompany: '#clearCompany'
  // Task Views
  addTaskView: '#addTaskView'
  editTaskView: '#editTaskView'
  viewTaskView: '#viewTaskView'
  deleteTaskView: '#deleteTaskView'
}

export type Ids = { [T in keyof Hashes]: T }

export const ids: Ids = {
  acceptTerms: 'acceptTerms',
  createTeam: 'createTeam',
  email: 'email',
  password: 'password',
  savePassword: 'savePassword',
  letsGo: 'letsGo',
  userName: 'userName',
  thatsMe: 'thatsMe',
  teamName: 'teamName',
  thatsMyTeam: 'thatsMyTeam',
  createProject: 'createProject',
  skipCreatingProject: 'skipCreatingProject',
  projectName: 'projectName',
  projectBillability: 'projectBillability',
  goToProject: 'goToProject',
  menu: 'menu',
  globalAdd: 'globalAdd',
  addNewProject: 'addNewProject',
  addNewTimeTracking: 'addNewTimeTracking',
  addNewTask: 'addNewTask',
  addNewUser: 'addNewUser',
  addNewCompany: 'addNewCompany',
  selectProjectType: 'selectProjectType',
  saveProject: 'saveProject',
  projectContextMenu: 'projectContextMenu',
  companyContextMenu: 'companyContextMenu',
  editProject: 'editProject',
  deleteProject: 'deleteProject',
  logout: 'logout',
  login: 'login',
  signupTitle: 'signupTitle',
  signupSubtitle: 'signupSubtitle',
  loginTitle: 'loginTitle',
  loginSubtitle: 'loginSubtitle',
  // Create Task
  taskName: 'taskName',
  taskDescription: 'taskDescription',
  startDate: 'startDate',
  endDate: 'endDate',
  dueDate: 'dueDate',
  timeBudget: 'timeBudget',
  setDate: 'setDate',
  plannedDuration: 'plannedDuration',
  attachment: 'attachment',
  saveTask: 'saveTask',
  // Task Detail page
  taskNameInline: 'taskNameInline',
  inlineCancel: 'inlineCancel',
  inlineConfirm: 'inlineConfirm',
  // Project Modal
  clearCompany: 'clearCompany',
  // Task Views
  addTaskView: 'addTaskView',
  editTaskView: 'editTaskView',
  viewTaskView: 'viewTaskView',
  deleteTaskView: 'deleteTaskView'
}
