export interface IHolidayRegionLocation {
  code: string
  name: string
}

export interface IHolidayRegion {
  id: string
  name: string
  countryCode: string
  country: string
  locationCode: string
  location: string
  isDefault: boolean
  numberOfPublicHolidays: number
  assignedUsers: string[]
}

export class HolidayRegion implements IHolidayRegion {
  id: string
  name: string
  countryCode: string
  country: string
  locationCode: string
  location: string
  isDefault: boolean
  numberOfPublicHolidays: number
  assignedUsers: string[]

  constructor(data: Partial<IHolidayRegion> = {}) {
    if (data) {
      Object.assign(this, data)
    }
  }
}
