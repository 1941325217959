import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common'
import { Size } from '@awork/_shared/types/size'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { ExternalProviders } from '@awork/_shared/models/file-external.model'

interface OnChange {}

@Component({
  selector: 'aw-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgSwitch, NgSwitchCase, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileIconComponent implements OnChange {
  @Input({ required: true, transform: (type: string) => type?.toLowerCase() }) extension: string
  @Input() externalProvider: ExternalProviders
  @Input() size: Size = Size.m

  iconClasses: string[] = []
  getIconByProvider = FileUpload.GetIconByProvider

  ngOnChanges() {
    this.iconClasses = this.getIconClasses()
  }

  /**
   * Returns the icon classes for the specified file type and size
   */
  getIconClasses(): string[] {
    return ['file-icon--' + FileUpload.getExtInfo(this.extension).color, 'file-icon--' + this.size]
  }
}
